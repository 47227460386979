<script>
import Loading from "vue-loading-overlay";
import Multiselect from "vue-multiselect";
//import TopBar from "@/components/TopBar.vue";
import SkeletonLoader from "@/components/SkeletonLoader.vue";
//import FooterItem from "@/components/FooterItem.vue";
import SuccessPage from "@/components/SuccessPage.vue";
import axios from "axios";
import { apiUrl } from "@/constants/config";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import json from "@/constants/flags.json";
import { sweetAlert } from "@/constants/functions";

export default {
	name: "SouscriptionAssuranceCivile",
	components: {
		Loading, multiselect: Multiselect, //"top-bar": TopBar,
		"skeleton-loader": SkeletonLoader, //"footer-item": FooterItem,
		"success-page": SuccessPage,
	},
	data() {
		return {

			montantFormate: "",
			validiteLien: "",
			montantFormateQuick: "",
			showLienReduit: true,
			currencyMask: createNumberMask({
				prefix: "",
				includeThousandsSeparator: true,
				allowNegative: false,
				thousandsSeparatorSymbol: " ",
			}),
			formRechargement: {
				issuer_name: "",
				card_type_code: "",
				card_type_description: "",
			},
			logList: "",
			current: "",
			answer: "0",
			operatorClicked: true,
			showCard: false,
			cards: [],
			cardsLength: 0,
			loginApaym: true,
			hideCodePay: false,
			tabIndex: 0,
			mtnOkay: false,
			mtnNOkay: false,
			tresorOkay: false,
			tresorNOkay: false,
			showPayBySomeone: true,
			orangeOkay: false,
			orangeNOkay: false,
			waveOkay: false,
			waveNOkay: false,
			moovOkay: false,
			moovNOkay: false,
			biciciNOkay: false,
			hideRecu: true,
			isLoading: false,
			fullPage: true,
			isLoadingQr: false,
			fullPageQr: false,
			isLoadingEcobank: false,
			fullPageEcobank: false,
			isLoadingTresorPay: false,
			fullPageTresorPay: false,
			isLoadingMomo: false,
			fullPageMomo: false,
			isLoadingAPaym: false,
			fullPageAPaym: false,
			isLoadingAPaymAuth: false,
			fullPageAPaymAuth: false,
			isLoadingOrange: false,
			fullPageOrange: false,
			isLoadingFlooz: false,
			fullPageFlooz: false,
			isLoadingBicici: false,
			fullPageBicici: false,
			isLoadingWave: false,
			fullPageWave: false,
			indice: { i: "225" },
			indiceMobileMoney: "225",
			indiceToShow: "",
			json: [],
			qrCodeUri: "",
			ecobankQrCodeUri: "",
			urlOrange: "",
			urlWave: "",
			isBicici: false,
			isOtherCard: false,
			cardChoice: true,
			contentBicici: "",
			formBicici: false,
			cardType: "",
			events: [],
			dataFormulaire: {
				nom: "",
				prenom: "",
				telephone: "",
				email: "",
				montant: "",
				motif: "",
				devise: "XOF",
				amountPourboire: "",
				society: "",
				key_payment: "",
				deleg: "",
				grade: "",
				nom_beneficiaire: "",
				date_naissance: "",
				
				
				numeroCompte: "",
				societeEnBourse: "",
				nombreActions: "",
				boutique: "",
				immatriculation: "",
				service: "",
				formule: "",

				// NSIA ASSURANCE
				typeClient: "",
				nomEntreprise: "",
				numeroPolice: "",
				numeroQuittance: "",
				marqueVehicule: "",
				code_apporteur: "",
				numero_mobile_apporteur: "",
				isInsured: "",

				nomAssure: "",
				prenomAssure: "",
				telephoneAssure: "",
				garantie: ""

			},
			dataFormulaireQuick: {
				nom: "",
				prenom: "",
				telephone: "",
				telephone2: "",
				email: "",
				montant: "",
				motif: "",
				devise: "XOF",
				amountPourboire: "",
				society: "",
				key_payment: "",
				numeroCompte: "",
				societeEnBourse: "",
				nombreActions: "",
				boutique: "",
				immatriculation: "",
				service: "",
				formule: "",
				quantite: "1",
			},
			dataApaymPay: { indice: "225", numApaym: "", codeApaym: "" },
			dataApaymPayAuth: {
				indice: "225",
				numApaym: "",
				codeApaym: "",
				token: "",
			},
			dataVisaCard: {
				cardHolderName: "",
				pan: "",
				expirationMonth: "",
				expirationYear: "",
				cvv: "",
			},
			dataMoMo: { numero: "" },
			dataTresorPAY: { numero: "" },
			dataMoov: { numero: "" },
			url_data: {
				reference: "",
				code_page: "",
				slug: "",
				montant: "",
				indice: "",
				numero: "",
				nom: "",
				prenom: "",
				email: "",
				motif: "",
				key_payment: "",
				redirect_key: "",
				formule: "",
			},
			dataPage: {
				name_page: "",
				description_page: "",
				description_page_2: "",
				business_logo: "",
				business_name: "",
				business_vpan: "",
				amount: "",
				amount2: "",
				page_redirect: "",
				email_notify: "",
				success_message: "",
				seo_image: "",
				slug: "",
				type: "",
				nom: "",
				verified: "",
				rcc: "",
				business_email: "",
				business_phone: "",
				facebook: "",
				instagram: "",
				whatsapp: "",
				site: "",
				youtube: "",
				twitter: "",
				ville: "",
				qr_code: "",
				link: "",
				partner: "",
				pourboire: "",
				frais: "",
				deleg_pdci: "",
				collected_amount: "",
				collected_qty: "",
				hide_wgy_infos: "",
				show_fields_by_default: "",
				show_email_by_default: "",
				taux_apaym: "",
				taux_om: "",
				taux_mtn: "",
				taux_cb: "",
				taux_moov: "",
				taux_wave: "",
				taux_tresor: "2",
				boutiques: [],
				custom_fields: "",
				urlRedirection: "",
				redirectWithCode: "",
				fixedAmount: "",
				dureeLien: "",
				subMerchant: "",
				merchant: "",

				air_france: 0,
				baie_sirenes: 0,
				damac: 0,
				yango: 0,
				umed: 0,

				eticket: [],
			},
			slug_boutique: "",
			hasProgram: true,
			langs: ["fr", "en"],
			logoOfSelectedService: "visa-qr.png",
			logoSmallDevice: "visa-qr.png",
			showErrorPage: false,
			formPayment: false,
			formQuiclyPayment: false,
			formSuperQuicklyPayment: false,
			formLoader: false,
			traded_company: [],
			infosSup: true,
			infosSup2: true,
			typeForm: "",
			modePay: "",
			refPayment: "",
			operator: null,
			modalOrange: false,
			modalTresor: false,
			delegationsPdci: [],
			motifsPdci: [],
			gradesPdci: [],
			options: [],
			minCardYear: new Date().getFullYear(),
			modalWave: false,
			modalBicici: false,
			mobileDevice: false,
			checkedFeesApaym: false,
			checkedFeesOm: false,
			checkedFeesWave: false,
			checkedFeesMtn: false,
			checkedFeesCb: false,
			expired: false,
			sharingSection: false,
			checkedFeesMoov: false,
			checkedFeesTresorPAY: false,
			pourboire: "0",
			montantSave: "",
			sauvegardeMontant: "",
			sendNotif: "sms",
			modePayment: "",
			showMenu: true,
			showHeader: false,
			showContent: false,
			menuSelected: "Visa QR",
			error: null,
			currencyFrom: "EUR",
			currencyTo: "XOF",
			currencies: [],
			showConversion: false,
			amountToConvert: "",
			convertedAmount: "",
			devise: "",
			montantDevise: "",
			conversionRate: "",
			montantAPayer: 0,
			imgStatus: "bronze.png",
			dataYangoRechargement: {
				driver_profil_id: "",
				driver_phone_number: "",
				amount: "",
				profil_id: "",
				ref_transaction: "",
				adresse_ip: "",
				device_ID: "",
				phone_Name: "",
			},
			token: null,
			eKey: null,
			verified: false,
			dataLocation: { latitude: "", longitude: "" },
			pointLoyalty: [],
			loyalty_card: "",
			nombre_points: 0,
			cadeau_a_offrir: "",
			nombre_paiements: 0,
			nombre_transactions: 0,
			statusClient: "",
			typeCarte: "",
			is_platinum_card: "",
			detailsMontantPdci: "",
			showPaymentSection: false,
			stopInterval: null,
			timePassed: 0,
			timerInterval: null,
			showVerifMoov: false,
			showVerifTresor: false,
			payment_failure_details: "",
			showInProcessTresor: false,
			showInProcessMoov: false,
			modesPaiementDispo: [
				{
					mode: "cb_visa",
					actif: "non",
					libelle: "VISA",
					img: "visa.png",
				},
				{
					mode: "cb_mastercard",
					actif: "non",
					libelle: "MASTERCARD",
					img: "mastercard.png",
				},
				{
					mode: "orange_money",
					actif: "non",
					libelle: "Orange Money",
					img: "om.png",
				},
				{
					mode: "wave",
					actif: "non",
					libelle: "Wave",
					img: "wave.png",
				},
				{
					mode: "mtn_money",
					actif: "non",
					libelle: "MTN Money",
					img: "momo.png",
				},
				{
					mode: "moov_money",
					actif: "non",
					libelle: "Moov Money",
					img: "flooz.png",
				},
				{
					mode: "tresor_pay",
					actif: "non",
					libelle: "Tresor PAY",
					img: "tresor-money.png",
				},
				/*{
				mode: "ussd",
				actif: "non",
				libelle: "APaym USSD",
				img: "apaym-ussd.png",
			},
			{
				mode: "apaym",
				actif: "non",
				libelle: "APaym",
				img: "apaym.png",
			},
			{
				mode: "qr",
				actif: "non",
				libelle: "Visa QR",
				img: "visa-qr.png",
			},
			{
				mode: "ecobank_pay",
				actif: "non",
				libelle: "ECOBANK Pay",
				img: "ecobank-pay.png",
			},*/
			],
			modesPaiementDispo2: [
				{
					mode: "cb_visa",
					actif: "non",
					libelle: "VISA",
					img: "visa.png",
				},
				{
					mode: "cb_mastercard",
					actif: "non",
					libelle: "MASTERCARD",
					img: "mastercard.png",
				},
				{
					mode: "orange_money",
					actif: "non",
					libelle: "Orange Money",
					img: "om.png",
				},
				{
					mode: "wave",
					actif: "non",
					libelle: "Wave",
					img: "wave.png",
				},
				{
					mode: "mtn_money",
					actif: "non",
					libelle: "MTN Money",
					img: "momo.png",
				},
				{
					mode: "moov_money",
					actif: "non",
					libelle: "Moov Money",
					img: "flooz.png",
				},
				{
					mode: "tresor_pay",
					actif: "non",
					libelle: "Tresor PAY",
					img: "tresor-money.png",
				},
				/*{
				mode: "qr",
				actif: "non",
				libelle: "Visa QR",
				img: "visa-qr.png",
			},
			{
				mode: "ecobank_pay",
				actif: "non",
				libelle: "ECOBANK Pay",
				img: "ecobank-pay.png",
			},*/
			],
			expirationMonth: [
				{
					id: 1,
					month: "01",
					libelle: "Janvier",
					libelleEn: "January",
				},
				{
					id: 2,
					month: "02",
					libelle: "Février",
					libelleEn: "February",
				},
				{
					id: 3,
					month: "03",
					libelle: "Mars",
					libelleEn: "March",
				},
				{
					id: 4,
					month: "04",
					libelle: "Avril",
					libelleEn: "April",
				},
				{
					id: 5,
					month: "05",
					libelle: "Mai",
					libelleEn: "May",
				},
				{
					id: 6,
					month: "06",
					libelle: "Juin",
					libelleEn: "June",
				},
				{
					id: 7,
					month: "07",
					libelle: "Juillet",
					libelleEn: "July",
				},
				{
					id: 8,
					month: "08",
					libelle: "Août",
					libelleEn: "August",
				},
				{
					id: 9,
					month: "09",
					libelle: "Septembre",
					libelleEn: "September",
				},
				{
					id: 10,
					month: "10",
					libelle: "Octobre",
					libelleEn: "October",
				},
				{
					id: 11,
					month: "11",
					libelle: "Novembre",
					libelleEn: "November",
				},
				{
					id: 12,
					month: "12",
					libelle: "Décembre",
					libelleEn: "December",
				},
			],
			typeServices: [
				{
					id: 1,
					nom_service: "Visite technique  - Véhicules Légers",
				},
				{
					id: 2,
					nom_service: "Visite technique - Véhicules Poids Lourds PL1-TP3",
				},
				{
					id: 3,
					nom_service: "Visite technique - Véhicules Poids Lourds PL2-TP4",
				},
				{
					id: 4,
					nom_service: "Visite technique - Véhicules Poids Lourds PL1-SR",
				},
				{
					id: 5,
					nom_service: "Visite technique - Véhicules Poids Lourds PL2-TR",
				},
				{
					id: 6,
					nom_service: "Visite technique - Véhicules Poids Lourds REVISITE VL",
				},
				{
					id: 7,
					nom_service: "Visite technique - Véhicules Poids Lourds REVISITE PL",
				},
				{
					id: 8,
					nom_service: "Diagnostic technique (Pré-visite)",
				},
				{
					id: 9,
					nom_service: "Vignette automobile",
				},
				{
					id: 10,
					nom_service: "Vignette Moto",
				},
				{
					id: 11,
					nom_service: "Identification de véhicule neuf",
				},
				{
					id: 12,
					nom_service: "Identification de véhicule importé d’occasion",
				},
				{
					id: 13,
					nom_service: "Fabrication de plaques- Moto",
				},
				{
					id: 14,
					nom_service: "Fabrication de plaques - Véhicules légers",
				},
				{
					id: 15,
					nom_service: "Fabrication de plaques - Poids lourds",
				},
				{
					id: 16,
					nom_service: "Fabrication de plaques - Semi-remorque",
				},
				{
					id: 17,
					nom_service: "Sécurisation plaque",
				},
				{
					id: 18,
					nom_service: "Réception à titre Isolé (RTI)",
				},
				{
					id: 19,
					nom_service: "Transfert plaque Immatriculation (TPI)",
				},
				{
					id: 20,
					nom_service: "Extrait carte grise",
				},
				{
					id: 21,
					nom_service: "Duplicata visite Technique",
				},
				{
					id: 22,
					nom_service: "Domiciliation de véhicule",
				},
				{
					id: 23,
					nom_service: "Diagnostic-Visite Technique",
				},
				{
					id: 24,
					nom_service: "Visite compteur horokilométrique",
				},
			],
			showBtnSearch: "true",
			assurance_choisie: '',
			show_assurance_form: false,
			garanties_assurance: [
				{
					_id: 1,
					head: 'Nature de la couverture',
					text_1: 'Capital Décès: 1.000.000 XOF',
					text_2: 'Incapacité : 1.000.000 XOF',
					text_3: 'Frais Médicaux : 150.000 XOF',
					prime: '7000'
				},
				{
					_id: 2,
					head: 'Nature de la couverture',
					text_1: 'Capital Décès: 2.000.000 XOF',
					text_2: 'Incapacité : 2.000.000 XOF',
					text_3: 'Frais Médicaux : 200.000 XOF',
					prime: '9000'
				},
				{
					_id: 3,
					head: 'Nature de la couverture ',
					text_1: 'Capital Décès: 3.000.000 XOF',
					text_2: 'Incapacité : 3.000.000 XOF',
					text_3: 'Frais Médicaux : 300.000 XOF',
					prime: '18000'
				},
				{
					_id: 4,
					head: 'Nature de la couverture ',
					text_1: 'Pour les capitaux garanties voir la cotation ou la CP',
					text_2: '',
					text_3: '',
					prime: ''
				},
			],
		};
	},
	computed: {},
	methods: {

		scrollToTop() {
			window.scrollTo(0, 0);
		},

		// Get information of payment page
		getInfosPage(slug) {

			this.isLoading = true;
			let dataSend = new FormData();
			dataSend.append("slug", slug);

			var req = axios
				.post(apiUrl + "api/marchand/souscription_assurances/infos", dataSend)
				.then((response) => {
					this.isLoading = false;
					if (response.data.code !== 1) {
						this.formLoader = true;
						this.showErrorPage = !this.showErrorPage;
						return false;
					}

					if (response.data.slug !== this.url_data.slug) {
						this.formLoader = true;
						this.showErrorPage = !this.showErrorPage;
						return false;
					}

					this.formPayment = true;
					this.dataPage.taux_apaym = response.data.taux_apaym;
					this.dataPage.taux_cb = response.data.taux_cb;
					this.dataPage.taux_moov = response.data.taux_moov;
					this.dataPage.taux_mtn = response.data.taux_mtn;
					this.dataPage.taux_om = response.data.taux_om;
					this.dataPage.taux_wave = response.data.taux_wave;
					this.dataPage.name_page = response.data.name_page;
					this.dataPage.description_page_2 = response.data.description_page;
					this.dataPage.description_page = response.data.description_page
						? this.getDescriptionTronque(response.data.description_page, 90)
						: response.data.description_page;
					this.dataPage.business_logo =
						response.data.business_logo !== ""
							? "https://apaym.com/cms/images/logo_marchand/" +
							response.data.business_logo
							: "";
					this.dataPage.business_name = response.data.business_name;
					this.dataPage.business_vpan = response.data.vpan;
					this.dataPage.amount =
						response.data.amount !== ""
							? response.data.amount
							: sessionStorage.getItem("m") !== null &&
								sessionStorage.getItem("m") !== ""
								? sessionStorage.getItem("m")
								: "";
					this.showPayBySomeone =
						!(sessionStorage.getItem("k") !== null &&
							sessionStorage.getItem("k") !== "");
					this.dataPage.page_redirect = response.data.page_redirect;
					this.dataPage.email_notify = response.data.email_notify;
					this.dataPage.success_message = response.data.success_message;
					this.dataPage.seo_image = response.data.seo_image;
					this.dataPage.slug = response.data.slug;
					this.dataPage.type = response.data.type;
					this.dataPage.nom = response.data.nom;
					this.dataPage.verified = response.data.verified;
					this.dataPage.rcc = response.data.rcc;
					this.dataPage.business_email = response.data.business_email;
					this.dataPage.business_phone = response.data.business_phone;
					this.dataPage.facebook = response.data.facebook;
					this.dataPage.instagram = response.data.instagram;
					this.dataPage.whatsapp = response.data.whatsapp;
					this.dataPage.site = response.data.site;
					this.dataPage.youtube = response.data.youtube;
					this.dataPage.twitter = response.data.twitter;
					this.dataPage.ville = response.data.ville;
					this.dataPage.qr_code = response.data.qr_code;
					this.dataPage.link = response.data.lien;
					this.dataPage.partner = response.data.partenaire;
					this.dataPage.pourboire = response.data.tip;
					this.dataPage.frais = response.data.fees;
					this.dataPage.deleg_pdci = response.data.deleg_pdci;
					this.dataPage.yango = response.data.yango;
					this.dataPage.air_france = response.data.air_france;
					this.dataPage.damac = response.data.damac;
					this.dataPage.baie_sirenes = response.data.baie_sirenes;
					this.dataPage.umed = response.data.umed;
					this.dataPage.collected_amount = response.data.collected_amount;
					this.dataPage.collected_qty = response.data.collected_qty;
					this.dataPage.hide_wgy_infos = response.data.hide_wgy_infos;
					this.dataPage.show_fields_by_default =
						response.data.show_fields_by_default;
					this.dataPage.show_email_by_default =
						response.data.show_email_by_default;
					this.dataPage.custom_fields = response.data.custom_fields;
					this.dataPage.urlRedirection = response.data.urlRedirection;
					this.dataPage.redirectWithCode = response.data.redirectWithCode;
					this.dataPage.fixedAmount = response.data.fixed_amount;
					this.dataPage.dureeLien = response.data.duree_lien;
					this.dataPage.subMerchant = response.data.subMerchantId;

					this.dataPage.eticket = [...response.data.eticket];
					// this.dataPage.amount = response.data.montant_fixe;
					this.dataPage.merchant = response.data.merchant;

					// Récupérer les boutiques ainsi que les agents des boutiques pour le marchand
					for (let donnee of response.data.boutiques) {
						if (donnee.responsables.length <= 0) {
							this.dataPage.boutiques.push({
								store_id: donnee.store_id,
								nom_boutique: donnee.nom_boutique.toUpperCase(),
								terminal_id: "",
							});
						} else {
							this.dataPage.boutiques.push({
								store_id: donnee.store_id,
								nom_boutique: donnee.nom_boutique.toUpperCase(),
								terminal_id: "",
							});

							for (let responsable of donnee.responsables) {
								if (responsable.terminal_id !== "0" && responsable.nom_agent) {
									this.dataPage.boutiques.push({
										store_id: donnee.store_id,
										nom_boutique: "          - " + responsable.nom_agent,
										terminal_id: responsable.terminal_id,
									});
								} else if (responsable.terminal_id !== "0") {
									this.dataPage.boutiques.push({
										store_id: donnee.store_id,
										nom_boutique:
											"          - Agent Nº " + responsable.terminal_id,
										terminal_id: responsable.terminal_id,
									});
								}
							}
						}
					}


					this.formLoader = true;
					this.indice.i =
						sessionStorage.getItem("i") !== undefined &&
							sessionStorage.getItem("i") !== null
							? sessionStorage.getItem("i")
							: "225";
					this.getIndiceJson();

					response.data.montant_fixe
						? (this.dataPage.amount = response.data.montant_fixe)
						: null;


					response.data.montant_fixe
						? (this.dataPage.amount2 = response.data.montant_fixe)
						: (this.dataPage.amount2 = this.dataPage.amount);


					// Traitons les modes de paiement actif pour le marchand
					for (let i = 0; i < response.data.modes_paiement.length; i++) {
						for (let j = 0; j < this.modesPaiementDispo.length; j++) {
							if (
								response.data.modes_paiement[i] ===
								this.modesPaiementDispo[j].mode
							) {
								this.modesPaiementDispo[j].actif = "oui";
							}
						}
					}

					// modesPaiementDispo2
					for (let i = 0; i < response.data.modes_paiement.length; i++) {
						for (let j = 0; j < this.modesPaiementDispo2.length; j++) {
							if (
								response.data.modes_paiement[i] ===
								this.modesPaiementDispo2[j].mode
							) {
								this.modesPaiementDispo2[j].actif = "oui";
							}
						}
					}
				})
				.catch((error) => {
					console.log(error);
					this.isLoading = false;
					this.$swal("Une erreur est survenue !", error, "error");
				});

			req.then((x) => console.log("Done! " + x));
		},

		// Allows numbers only, amount field
		numbersOnly(evt) {
			evt = evt ? evt : window.event;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if (
				charCode > 31 &&
				(charCode < 48 || charCode > 57) &&
				charCode !== 46
			) {
				evt.preventDefault();
			} else {
				return true;
			}
		},

		// Open calculator modal
		openCalculator() {
			this.$refs["openCalculatorModal"].show();
		},

		isMobile: function () {
			return false
		},

		getDescriptionTronque(text, nombreCharAtronquer) {

			let delim = " [suite...]",
				length = nombreCharAtronquer;
			if (nombreCharAtronquer < text.length) {
				while (text[length] !== " " && length > 0) {
					length--;
				}

				if (length === 0) {
					return text.substring(0, nombreCharAtronquer) + delim;
				}

				return text.substring(0, length) + delim;
			}

			return text;
		},

		getDescriptionTronque2(text, nombreCharAtronquer) {
			let delim = "...",
				length = nombreCharAtronquer;
			if (nombreCharAtronquer < text.length) {
				while (text[length] != " " && length > 0) {
					length--;
				}

				if (length == 0) {
					return text.substring(0, nombreCharAtronquer) + delim;
				}

				return text.substring(0, length) + delim;
			}

			return text;
		},

		getIndiceJson() {

			this.json = [...json];
			this.options = [];

			for (let j of this.json) {
				this.options.push({
					country_code: j.flag + " +" + j.dial_code,
					country: j.flag + " " + j.name + " +" + j.dial_code,
					code: j.dial_code,
				});
			}

			// Indicatifs
			this.indice.i = this.options.find(
				(option) => option.code === this.indice.i
			);
			this.dataApaymPay.indice = this.options.find(
				(option) => option.code === this.dataApaymPay.indice
			);
			this.dataApaymPayAuth.indice = this.options.find(
				(option) => option.code === this.dataApaymPayAuth.indice
			);

			// Indicatif pour les mobile money ivoiriens
			this.indiceMobileMoney = this.options.find(
				(option) => option.code === "225"
			);
		},

		getIpAddress() {
			axios
				.get("https://ipinfo.io/json?token=8c8fd7115a940f")
				.then((response) => {
					this.dataYangoRechargement.adresse_ip =
						response.data.ip +
						" - " +
						response.data.org +
						" | " +
						response.data.country +
						" - " +
						response.data.city +
						" - " +
						response.data.region;
					// console.log(response);
				})
				.catch((err) => {
					console.log(err);
				});
		},

		getLocation() {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					console.log("latitude = " + position.coords.latitude);
					console.log("longitude = " + position.coords.longitude);
					this.dataLocation.latitude = position.coords.latitude;
					this.dataLocation.longitude = position.coords.longitude;
				},
				(error) => {
					console.log(error.message);
				}
			);
		},

		payer() {

			if (isNaN(this.dataFormulaire.montant.replace(/\s/g, ""))) {
				console.log(this.dataFormulaire.montant)
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Info",
					"Veuillez renseigner un montant correct !"
				);
				return false;
			}

			if (this.assurance_choisie === 'Assurance IA' && this.dataFormulaire.isInsured === 'Non'
				&& (this.dataFormulaire.nomAssure === '' || this.dataFormulaire.prenomAssure === ''
					|| this.dataFormulaire.telephoneAssure === '' || this.dataFormulaire.nom_beneficiaire === ''
					|| this.dataFormulaire.date_naissance === '')) {
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Info",
					"Veuillez renseigner les infos de l'assuré !"
				);
				return false;
			}

			if (this.assurance_choisie === 'Assurance IA' && this.dataFormulaire.garantie._id === 4
				&& (this.dataFormulaire.numeroQuittance === '')) 
			{
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Info",
					"Veuillez renseigner le numéro de quittance !"
				);
				return false;
			}

			if (this.assurance_choisie === 'Assurance IA' && this.dataFormulaire.garantie._id === 4
				&& ( parseFloat(this.dataFormulaire.montant.replace(/\s/g, "")) <= 18000 )
			) {
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Info",
					"Veuillez renseigner un montant supérieur à 18 000 FCFA !"
				);
				return false;
			}


			if (this.assurance_choisie === 'Assurance Auto' && this.dataFormulaire.typeClient === 'Particulier'
				&& (this.dataFormulaire.nom === '' || this.dataFormulaire.prenom === '')) {
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Info",
					"Veuillez renseigner les infos de l'assuré !"
				);
				return false;
			}

			if (this.assurance_choisie === 'Assurance Auto' && this.dataFormulaire.typeClient === 'Entreprise'
				&& (this.dataFormulaire.nomEntreprise === '')) {
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Info",
					"Veuillez renseigner le nom de l'entreprise !"
				);
				return false;
			}


			if (this.dataFormulaire.code_apporteur.replace(/\s/g, "") !== "") {
				if (this.dataFormulaire.code_apporteur.length < 4 || this.dataFormulaire.code_apporteur.length > 4) {
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"info",
						"Info",
						"Veuillez renseigner le code de l'apporteur sur 4 caractères !"
					);
					return false;
				}

			} else {
				this.dataFormulaire.code_apporteur = "0000";
			}

			// Set toutes la values à false
			this.qrCodeUri = "";
			this.orangeOkay = false;
			this.orangeNOkay = false;
			this.mtnOkay = false;
			this.mtnNOkay = false;
			this.moovNOkay = false;
			this.moovOkay = false;
			this.tresorOkay = false;
			this.tresorNOkay = false;


			this.dataFormulaire.montant = this.dataFormulaire.montant.replace(/\s/g, "");


			this.montantSave =
				parseFloat(this.dataFormulaire.montant) +
				(this.pourboire !== "autre"
					? parseFloat(this.pourboire)
					: this.dataFormulaire.amountPourboire
						? parseFloat(this.dataFormulaire.amountPourboire)
						: 0);


			console.log(this.montantSave)

			// this.montantAPayer = parseFloat(this.dataFormulaire.montant);
			this.montantAPayer = parseFloat(this.dataFormulaire.montant);
			// this.montantSave = parseFloat(this.montantSave.replace(/\s/g, ""));

			// Init Payment process
			this.isLoading = true;
			let dataSend = new FormData();

			dataSend.append("assurance_choisie", this.assurance_choisie);
			dataSend.append("slug", this.url_data.slug);
			dataSend.append("montant", this.dataFormulaire.montant);
			dataSend.append("typeClient", this.dataFormulaire.typeClient);
			dataSend.append("code_apporteur", this.dataFormulaire.code_apporteur);
			dataSend.append("numero_mobile_apporteur", "225"+this.dataFormulaire.numero_mobile_apporteur.replace(/\s/g, ''));
			dataSend.append("nomEntreprise", this.dataFormulaire.nomEntreprise);
			dataSend.append("numeroPolice", this.dataFormulaire.numeroPolice);
			dataSend.append("immatriculation", this.dataFormulaire.immatriculation);
			dataSend.append("numeroQuittance", this.dataFormulaire.numeroQuittance);
			dataSend.append("marqueVehicule", this.dataFormulaire.marqueVehicule);
			dataSend.append("email", this.dataFormulaire.email);
			dataSend.append("isInsured", this.dataFormulaire.isInsured);
			dataSend.append("nom", this.dataFormulaire.nom);
			dataSend.append("prenom", this.dataFormulaire.prenom);
			dataSend.append("telephone", this.indice.i.code + this.dataFormulaire.telephone.replace(/\s/g, ''));
			dataSend.append("nomAssure", this.dataFormulaire.nomAssure);
			dataSend.append("date_naissance", this.dataFormulaire.date_naissance);
			dataSend.append("nom_beneficiaire", this.dataFormulaire.nom_beneficiaire);
			dataSend.append("prenomAssure", this.dataFormulaire.prenomAssure);
			dataSend.append("telephoneAssure", this.indice.i.code + this.dataFormulaire.telephoneAssure.replace(/\s/g, ''));
			dataSend.append("nombreTickets", this.assurance_choisie === "Assurance IA" ?
				this.dataFormulaire.garantie._id !== 4 ? this.dataFormulaire.garantie._id : 
				this.dataFormulaire.montant >= 7000 ? Math.floor(this.dataFormulaire.montant / 7000) : "" : ""
			);
			dataSend.append("nombrePoints", this.assurance_choisie === "Assurance Auto" ? 
				this.dataFormulaire.montant >= 75000 ? Math.floor(this.dataFormulaire.montant.replace(/\s/g, '') / 7000) : "" : ""
			);
			dataSend.append("indice", this.indice.i.code);
			dataSend.append("adresse_ip", this.dataYangoRechargement.adresse_ip);
			dataSend.append("device_type", this.$browserDetect.meta.name + " " + this.$browserDetect.meta.version + " " + this.$browserDetect.meta.ua);


			axios.post(apiUrl + "api/marchand/souscription_assurances/payer", dataSend)
			.then((response) => {

				this.isLoading = false;

				if (response.data.code === 1) {

					this.scrollToTop();

					// _reference.set(response.data["reference"]);
					this.url_data.reference = response.data["reference"];
					window.localStorage.setItem(
						"_reference_" + this.url_data.slug,
						response.data["reference"]
					);

					this.refPayment = response.data["reference"];
					// this.$refs['openPaymentModal'].show();
					this.showPaymentSection = true;
					this.formPayment = false;

					/*sweetAlert(
						this.$swal,
						true,
						"top-end",
						"success",
						this.$t("payment_modal.apaym.sweet_alert_text.value"),
						response.data["message"]
					);*/

					return false;
				}

				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"error",
					"Erreur",
					response.data["message"]
				);

			}).catch((error) => {

				this.isLoading = false;
				console.log(error);
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"error",
					"Erreur",
					"Une erreur est survenue, veuillez réessayer plus tard"
				);
			})
		},

		// Copy payment link on mobile device
		copy() {
			if (this.dataPage.dureeLien == "0") {
				try {
					var successful = navigator.clipboard.writeText(
						"https://pay.apaym.com/ref/" + this.refPayment
					);

					console.log(successful)
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"success",
						this.$t("payment_modal.apaym.sweet_alert_text.value"),
						""
					);
				} catch (err) {
					console.log(err);
				}

				return false;
			}

			if (this.showLienReduit == false) {
				try {
					var successful = navigator.clipboard.writeText(
						"https://pay.apaym.com/ref/" + this.refPayment
					);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"success",
						this.$t("payment_modal.apaym.sweet_alert_text.value"),
						""
					);
				} catch (err) {
					console.log(err);
				}

				return false;
			}

			this.$swal({
				title: "Donnée requise",
				html: "<div style='line-height: 1.5; font-size: 18px;'> Veuillez choisir la durée de validité de votre lien de paiement !</div>",
				icon: "info",
			});
		},

		validitePaiement() {
			if (this.validiteLien == "") {
				this.$swal({
					title: "Donnée requise",
					html: "<div style='line-height: 1.5; font-size: 18px;'> Veuillez choisir la durée de validité de votre lien de paiement !</div>",
					icon: "info",
				});

				return false;
			}

			this.$swal({
				title: "Êtes-vous certain(e) ?",
				html:
					"<div style='line-height: 1.5; font-size: 18px;'> Vous allez partager ce lien de paiement pour une durée de " +
					this.validiteLien +
					"</div>",
				icon: "info",
				showCancelButton: true,
				cancelButtonColor: "#6e7881",
				confirmButtonText: "Oui je valide",
				cancelButtonText: "Non",
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					this.isLoading = true;
					let dataSend = new FormData();
					dataSend.append("dureeValidite", this.validiteLien);
					dataSend.append(
						"reference",
						window.localStorage.getItem("_reference_" + this.url_data.slug)
					);

					axios
						.post(apiUrl + "api/paiement-marchand/validite-lien", dataSend)
						.then((response) => {
							this.isLoading = false;

							if (response.data["code"] == "1") {
								this.showLienReduit = false;
								sweetAlert(
									this.$swal,
									true,
									"top-end",
									"success",
									"Succès",
									response.data["message"]
								);
								return false;
							}

							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"info",
								"Info",
								response.data["message"]
							);
						})
						.catch((error) => {
							this.isLoading = false;
							console.log(error);
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"error",
								"Erreur",
								"Une erreur est survenue, veuillez réessayer plus tard"
							);
						});
				}
			});
		},

		copyLink() {
			this.$swal({
				title: "Donnée requise",
				html: "<div style='line-height: 1.5; font-size: 18px;'> Veuillez choisir la durée de validité de votre lien de paiement !</div>",
				icon: "info",
			});
		},

		// Copy payment link on desktop
		copyDesktop() {
			let testingCodeToCopy = document.querySelector("#paymentLinkDesktop");
			testingCodeToCopy.setAttribute("type", "text");
			testingCodeToCopy.select();

			try {
				var successful = document.execCommand("copy");
				var msg = successful ? "successful" : "unsuccessful";
				console.log(msg);
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Info",
					"Le lien de paiement a été copié !"
				);
			} catch (err) {
				console.log(err);
			}

			/* unselect the range */
			testingCodeToCopy.setAttribute("type", "hidden");
			window.getSelection().removeAllRanges();
		},

		onCancel() {
			console.log('Cancel')
		},

		// Reload la page
		reload() {
			location.reload();
		},

		choixAssurance() {

			this.show_assurance_form = false;

			this.dataFormulaire = {
				// nom: "",
				// prenom: "",
				// telephone: "",
				// email: "",
				// montant: "",
				// motif: "",
				// devise: "XOF",
				// amountPourboire: "",
				// society: "",
				// key_payment: "",
				// deleg: "",
				// grade: "",
                //
				// numeroCompte: "",
				// societeEnBourse: "",
				// nombreActions: "",
				// boutique: "",
				// immatriculation: "",
				// service: "",
				// formule: "",
                //
				// // NSIA ASSURANCE
				// typeClient: "",
				// nomEntreprise: "",
				// numeroPolice: "",
				// numeroQuittance: "",
				// marqueVehicule: "",
				// code_apporteur: "",
				// numero_mobile_apporteur: "",
				// isInsured: "",
                //
				// nomAssure: "",
				// prenomAssure: "",
				// telephoneAssure: "",
				// garantie: "",

                nomEleve: "",
                prenomEleve: "",
                contactParentEleve: "",
                matriculeEleve: "",
                ecoleEleve: ""

            };

			this.hideRecu = true;
			this.scrollToTop();
		},

		hideSectionPayment() {

			if (sessionStorage.getItem("n")) sessionStorage.removeItem("n");
			if (sessionStorage.getItem("e")) sessionStorage.removeItem("e");
			if (sessionStorage.getItem("f")) sessionStorage.removeItem("f");
			if (sessionStorage.getItem("m")) sessionStorage.removeItem("m");
			if (sessionStorage.getItem("p")) sessionStorage.removeItem("p");
			if (sessionStorage.getItem("k")) sessionStorage.removeItem("k");
			if (sessionStorage.getItem("r")) sessionStorage.removeItem("r");
			if (sessionStorage.getItem("t")) sessionStorage.removeItem("t");
			if (sessionStorage.getItem("i")) sessionStorage.removeItem("i");

			this.dataFormulaire = {
				nom: "",
				prenom: "",
				telephone: "",
				email: "",
				montant: "",
				motif: "",
				devise: "XOF",
				amountPourboire: "",
				society: "",
				key_payment: "",
				deleg: "",
				grade: "",

				numeroCompte: "",
				societeEnBourse: "",
				nombreActions: "",
				boutique: "",
				immatriculation: "",
				service: "",
				formule: "",

				// NSIA ASSURANCE
				typeClient: "",
				nomEntreprise: "",
				numeroPolice: "",
				numeroQuittance: "",
				marqueVehicule: "",
				code_apporteur: "",
				numero_mobile_apporteur: "",
				isInsured: "",

				nomAssure: "",
				prenomAssure: "",
				telephoneAssure: "",
				garantie: ""
			};

			this.hideRecu = true;
			this.scrollToTop();
			this.showPaymentSection = !this.showPaymentSection;
			this.formPayment = !this.formPayment;
		},

		// BICICI payment, select type of card (Visa or Mastercard)
		selectCardType(event) {
			this.cardType = event.target.value;
		},

		// Fermer un modal, en paramètre on a la référence du modal que l'on souhaite fermer
		closeModal(refModal) {
			this.scrollToTop();

			if (refModal == "openPaymentModal") {
				this.logoOfSelectedService = "visa-qr-1.png";
			}

			if (this.typeForm == "quickly") {
				this.montantSave !== ""
					? (this.dataFormulaireQuick.montant = this.montantSave)
					: console.log("");
			} else {
				this.montantSave !== ""
					? (this.dataFormulaire.montant = this.montantSave)
					: console.log("");
			}

			this.$refs[refModal].hide();
		},

		onCancelAPaym() {
			console.log("Loader APaym dismiss");
		},

		onCancelAPaymAuth() {
			console.log("Loader APaym Auth dismiss");
		},

		onCancelQr() {
			console.log("Loader QR Code dismiss");
		},

		onCancelMomo() 
		{
			console.log("Loader MoMo dismiss");
		},

		onCancelTresorPay() {
			console.log("Loader Tresor dismiss");
		},

		onCancelOrange() {
			console.log("Loader Orange money dismiss");
		},

		onCancelWave() {
			console.log("Loader Wave dismiss");
		},

		onCancelFlooz() {
			console.log("Loader Flooz dismiss");
		},

		onCancelBicici() {
			console.log("Loader BICICI dismiss");
		},

		// Vérifier objet
		isObject(value) {
			return (
				typeof value === "object" && value !== null && !Array.isArray(value)
			);
		},

		// Visa QR
		generateQr(ref, feesPaidBy) {
			this.isLoadingQr = true;
			let dataSend = new FormData();
			dataSend.append("reference", ref);
			dataSend.append("feesPaidBy", feesPaidBy);

			axios
				.post(apiUrl + "api/paiement-marchand/generer-qr-code", dataSend)
				.then((response) => {
					this.isLoadingQr = false;
					if (response.data.code == "success") {
						this.qrCodeUri = response.data.qrCodeUri;
						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
				})
				.catch((error) => {
					this.isLoadingQr = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
		},

		// Visa QR, payer les frais
		feeAmountQr() 
		{
			this.checkedFeesApaym = !this.checkedFeesApaym;

			if (this.typeForm === "normal") 
			{

				let montant = this.montantSave; let frais = 0; let feesPaidBy = 0;
			
				if (this.checkedFeesApaym) {
					this.dataFormulaire.montant =
						this.dataPage.frais == "3"
							? parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
							)
							: parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym) / 100)
							);

					frais =
						this.dataPage.frais == "3"
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
							)
							: parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym) / 100)
							);
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

					if (this.dataPage.frais == "2") {

						feesPaidBy = 2;
					} else if (this.dataPage.frais == "1") {
						// Payer les frais est facultatif
						if (this.checkedFeesApaym == true) {
							feesPaidBy = 1;
						}
					} else if (this.dataPage.frais == "3") {
						// Frais partagés
						if (this.checkedFeesApaym == true) {
							feesPaidBy = 3;
						}
					}

					this.generateQr(
						window.localStorage.getItem("_reference_" + this.url_data.slug),
						frais,
						this.dataFormulaire.montant
					);

					return false;
				}

				this.dataFormulaire.montant = this.montantSave;
				this.generateQr(
					window.localStorage.getItem("_reference_" + this.url_data.slug),
					feesPaidBy
				);

			} else {
				let montant = this.montantSave;
				let frais = 0;
				let feesPaidBy = 0;
				if (this.checkedFeesApaym) {
					console.log(montant);
					this.dataFormulaireQuick.montant =
						this.dataPage.frais == "3"
							? parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
							)
							: parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym) / 100)
							);

					frais =
						this.dataPage.frais == "3"
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
							)
							: parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym) / 100)
							);
					feesPaidBy = 3;
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

					this.generateQr(
						window.localStorage.getItem("_reference_" + this.url_data.slug),
						feesPaidBy
					);


					return false;
				}

				// Si le client doit obligatoirement payer les frais
				if (this.dataPage.frais == "2") {

					feesPaidBy = 2;
				} else if (this.dataPage.frais == "1") {
					// Payer les frais est facultatif
					if (this.checkedFeesOm == true) {
						feesPaidBy = 1;
					}
				} else if (this.dataPage.frais == "3") {
					// Frais partagés
					if (this.checkedFeesOm == true) {
						feesPaidBy = 3;
					}
				}
				this.dataFormulaireQuick.montant = this.montantSave;
				frais = 0;
				this.generateQr(
					window.localStorage.getItem("_reference_" + this.url_data.slug),
					feesPaidBy
				);
			}
		},

		// ECOBANK QR
		generateEcobankQr(ref, frais, montant) {
			this.isLoadingEcobank = true;
			let dataSend = new FormData();
			dataSend.append("reference", ref);
			dataSend.append("frais", frais);
			dataSend.append("montant", montant);
			dataSend.append(
				"indice",
				this.indice.i.code !== "" ? this.indice.i.code : "Indice inconnu"
			);

			axios
				.post(
					apiUrl + "api/paiement-marchand/generer-dynamic-qr-code-ecobank",
					dataSend
				)
				.then((response) => {
					this.isLoadingEcobank = false;
					if (response.data.code == "success") {
						this.ecobankQrCodeUri = response.data.qrCodeUri;
						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
				})
				.catch((error) => {
					this.isLoadingEcobank = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
		},

		// ECOBANK QR, payer les frais
		feeAmountEcobankQr() {
			this.checkedFeesApaym = !this.checkedFeesApaym;

			if (this.typeForm == "normal") {
				let montant = this.montantSave;
				let frais = 0;
				if (this.checkedFeesApaym) {
					// console.log(montant) ;
					this.dataFormulaire.montant = Math.round(
						parseFloat(montant) +
						(this.checkedFeesApaym
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym) / 100)
							)
							: 0)
					);

					frais = this.checkedFeesApaym
						? Math.round(
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym) / 100)
							)
						)
						: 0;

					this.generateEcobankQr(
						window.localStorage.getItem("_reference_" + this.url_data.slug),
						frais,
						this.dataFormulaire.montant
					);

					return false;
				}

				this.dataFormulaire.montant = this.montantSave;
				frais = 0;
				console.log("Montant = " + this.dataFormulaire.montant);
				console.log("frais = " + frais);
				this.generateEcobankQr(
					window.localStorage.getItem("_reference_" + this.url_data.slug),
					"",
					this.dataFormulaire.montant
				);
			} else {
				// this.sauvegardeMontant = this.dataFormulaireQuick.montant ;
				let montant = this.montantSave;
				let frais = 0;
				if (this.checkedFeesApaym) {
					console.log(montant);
					this.dataFormulaireQuick.montant = Math.round(
						parseFloat(montant) +
						(this.checkedFeesApaym
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym) / 100)
							)
							: 0)
					);

					frais = this.checkedFeesApaym
						? Math.round(
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym) / 100)
							)
						)
						: 0;

					this.generateEcobankQr(
						window.localStorage.getItem("_reference_" + this.url_data.slug),
						frais,
						this.dataFormulaireQuick.montant
					);

					console.log("Montant = " + this.dataFormulaireQuick.montant);
					console.log("frais = " + frais);

					return false;
				}

				this.dataFormulaireQuick.montant = this.montantSave;
				frais = 0;
				console.log("Montant = " + this.dataFormulaireQuick.montant);
				console.log("frais = " + frais);
				this.generateEcobankQr(
					window.localStorage.getItem("_reference_" + this.url_data.slug),
					"",
					this.dataFormulaireQuick.montant
				);
			}
		},

		// APaym payment code
		sendAPaym() {
			if (
				this.dataApaymPay.numApaym.replace(/\s/g, "") == "" ||
				this.dataApaymPay.codeApaym.replace(/\s/g, "") == ""
			) {
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Info",
					"Veuillez renseigner votre numéro de téléphone APaym ainsi que votre code de paiement !"
				);
				return false;
			}

			let montant = this.montantSave,
				frais = 0,
				feesPaidBy = 0;
			// Si le client doit obligatoirement payer les frais
			if (this.dataPage.frais == "2") {
				montant =
					parseFloat(this.montantSave) +
					(parseFloat(this.montantSave) *
						parseFloat(this.dataPage.taux_apaym)) /
					100;
				frais =
					(parseFloat(this.montantSave) *
						parseFloat(this.dataPage.taux_apaym)) /
					100;
				feesPaidBy = 2;
			} else if (this.dataPage.frais == "1") {
				// Payer les frais est facultatif
				if (this.checkedFeesApaym == true) {
					// montant = parseFloat(this.montantSave) + ( parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_apaym) / 100 ) ;
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_apaym)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_apaym)) /
						100;
					feesPaidBy = 1;
				}
			} else if (this.dataPage.frais == "3") {
				// Frais partagés
				if (this.checkedFeesApaym == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_apaym / 2)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_apaym / 2)) /
						100;
					feesPaidBy = 3;
				}
			}

			this.isLoadingAPaym = true;
			let dataSend = new FormData();
			// dataSend.append("reference", _reference.get());
			dataSend.append(
				"reference",
				window.localStorage.getItem("_reference_" + this.url_data.slug)
			);
			dataSend.append(
				"numAPaym",
				this.dataApaymPay.indice.code +
				"" +
				this.dataApaymPay.numApaym.replace(/\s/g, "")
			);
			dataSend.append(
				"codePaiement",
				this.dataApaymPay.codeApaym.replace(/\s/g, "")
			);
			dataSend.append("feesPaidBy", feesPaidBy);

			axios
				.post(apiUrl + "api/paiement-marchand/code-paiement", dataSend)
				.then((response) => {
					this.isLoadingAPaym = false;

					if (response.data.code == 1) {
						// this.dataPage.deleg_pdci == "3" ? this.doYangoRechargement() : console.log("Call back") ;
						this.hideRecu = false;

						this.showPaymentSection = false;
						if (this.typeForm == "normal") {
							this.formPayment = true;
						} else {
							this.formQuiclyPayment = true;
						}

						if (this.slug_boutique != null) {
							setTimeout(() => {
								window.location.href =
									"https://shopapaym.weblogy.net/" + this.slug_boutique;
							}, 2000);
							return;
						}

						this.dataPage.urlRedirection !== "" &&
							this.dataPage.urlRedirection !== null
							? setTimeout(
								(window.location.href = this.dataPage.urlRedirection),
								12000
							)
							: console.log("");

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
				})
				.catch((error) => {
					this.isLoadingAPaym = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
		},

		// APaym authentication - Send connection code
		sendAPaymAuth() {
			if (this.dataApaymPayAuth.numApaym.replace(/\s/g, "") == "") {
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Info",
					"Veuillez renseigner numéro de téléphone APaym ainsi que votre code de paiement !"
				);
				return false;
			}

			this.isLoadingAPaymAuth = true;
			this.hideCodePay = false;

			let dataSend = new FormData();
			dataSend.append(
				"numero",
				this.dataApaymPayAuth.indice.code +
				"" +
				this.dataApaymPayAuth.numApaym.replace(/\s/g, "")
			);

			axios
				.post(apiUrl + "api/paiement-marchand/authentification-apaym", dataSend)
				.then((response) => {
					this.isLoadingAPaymAuth = false;

					if (response.data.code == 1) {
						this.hideCodePay = true;
						this.dataApaymPayAuth.token = response.data.token;
						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
				})
				.catch((error) => {
					this.isLoadingAPaymAuth = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
		},

		// APaym authentication
		sendAPaymToken() {
			if (
				this.dataApaymPayAuth.numApaym.replace(/\s/g, "") == "" ||
				this.dataApaymPayAuth.codeApaym.replace(/\s/g, "") == ""
			) {
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Info",
					"Veuillez renseigner numéro de téléphone APaym ainsi que votre code de connexion !"
				);
				return false;
			}

			this.isLoadingAPaymAuth = true;
			let dataSend = new FormData();
			dataSend.append(
				"numero",
				this.dataApaymPayAuth.indice.code +
				"" +
				this.dataApaymPayAuth.numApaym.replace(/\s/g, "")
			);
			dataSend.append(
				"codeConnexion",
				this.dataApaymPayAuth.codeApaym.replace(/\s/g, "")
			);
			dataSend.append("token", this.dataApaymPayAuth.token);

			axios
				.post(apiUrl + "api/paiement-marchand/verifier-otp", dataSend)
				.then((response) => {
					this.isLoadingAPaymAuth = false;

					if (response.data.code == 1) {
						// Mask APaym auth form
						this.loginApaym = false;
						this.hideCodePay = false;

						// Show list of cards
						this.cards = [];
						this.cardsLength = response.data["nombre_cards"];
						this.showCard = true;

						for (let card of response.data["cards"]) {
							if (card.card_key_name == "NSIACIVISAPCN") {
								this.cards.push({
									id: card.id,
									bank: card.bank,
									card_color: `background-image: linear-gradient(to right bottom, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color})`,
									last_4_digit: card.last_4_digit,
									nickname: card.nickname,
									favori_card: card.favori_card,
									favori_card_: card.favori_card ? 1 : 0,
									imgCard: "Carte-apaym-01.png",
								});
							} else if (card.card_key_name == "UBACIVISAPPB") {
								this.cards.push({
									id: card.id,
									bank: card.bank,
									card_color: `background-image: linear-gradient(to right bottom, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color})`,
									last_4_digit: card.last_4_digit,
									nickname: card.nickname,
									favori_card: card.favori_card,
									favori_card_: card.favori_card ? 1 : 0,
									imgCard: "Carte-apaym-06.png",
								});
							} else if (card.card_key_name == "UBACIVISAPPN") {
								this.cards.push({
									id: card.id,
									bank: card.bank,
									card_color: `background-image: linear-gradient(to right bottom, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color})`,
									last_4_digit: card.last_4_digit,
									nickname: card.nickname,
									favori_card: card.favori_card,
									favori_card_: card.favori_card ? 1 : 0,
									imgCard: "Carte-apaym-05.png",
								});
							} else if (card.card_key_name == "UBACIVISAVCN") {
								this.cards.push({
									id: card.id,
									bank: card.bank,
									card_color: `background-image: linear-gradient(to right bottom, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color})`,
									last_4_digit: card.last_4_digit,
									nickname: card.nickname,
									favori_card: card.favori_card,
									favori_card_: card.favori_card ? 1 : 0,
									imgCard: "Carte-Virtuelle-APAYM-UBA.png",
								});
							} else {
								this.cards.push({
									id: card.id,
									bank: card.bank,
									card_color: `background-image: linear-gradient(to right bottom, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color}, ${card.card_color})`,
									last_4_digit: card.last_4_digit,
									nickname: card.nickname,
									favori_card: card.favori_card,
									favori_card_: card.favori_card ? 1 : 0,
									imgCard: "visa-autre.png",
								});
							}
						}

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);

					this.showCard = false;
					this.hideCodePay = false;
					this.loginApaym = true;
					this.dataApaymPayAuth.codeApaym = "";
					this.dataApaymPayAuth.token = "";
				})
				.catch((error) => {
					this.isLoadingAPaymAuth = false;
					this.showCard = false;
					this.hideCodePay = false;
					this.loginApaym = true;
					this.dataApaymPayAuth.codeApaym = "";
					this.dataApaymPayAuth.token = "";

					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
		},

		// APaym payment with card from wallet
		payWithApaym(carte) {
			let devise =
				this.dataFormulaire.devise !== ""
					? this.dataFormulaire.devise
					: this.dataFormulaireQuick.devise;
			let montant = this.montantSave,
				frais = 0,
				feesPaidBy = 0;
			if (this.dataPage.frais == "2") {
				montant =
					parseFloat(this.montantSave) +
					(parseFloat(this.montantSave) *
						parseFloat(this.dataPage.taux_apaym)) /
					100;

				frais =
					(parseFloat(this.montantSave) *
						parseFloat(this.dataPage.taux_apaym)) /
					100;
				feesPaidBy = 2;
			} else if (this.dataPage.frais == "1") {
				// Payer les frais est facultatif
				if (this.checkedFeesApaym == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_apaym)) /
						100;

					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_apaym)) /
						100;
					feesPaidBy = 1;
				}
			} else if (this.dataPage.frais == "3") {
				// Frais partagés
				if (this.checkedFeesApaym == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_apaym / 2)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_apaym / 2)) /
						100;
					feesPaidBy = 3;
				}
			}

			this.$swal({
				title: this.$t("payment_modal.apaym.text_validate_payment_title.value"),
				html:
					"<div style='line-height: 1.5; font-size: 18px;'>" +
					this.$t("payment_modal.apaym.text_validate_payment.value") +
					"" +
					carte.nickname +
					" Visa **** <b>" +
					carte.last_4_digit +
					"</b>" +
					this.$t("payment_modal.apaym.text_validate_payment2.value") +
					"<b>" +
					new Intl.NumberFormat("fr-FR", {
						style: "currency",
						currency: devise,
					}).format(parseFloat(montant)) +
					"</b> " +
					this.$t("payment_modal.apaym.text_validate_payment3.value") +
					"</div>",
				icon: "info",
				showCancelButton: true,
				cancelButtonColor: "#6e7881",
				confirmButtonText: this.$t(
					"payment_modal.apaym.text_validate_payment_yes.value"
				),
				cancelButtonText: this.$t(
					"payment_modal.apaym.text_validate_payment_no.value"
				),
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					this.isLoadingAPaymAuth = true;
					let dataSend = new FormData();
					dataSend.append(
						"numero",
						this.dataApaymPayAuth.indice.code +
						"" +
						this.dataApaymPayAuth.numApaym.replace(/\s/g, "")
					);
					dataSend.append("token", this.dataApaymPayAuth.token);
					dataSend.append(
						"reference",
						window.localStorage.getItem("_reference_" + this.url_data.slug)
					);
					dataSend.append("card_id", carte.id);
					dataSend.append("feesPaidBy", feesPaidBy);

					axios
						.post(apiUrl + "api/paiement-marchand/apaym", dataSend)
						.then((response) => {
							this.isLoadingAPaymAuth = false;
							this.$swal.close();

							if (response.data.code == 1) {
								this.hideRecu = false;
								this.dataApaymPayAuth.codeApaym = "";
								this.dataApaymPayAuth.token = "";

								this.showPaymentSection = false;
								if (this.typeForm == "normal") {
									this.formPayment = true;
								} else {
									this.formQuiclyPayment = true;
								}

								if (this.slug_boutique != null) {
									setTimeout(() => {
										window.location.href =
											"https://shopapaym.weblogy.net/" + this.slug_boutique;
									}, 2000);
									return;
								}

								this.dataPage.urlRedirection !== "" &&
									this.dataPage.urlRedirection !== null
									? setTimeout(
										(window.location.href = this.dataPage.urlRedirection),
										12000
									)
									: console.log("");

								return false;
							}

							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"error",
								"Erreur",
								response.data["message"]
							);
							this.showCard = false;
							this.hideCodePay = false;
							this.loginApaym = true;
							this.dataApaymPayAuth.codeApaym = "";
							this.dataApaymPayAuth.token = "";
						})
						.catch((error) => {
							this.isLoadingAPaymAuth = false;
							console.log(error);
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"error",
								"Erreur",
								"Une erreur est survenue, veuillez réessayer plus tard"
							);
							this.showCard = false;
							this.hideCodePay = false;
							this.loginApaym = true;
							this.dataApaymPayAuth.codeApaym = "";
							this.dataApaymPayAuth.token = "";
						});
				}
			});
		},

		// Disconnect APaym account
		disconnectApaym() {
			this.showCard = false;
			this.hideCodePay = false;
			this.loginApaym = true;

			this.dataApaymPayAuth.codeApaym = "";
			this.dataApaymPayAuth.token = "";
		},

		sendVisa() {
			if (
				this.dataVisa.pan.replace(/\s/g, "") == "" ||
				this.dataVisa.expiration.replace(/\s/g, "") == "" ||
				this.dataVisa.cvv.replace(/\s/g, "") == ""
			) {
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Info",
					"Veuillez renseigner les informations de votre carte Visa !"
				);
				return false;
			}
		},

		// Bank card - BICICI Form
		sendBicici() {
			if (this.cardType == "") {
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Attention",
					"Veuillez préciser si votre carte est Visa ou Mastercard !"
				);
				return false;
			}

			this.biciciNOkay = false;
			this.isLoadingBicici = true;

			let dataSend = new FormData();
			dataSend.append(
				"reference",
				window.localStorage.getItem("_reference_" + this.url_data.slug)
			);
			dataSend.append("cardType", this.cardType);

			axios
				.post(apiUrl + "api/payment-merchant/bicici", dataSend)
				.then((response) => {
					this.isLoadingBicici = false;

					if (response.data.code == 1) {
						if (response.data.status == "INITIALIZED") {
							this.contentBicici = response.data.redirect_url;
							this.isBicici = !this.isBicici;
							this.formBicici = !this.formBicici;
							this.modalBicici = true;
							return false;
						}

						sweetAlert(
							this.$swal,
							true,
							"top-end",
							"error",
							"Erreur",
							"Une erreur est survenue, veuillez réessayer plus tard"
						);
						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
					return false;
				})
				.catch((error) => {
					this.isLoadingBicici = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
		},

		// Vérifier qu'un numéro de carte est Visa
		isVisaCardNumberValid(cardNumber) {
			console.log(cardNumber);

			// Regex to check valid
			// cardNumber
			let regex = new RegExp(/^4[0-9]{12}(?:[0-9]{3})?$/);

			// if cardNumber
			// is empty return false
			if (cardNumber == null) {
				return "false";
			}

			// Return true if the cardNumber
			// matched the ReGex
			if (regex.test(cardNumber) == true) {
				return "true";
			} else {
				return "false";
			}
		},

		// Vérifier qu'un numéro de carte est Mastercard
		isMastercardCardNumberValid(cardNumber) {
			console.log(cardNumber);

			// Regex to check valid
			// cardNumber
			let regex = new RegExp(
				/^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$/
			);

			// if cardNumber
			// is empty return false
			if (cardNumber == null) {
				return "false";
			}

			// Return true if the cardNumber
			// matched the ReGex
			if (regex.test(cardNumber) == true) {
				return "true";
			} else {
				return "false";
			}
		},

		sendCardPayment() {

			let montant = this.montantSave,
				frais = 0,
				cardHolder = "",
				whoPayFees = "MARCHAND";

			if (this.dataPage.frais == "2") {
				montant =
					parseFloat(this.montantSave) +
					(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
					100;

				frais =
					(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
					100;

			} else if (this.dataPage.frais == "1") {

				if (this.checkedFeesCb == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
						100;

					frais =
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
						100;
				}
			} else if (this.dataPage.frais == "3") {
				// Frais partagés
				if (this.checkedFeesCb == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_cb / 2)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_cb / 2)) /
						100;
					whoPayFees = "CLIENT";
				}
			}

			if (
				this.dataVisaCard.pan.replace(/\s/g, "") == "" ||
				this.dataVisaCard.expirationMonth == "" ||
				this.dataVisaCard.expirationYear == "" ||
				this.dataVisaCard.cvv.replace(/\s/g, "") == ""
			) {
				this.$swal({
					title: "Information",
					icon: "info",
					html: "<strong class='font-16' style='line-height: 1.5'>Veuillez renseigner toutes les informations du formulaire !</strong>",
				});

				return false;
			}

			this.isLoadingBicici = true;

			if (this.dataVisaCard.cardHolderName.replace(/\s/g, "") == "") {
				this.dataVisaCard.cardHolderName = "Client APaym";
			}

			if (this.menuSelected == "VISA") {

				/*if (!this.formRechargement.issuer_name) {
					this.isLoadingBicici = false;
					this.$swal({
						title: "Information",
						icon: "info",
						html: "<strong class='font-16' style='line-height: 1.5'>Veuillez renseigner une carte Visa valide !</strong>",
					});
					
					return false;
				}*/

				if (
					this.isVisaCardNumberValid(
						this.dataVisaCard.pan.replace(/\s/g, "")
					) == "true"
				) {
					if (this.dataPage.merchant == "21449" || this.dataPage.merchant == "24545") {
						window.location.href =
							"https://carte.abidjan.net/mobilemoney/paymentoffshore.php?pan=" +
							this.dataVisaCard.pan.replace(/\s/g, "") +
							"&amount=" +
							montant +
							"&cardholderName=" +
							this.dataVisaCard.cardHolderName.replace(/\s/g, "") +
							"&expiry=" +
							this.dataVisaCard.expirationYear +
							"-" +
							this.dataVisaCard.expirationMonth +
							"&cvv=" +
							this.dataVisaCard.cvv +
							"&frais=" +
							frais +
							"&cardType=" +
							this.menuSelected +
							"&slug=" +
							this.url_data.slug +
							"&reference=" +
							window.localStorage.getItem("_reference_" + this.url_data.slug);
						return false;
					}

					window.location.href =
						"https://carte.abidjan.net/mobilemoney/paymentrequest.php?pan=" +
						this.dataVisaCard.pan.replace(/\s/g, "") +
						"&amount=" +
						montant +
						"&cardholderName=" +
						this.dataVisaCard.cardHolderName.replace(/\s/g, "") +
						"&expiry=" +
						this.dataVisaCard.expirationYear +
						"-" +
						this.dataVisaCard.expirationMonth +
						"&cvv=" +
						this.dataVisaCard.cvv +
						"&frais=" +
						frais +
						"&cardType=" +
						this.menuSelected +
						"&slug=" +
						this.url_data.slug +
						"&reference=" +
						window.localStorage.getItem("_reference_" + this.url_data.slug);

					return false;
				}

				this.isLoadingBicici = false;
				this.$swal({
					title: "Information",
					icon: "info",
					html: "<strong class='font-16' style='line-height: 1.5'>Veuillez renseigner une carte Visa valide !</strong>",
				});
			} else {
				if (
					this.isMastercardCardNumberValid(
						this.dataVisaCard.pan.replace(/\s/g, "")
					) == "true"
				) {
					if (this.dataPage.merchant == "21449" || this.dataPage.merchant == "24545") {
						window.location.href =
							"https://carte.abidjan.net/mobilemoney/paymentoffshore.php?pan=" +
							this.dataVisaCard.pan.replace(/\s/g, "") +
							"&amount=" +
							montant +
							"&cardholderName=" +
							this.dataVisaCard.cardHolderName.replace(/\s/g, "") +
							"&expiry=" +
							this.dataVisaCard.expirationYear +
							"-" +
							this.dataVisaCard.expirationMonth +
							"&cvv=" +
							this.dataVisaCard.cvv +
							"&frais=" +
							frais +
							"&cardType=" +
							this.menuSelected +
							"&slug=" +
							this.url_data.slug +
							"&reference=" +
							window.localStorage.getItem("_reference_" + this.url_data.slug);
						return false;
					}

					window.location.href =
						"https://carte.abidjan.net/mobilemoney/paymentrequest.php?pan=" +
						this.dataVisaCard.pan.replace(/\s/g, "") +
						"&amount=" +
						montant +
						"&cardholderName=" +
						this.dataVisaCard.cardHolderName.replace(/\s/g, "") +
						"&expiry=" +
						this.dataVisaCard.expirationYear +
						"-" +
						this.dataVisaCard.expirationMonth +
						"&cvv=" +
						this.dataVisaCard.cvv +
						"&frais=" +
						frais +
						"&cardType=" +
						this.menuSelected +
						"&slug=" +
						this.url_data.slug +
						"&reference=" +
						window.localStorage.getItem("_reference_" + this.url_data.slug);
					return false;
				}

				this.isLoadingBicici = false;
				this.$swal({
					title: "Information",
					icon: "info",
					html: "<strong class='font-16' style='line-height: 1.5'>Veuillez renseigner une Mastercard valide !</strong>",
				});
			}

			// return false;

			/*window.location.href = "https://carte.abidjan.net/mobilemoney/paymentrequest.php?pan="+this.dataVisaCard.pan.replace(/\s/g, '')
				+"&amount="+ montant +"&cardholderName="+ this.dataVisaCard.cardHolderName + "&expiry="+ this.dataVisaCard.expirationYear+"-"+this.dataVisaCard.expirationMonth
				+ "&cvv="+ this.dataVisaCard.cvv + "&frais="+ frais +  "&cardType=" + this.menuSelected + "&reference="+ window.localStorage.getItem("_reference_"+ this.url_data.slug)
			;*/

			// window.location.href = "https://carte.abidjan.net/pro/api/init-card-payment/"+ window.localStorage.getItem("_reference_"+ this.dataPage.slug) ;
			// window.location.href = apiUrl +"api/paiement-marchand/paiement-carte-bancaire/"+ this.url_data.reference.substring(0, this.url_data.reference.length - 1) +"/"+ Math.round(montant) +"/"+ Math.round(frais) ;
		},

		sendCardPaymentUba() {
			let montant = this.montantSave,
				frais = 0,
				feesPaidBy = 0;

			if (this.dataPage.frais == "2") {
				montant =
					parseFloat(this.montantSave) +
					(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
					100;
				frais =
					(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
					100;
				feesPaidBy = 2;
			} else if (this.dataPage.frais == "1") {
				if (this.checkedFeesCb == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
						100;
					frais =
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_cb)) /
						100;
					feesPaidBy = 1;
				}
			} else if (this.dataPage.frais == "3") {
				// Frais partagés
				if (this.checkedFeesCb == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_cb / 2)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_cb / 2)) /
						100;
					feesPaidBy = 3;
				}
			}

			window.location.href =
				apiUrl +
				"api/paiement-marchand/paiement-carte-bancaireTest/" +
				this.url_data.reference.substring(
					0,
					this.url_data.reference.length - 1
				) +
				"/" +
				feesPaidBy;
		},

		// Orange money payment
		sendOM(option) {
			this.orangeOkay = false;
			this.orangeNOkay = false;
			this.isLoadingOrange = true;

			let montant = this.montantSave,
				frais = 0,
				feesPaidBy = 0;
			// Si le client doit obligatoirement payer les frais
			if (this.dataPage.frais == "2") {
				montant =
					parseFloat(this.montantSave) +
					(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_om)) /
					100;
				frais =
					(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_om)) /
					100;
				feesPaidBy = 2;
			} else if (this.dataPage.frais == "1") {
				// Payer les frais est facultatif
				if (this.checkedFeesOm == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_om)) /
						100;
					frais =
						(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_om)) /
						100;
					feesPaidBy = 1;
				}
			} else if (this.dataPage.frais == "3") {
				// Frais partagés
				if (this.checkedFeesOm == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_om / 2)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_om / 2)) /
						100;
					feesPaidBy = 3;
				}
			}

			let dataSend = new FormData();
			dataSend.append(
				"reference",
				window.localStorage.getItem("_reference_" + this.url_data.slug)
			);
			dataSend.append("feesPaidBy", feesPaidBy);

			axios
				.post(apiUrl + "api/paiement-marchand/omoney", dataSend)
				.then((response) => {
					if (response.data.code == 1) {
						if (response.data.url == null || response.data.url == "") {
							this.isLoadingOrange = false;
							this.$refs["openOrangeMoneyModal"].hide();
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"error",
								"Erreur",
								"Veuillez reprendre tout le processus, si le problème persiste, veuillez essayer un autre moyen de paiement svp."
							);

							return false;
						}

						if (option == "app") {
							window.location.href = response.data.url;
							return false;
						}

						if (this.isMobile()) {
							window.location.href = "tel:%23 144*82 %23";
						}

						// Open iframe in Modal
						this.isLoadingOrange = false;
						this.urlOrange = response.data.url;
						this.orangeOkay = true;
						this.modalOrange = true;
						this.$refs["openOrangeMoneyModal"].show();

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
					this.isLoadingOrange = false;
				})
				.catch((error) => {
					this.isLoadingOrange = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
		},

		// Wave payment
		sendWave() {

			this.waveOkay = false;
			this.waveNOkay = false;
			this.isLoadingWave = true;

			let montant = this.montantSave, frais = 0, feesPaidBy = 0;

			// Si le client doit obligatoirement payer les frais
			if (this.dataPage.frais == "2") {

				montant =
					parseFloat(this.montantSave) +
					(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_wave)) /
					100;
				frais =
					(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_wave)) /
					100;
				feesPaidBy = 2;
			} else if (this.dataPage.frais == "1") {
				// Payer les frais est facultatif
				if (this.checkedFeesWave == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_wave)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_wave)) /
						100;
					feesPaidBy = 1;
				}
			} else if (this.dataPage.frais == "3") {
				// Frais partagés
				if (this.checkedFeesWave == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_wave / 2)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_wave / 2)) /
						100;
					feesPaidBy = 3;
				}
			}

			let dataSend = new FormData();
			dataSend.append(
				"reference",
				window.localStorage.getItem("_reference_" + this.url_data.slug)
			);
			dataSend.append("feesPaidBy", feesPaidBy);

			axios
				.post(apiUrl + "api/paiement-marchand/wave", dataSend)
				.then((response) => {
					this.isLoadingWave = false;
					this.waveOkay = true;

					if (response.data.code == 1) {
						if (response.data.url == null || response.data.url == "") {
							this.isLoadingWave = false;
							this.$refs["openWaveModal"].hide();
							sweetAlert(
								this.$swal,
								true,
								"top-end",
								"error",
								"Erreur",
								"Veuillez reprendre tout le processus, si le problème persiste, veuillez essayer un autre moyen de paiement svp."
							);

							return false;
						}

						// Il s'agit d'un téléphone mobile, on lance l'app Wave directement
						// L'utilisateur doit s'assurer d'avoir installé la version à jour de l'app
						if (this.isMobile()) {
							window.location.href = response.data.url;
							return false;
						}

						// Open iframe in Modal
						this.urlWave = response.data.url;
						this.modalWave = true;
						this.$refs["openWaveModal"].show();

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
					this.isLoadingWave = false;
				})
				.catch((error) => {
					this.isLoadingWave = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
		},

		// MTN MoMo payment
		sendMoMo() {
			if (this.dataMoMo.numero.replace(/\s/g, "") == "") {
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Info",
					"Veuillez renseigner votre numéro MoMo !"
				);
				return false;
			}

			this.mtnOkay = false;
			this.mtnNOkay = false;
			this.isLoadingMomo = true;

			let montant = this.montantSave,
				frais = 0,
				feesPaidBy = 0;
			// Si le client doit obligatoirement payer les frais
			if (this.dataPage.frais == "2") {
				montant =
					parseFloat(this.montantSave) +
					(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_mtn)) /
					100;
				frais =
					(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_mtn)) /
					100;
				feesPaidBy = 2;
			} else if (this.dataPage.frais == "1") {
				// Payer les frais est facultatif
				if (this.checkedFeesMtn == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_mtn)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_mtn)) /
						100;
					feesPaidBy = 1;
				}
			} else if (this.dataPage.frais == "3") {
				// Payer les frais est partagé
				if (this.checkedFeesMtn == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_mtn / 2)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_mtn / 2)) /
						100;
					feesPaidBy = 3;
				}
			}

			let dataSend = new FormData();
			dataSend.append(
				"reference",
				window.localStorage.getItem("_reference_" + this.url_data.slug)
			);
			dataSend.append("numero", this.dataMoMo.numero.replace(/\s/g, ""));
			dataSend.append("montant", montant);
			dataSend.append("frais", frais);
			dataSend.append("feesPaidBy", feesPaidBy);

			axios
				.post(apiUrl + "api/paiement-marchand/momo", dataSend)
				.then((response) => {
					if (response.data.code == 1000) {
						this.isLoadingMomo = false;
						this.mtnOkay = true;
						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
					this.isLoadingMomo = false;
				})
				.catch((error) => {
					this.isLoadingMomo = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
		},

		// Moov money payment
		sendMoov() {
			if (this.dataMoov.numero.replace(/\s/g, "") == "") {
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Info",
					"Veuillez renseigner votre numéro Flooz !"
				);
				return false;
			}

			this.moovOkay = false;
			this.moovNOkay = false;
			this.isLoadingFlooz = true;

			let montant = this.montantSave,
				frais = 0,
				feesPaidBy = 0;
			// Si le client doit obligatoirement payer les frais
			if (this.dataPage.frais == "2") {
				montant =
					parseFloat(this.montantSave) +
					(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_moov)) /
					100;
				frais =
					(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_moov)) /
					100;
				feesPaidBy = 2;
			} else if (this.dataPage.frais == "1") {
				// Payer les frais est facultatif
				if (this.checkedFeesMoov == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_moov)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_moov)) /
						100;
					feesPaidBy = 1;
				}
			} else if (this.dataPage.frais == "3") {
				// Payer les frais est partagé
				if (this.checkedFeesMoov == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_moov / 2)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_moov / 2)) /
						100;
					feesPaidBy = 3;
				}
			}

			let dataSend = new FormData();
			// dataSend.append("reference", _reference.get());
			dataSend.append(
				"reference",
				window.localStorage.getItem("_reference_" + this.url_data.slug)
			);
			dataSend.append("numero", this.dataMoov.numero.replace(/\s/g, ""));
			dataSend.append("feesPaidBy", feesPaidBy);

			axios
				.post(
					apiUrl + "api/paiement-marchand/flooz",
					// 'https://carte.abidjan.net/apaym_pro/api/payment-merchant/flooz',
					dataSend
				)
				.then((response) => {
					if (response.data.code == 1) {
						this.isLoadingFlooz = false;
						this.moovOkay = true;
						// this.startTimer();
						this.showVerifMoov = false;
						this.showInProcessMoov = true;

						this.stopInterval = setInterval(() => {
							this.checkingFloozStatus();
						}, 15000); // 15s

						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					); //
					this.isLoadingFlooz = false;
				})
				.catch((error) => {
					this.isLoadingFlooz = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
		},

		// Check if moov payment is made successfully or not
		checkingFloozStatus() {
			let dataSend = new FormData();
			dataSend.append(
				"reference",
				window.localStorage.getItem("_reference_" + this.url_data.slug)
			);

			axios
				.post(
					apiUrl + "api/paiement-marchand/statut-flooz",
					// 'https://carte.abidjan.net/apaym_pro/api/payment-merchant/verify-flooz-status',
					dataSend
				)
				.then((response) => {
					// Transaction was successfully
					if (response.data.statusCode == 0) {
						clearInterval(this.stopInterval);
						// this.dataPage.deleg_pdci == "3" ? this.doYangoRechargement() : console.log("Call back") ;

						// if (
						// 	this.dataPage.urlRedirection !== "" &&
						// 	this.dataPage.urlRedirection !== null
						// ) {
						// 	this.dataPage.redirectWithCode == "1"
						// 		? setTimeout(
						// 			(window.location.href =
						// 				this.dataPage.urlRedirection +
						// 				"?code=" +
						// 				sessionStorage.getItem("k")),
						// 			12000
						// 		)
						// 		: setTimeout(
						// 			(window.location.href = this.dataPage.urlRedirection),
						// 			12000
						// 		);
						// }

						// this.dataPage.urlRedirection !== "" && this.dataPage.urlRedirection !== null ? setTimeout( window.location.href = this.dataPage.urlRedirection, 12000) : console.log("") ;
						this.isLoadingFlooz = false;
						this.hideRecu = false;
						this.modePay = "Moov Money";
						this.modePayment = "Moov Money";
						// this.$refs['openPaymentModal'].hide();
						this.showPaymentSection = false;
						if (this.typeForm == "normal") {
							this.formPayment = true;
						} else {
							this.formQuiclyPayment = true;
						}

						// if (this.slug_boutique != null) {
						// 	setTimeout(() => {
						// 		window.location.href =
						// 			"https://shopapaym.weblogy.net/" + this.slug_boutique;
						// 	}, 2000);
						// 	return;
						// }

						return false;
					} else if (response.data.statusCode == 47) {
						// Transaction is still pending
						this.showVerifMoov = false;
						this.showInProcessMoov = true;
						this.checkMoovAgain();
						return false;
					} else {
						// Transaction was failed
						clearInterval(this.stopInterval);
						this.isLoadingFlooz = false;
						this.moovNOkay = true;
						this.moovOkay = false;
					}
				})
				.catch((error) => {
					this.isLoadingFlooz = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
					clearInterval(this.stopInterval);
				});
		},

		checkMoovAgain() {
			// Vérification du statut de la transaction Flooz
			this.stopInterval = setInterval(() => {
				this.checkingFloozStatus();
			}, 30000); // 60s => 1min
		},

		// TresorPAY Payment
		sendTresorPAY() {
			if (this.dataTresorPAY.numero.replace(/\s/g, "") == "") {
				sweetAlert(
					this.$swal,
					true,
					"top-end",
					"info",
					"Info",
					"Veuillez renseigner votre numéro Trésor Pay !"
				);
				return false;
			}

			this.tresorOkay = false;
			this.tresorNOkay = false;
			this.isLoadingTresorPay = true;

			let montant = this.montantSave,
				frais = 0,
				feesPaidBy = 0;
			// Si le client doit obligatoirement payer les frais
			if (this.dataPage.frais == "2") {
				montant =
					parseFloat(this.montantSave) +
					(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_tresor)) /
					100;
				frais =
					(parseFloat(this.montantSave) * parseFloat(this.dataPage.taux_tresor)) /
					100;
				feesPaidBy = 2;
			} else if (this.dataPage.frais == "1") {
				// Payer les frais est facultatif
				if (this.checkedFeesTresorPAY == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_tresor)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_tresor)) /
						100;
					feesPaidBy = 1;
				}
			} else if (this.dataPage.frais == "3") {
				// Payer les frais est partagé
				if (this.checkedFeesTresorPAY == true) {
					montant =
						parseFloat(this.montantSave) +
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_tresor / 2)) /
						100;
					frais =
						(parseFloat(this.montantSave) *
							parseFloat(this.dataPage.taux_tresor / 2)) /
						100;
					feesPaidBy = 3;
				}
			}

			let dataSend = new FormData();
			dataSend.append(
				"reference",
				window.localStorage.getItem("_reference_" + this.url_data.slug)
			);
			dataSend.append("numero", this.dataTresorPAY.numero.replace(/\s/g, ""));
			dataSend.append("montant", montant);
			dataSend.append("frais", frais);
			dataSend.append("feesPaidBy", feesPaidBy);

			axios
				.post(apiUrl + "api/paiement-marchand/tresorpay/payerAvecTresorPay", dataSend)
				.then((response) => {
					if (response.data.code == 200) {
						this.isLoadingTresorPay = false;
						this.showInProcessTresor = true;
						this.tresorOkay = true;
						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
					this.isLoadingTresorPay = false;
				})
				.catch((error) => {
					this.isLoadingTresorPay = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
		},

		unSlugify(slug) {
			const result = slug.replace(/\-/g, " ");
			return result.replace(/\w\S*/g, function (txt) {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
			});
		},

		// Store the language value
		changeLanguage(event) {
			window.localStorage.setItem("lang", event.target.value);
		},

		selectMenu(payment) {
			this.menuSelected = payment;
			this.showMenu = !this.showMenu;
			this.showHeader = !this.showHeader;
			this.showContent = !this.showContent;

			this.typeForm === "normal"
				? (this.dataFormulaire.montant = this.montantSave)
				: (this.dataFormulaireQuick.montant = this.montantSave);

			this.checkedFeesApaym = false;
			this.checkedFeesOm = false;
			this.checkedFeesCb = false;
			this.checkedFeesWave = false;
			this.checkedFeesMtn = false;
			this.checkedFeesMoov = false;
			this.checkedFeesTresorPAY = false;

			this.scrollToTop();

			switch (payment) {
				case "Visa QR":
					// On paie les frais obligatoirement
					if (this.dataPage.frais == "2" || this.dataPage.frais == "3") {
						// Générons le QR Code prenant en compte le frais obligatoire
						this.feeAmountQr();
					} else {
						// Générons le QR Code
						this.generateQr(
							window.localStorage.getItem("_reference_" + this.url_data.slug),
							"",
							this.typeForm === "normal"
								? this.dataFormulaire.montant
								: this.dataFormulaireQuick.montant
						);
					}

					this.logoSmallDevice = "visa-qr.png";

					break;

				case "VISA":
					// On paie les frais obligatoirement
					if (this.dataPage.frais == "2") {
						this.checkedFeesCb = !this.checkedFeesCb;
						this.tauxMontant(this.dataPage.taux_cb);
					} else {
						console.log("Paiement par " + payment);
					}

					if (this.dataPage.frais == "3") {
						this.checkedFeesCb = !this.checkedFeesCb;
						this.tauxMontant(this.dataPage.taux_cb / 2);
					}

					this.logoSmallDevice = "visa.png";

					break;

				case "MASTERCARD":
					// On paie les frais obligatoirement
					if (this.dataPage.frais == "2") {
						this.checkedFeesCb = !this.checkedFeesCb;
						this.tauxMontant(this.dataPage.taux_cb);
					} else {
						console.log("Paiement par " + payment);
					}

					if (this.dataPage.frais == "3") {
						this.checkedFeesCb = !this.checkedFeesCb;
						this.tauxMontant(this.dataPage.taux_cb / 2);
					}

					this.logoSmallDevice = "mastercard.png";

					break;

				case "Orange Money":
					// On paie les frais obligatoirement
					if (this.dataPage.frais == "2") {
						this.checkedFeesOm = !this.checkedFeesOm;
						this.tauxMontant(this.dataPage.taux_om);
					} else {
						console.log("Paiement par " + payment);
					}

					if (this.dataPage.frais == "3") {
						this.checkedFeesOm = !this.checkedFeesOm;
						this.tauxMontant(this.dataPage.taux_om / 2);
					}

					this.logoSmallDevice = "om.png";

					break;

				case "Wave":
					// On paie les frais obligatoirement
					if (this.dataPage.frais == "2") {
						this.checkedFeesWave = !this.checkedFeesWave;
						this.tauxMontant(this.dataPage.taux_wave);
					} else {
						console.log("Paiement par " + payment);
					}

					if (this.dataPage.frais == "3") {
						this.checkedFeesWave = !this.checkedFeesWave;
						this.tauxMontant(this.dataPage.taux_wave / 2);
					}

					this.logoSmallDevice = "wave.png";

					break;

				case "MTN Money":
					// On paie les frais obligatoirement
					if (this.dataPage.frais == "2") {
						this.checkedFeesMtn = !this.checkedFeesMtn;
						this.tauxMontant(this.dataPage.taux_mtn);
					} else {
						console.log("Paiement par " + payment);
					}

					if (this.dataPage.frais == "3") {
						this.checkedFeesMtn = !this.checkedFeesMtn;
						this.tauxMontant(this.dataPage.taux_mtn / 2);
					}

					this.logoSmallDevice = "momo.png";

					break;

				case "Moov Money":
					// On paie les frais obligatoirement
					if (this.dataPage.frais == "2") {
						this.checkedFeesMoov = !this.checkedFeesMoov;
						this.tauxMontant(this.dataPage.taux_moov);
					} else {
						console.log("Paiement par " + payment);
					}

					if (this.dataPage.frais == "3") {
						this.checkedFeesMoov = !this.checkedFeesMoov;
						this.tauxMontant(this.dataPage.taux_moov / 2);
					}

					this.logoSmallDevice = "flooz.png";

					break;

				case "Tresor PAY":
					// On paie les frais obligatoirement
					if (this.dataPage.frais == "2") {
						this.checkedFeesTresorPAY = !this.checkedFeesTresorPAY;
						this.tauxMontant(this.dataPage.taux_tresor);
					} else {
						console.log("Paiement par " + payment);
					}

					if (this.dataPage.frais == "3") {
						this.checkedFeesTresorPAY = !this.checkedFeesTresorPAY;
						this.tauxMontant(this.dataPage.taux_tresor / 2);
					}

					this.logoSmallDevice = "tresor-money.png";

					break;

				case "APaym USSD":
					// On paie les frais obligatoirement
					if (this.dataPage.frais == "2") {
						this.checkedFeesApaym = !this.checkedFeesApaym;
						this.tauxMontant(this.dataPage.taux_apaym);
					} else {
						console.log("Paiement par " + payment);
					}

					if (this.dataPage.frais == "3") {
						this.checkedFeesApaym = !this.checkedFeesApaym;
						this.tauxMontant(this.dataPage.taux_apaym / 2);
					}

					this.logoSmallDevice = "apaym-ussd.png";

					break;

				case "APaym":
					// On paie les frais obligatoirement
					if (this.dataPage.frais == "2") {
						this.checkedFeesApaym = !this.checkedFeesApaym;
						this.tauxMontant(this.dataPage.taux_apaym);
					} else {
						console.log("Paiement par " + payment);
					}

					if (this.dataPage.frais == "3") {
						this.checkedFeesApaym = !this.checkedFeesApaym;
						this.tauxMontant(this.dataPage.taux_apaym / 2);
					}

					this.logoSmallDevice = "apaym.png";

					break;

				case "ECOBANK Pay":
					// On paie les frais obligatoirement
					if (this.dataPage.frais == "2" || this.dataPage.frais == "3") {
						// Générons le QR Code prenant en compte le frais obligatoire
						this.feeAmountEcobankQr();
					} else {
						// Générons le QR Code
						this.generateEcobankQr(
							window.localStorage.getItem("_reference_" + this.url_data.slug),
							"",
							this.typeForm == "normal"
								? this.dataFormulaire.montant
								: this.dataFormulaireQuick.montant
						);
					}

					this.logoSmallDevice = "ecobank-pay.png";

					break;
			}

			this.cardType =
				payment == "VISA" || payment == "MASTERCARD" ? payment : "";
			this.typeCarte =
				this.cardType.charAt(0).toUpperCase() +
				this.cardType.slice(1).toLowerCase();
		},

		// Calculer le montant total à payer prenant en
		// compte le frais obligatoire à payer
		tauxMontant(taux) {
			let montant = this.montantSave,
				frais = 0;

			if (this.typeForm == "normal") {
				this.dataFormulaire.montant =
					parseFloat(montant) +
					// ( this.pourboire !== 'autre' ? parseFloat(this.pourboire) : ( this.dataFormulaire.amountPourboire ? parseFloat( this.dataFormulaire.amountPourboire ) : 0 ) ) +
					parseFloat(montant * (parseFloat(taux) / 100));

				frais = parseFloat(montant * (parseFloat(taux) / 100));
				// On lance la requête pour modifier le montant ainsi que le frais
				// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

				return false;
			}

			this.dataFormulaireQuick.montant =
				parseFloat(montant) +
				// ( this.pourboire !== 'autre' ? parseFloat(this.pourboire) : ( this.dataFormulaireQuick.amountPourboire ? parseFloat( this.dataFormulaire.amountPourboire ) : 0 ) ) +
				parseFloat(montant * (parseFloat(taux) / 100));

			frais = parseFloat(montant * (parseFloat(taux) / 100));
			// On lance la requête pour modifier le montant ainsi que le frais
			// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
		},

		// Calculer le montant total à payer prenant en
		// compte le frais à payer
		tauxMontantOm() {
			let montant = this.montantSave,
				frais = 0;
			this.checkedFeesOm = !this.checkedFeesOm;

			// Formulaire normal
			if (this.typeForm == "normal") {
				if (this.checkedFeesOm) {
					this.dataFormulaire.montant =
						this.dataPage.frais == "3"
							? parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_om / 2) / 100)
							)
							: parseFloat(montant) +
							parseFloat(montant * (parseFloat(this.dataPage.taux_om) / 100));

					frais =
						this.dataPage.frais == "3"
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_om / 2) / 100)
							)
							: parseFloat(montant * (parseFloat(this.dataPage.taux_om) / 100));
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

					return false;
				}

				this.dataFormulaire.montant = this.montantSave;
				frais = 0;
				// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;
				return false;
			}

			// Formulaire Paie vite
			if (this.checkedFeesOm) {
				this.dataFormulaireQuick.montant =
					this.dataPage.frais == "3"
						? parseFloat(montant) +
						parseFloat(
							montant * (parseFloat(this.dataPage.taux_om / 2) / 100)
						)
						: parseFloat(montant) +
						parseFloat(montant * (parseFloat(this.dataPage.taux_om) / 100));

				frais =
					this.dataPage.frais == "3"
						? parseFloat(
							montant * (parseFloat(this.dataPage.taux_om / 2) / 100)
						)
						: parseFloat(montant * (parseFloat(this.dataPage.taux_om) / 100));
				// On lance la requête pour modifier le montant ainsi que le frais
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;

				return false;
			}

			this.dataFormulaireQuick.montant = this.montantSave;
			frais = 0;
			// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
		},

		// Calculer le montant total à payer prenant en
		// compte le frais à payer
		tauxMontantWave() {
			let montant = this.montantSave,
				frais = 0;
			this.checkedFeesWave = !this.checkedFeesWave;

			// Formulaire normal
			if (this.typeForm == "normal") {
				if (this.checkedFeesWave) {
					this.dataFormulaire.montant =
						this.dataPage.frais == "3"
							? parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_wave / 2) / 100)
							)
							: parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_wave) / 100)
							);

					frais =
						this.dataPage.frais == "3"
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_wave / 2) / 100)
							)
							: parseFloat(
								montant * (parseFloat(this.dataPage.taux_wave) / 100)
							);
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

					return false;
				}

				this.dataFormulaire.montant = this.montantSave;
				frais = 0;
				// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;
				return false;
			}

			// Formulaire Paie vite
			if (this.checkedFeesWave) {
				this.dataFormulaireQuick.montant =
					this.dataPage.frais == "3"
						? parseFloat(montant) +
						parseFloat(
							montant * (parseFloat(this.dataPage.taux_wave / 2) / 100)
						)
						: parseFloat(montant) +
						parseFloat(montant * (parseFloat(this.dataPage.taux_wave) / 100));

				frais =
					this.dataPage.frais == "3"
						? parseFloat(
							montant * (parseFloat(this.dataPage.taux_wave / 2) / 100)
						)
						: parseFloat(montant * (parseFloat(this.dataPage.taux_wave) / 100));
				// On lance la requête pour modifier le montant ainsi que le frais
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;

				return false;
			}

			this.dataFormulaireQuick.montant = this.montantSave;
			frais = 0;
			// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
		},

		// Calculer le montant total à payer prenant en
		// compte le frais à payer
		tauxMontantMtn() {
			let montant = this.montantSave,
				frais = 0;
			this.checkedFeesMtn = !this.checkedFeesMtn;

			// Formulaire normal
			if (this.typeForm == "normal") {
				if (this.checkedFeesMtn) {
					this.dataFormulaire.montant =
						this.dataPage.frais == "3"
							? parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_mtn / 2) / 100)
							)
							: parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_mtn) / 100)
							);

					frais =
						this.dataPage.frais == "3"
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_mtn / 2) / 100)
							)
							: parseFloat(
								montant * (parseFloat(this.dataPage.taux_mtn) / 100)
							);
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

					return false;
				}

				this.dataFormulaire.montant = this.montantSave;
				frais = 0;
				// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;
				return false;
			}

			// Formulaire Paie vite
			if (this.checkedFeesMtn) {
				this.dataFormulaireQuick.montant =
					this.dataPage.frais == "3"
						? parseFloat(montant) +
						parseFloat(
							montant * (parseFloat(this.dataPage.taux_mtn / 2) / 100)
						)
						: parseFloat(montant) +
						parseFloat(montant * (parseFloat(this.dataPage.taux_mtn) / 100));

				frais =
					this.dataPage.frais == "3"
						? parseFloat(
							montant * (parseFloat(this.dataPage.taux_mtn / 2) / 100)
						)
						: parseFloat(montant * (parseFloat(this.dataPage.taux_mtn) / 100));
				// On lance la requête pour modifier le montant ainsi que le frais
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;

				return false;
			}

			this.dataFormulaireQuick.montant = this.montantSave;
			frais = 0;
			// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
		},

		// Calculer le montant total à payer prenant en
		// compte le frais à payer
		tauxMontantMoov() {
			let montant = this.montantSave,
				frais = 0;
			this.checkedFeesMoov = !this.checkedFeesMoov;

			// Formulaire normal
			if (this.typeForm == "normal") {
				if (this.checkedFeesMoov) {
					this.dataFormulaire.montant =
						this.dataPage.frais == "3"
							? parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_moov / 2) / 100)
							)
							: parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_moov) / 100)
							);

					frais =
						this.dataPage.frais == "3"
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_moov / 2) / 100)
							)
							: parseFloat(
								montant * (parseFloat(this.dataPage.taux_moov) / 100)
							);
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

					return false;
				}

				this.dataFormulaire.montant = this.montantSave;
				frais = 0;
				// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;
				return false;
			}

			// Formulaire Paie vite
			if (this.checkedFeesMoov) {
				this.dataFormulaireQuick.montant =
					this.dataPage.frais == "3"
						? parseFloat(montant) +
						parseFloat(
							montant * (parseFloat(this.dataPage.taux_moov / 2) / 100)
						)
						: parseFloat(montant) +
						parseFloat(montant * (parseFloat(this.dataPage.taux_moov) / 100));

				frais =
					this.dataPage.frais == "3"
						? parseFloat(
							montant * (parseFloat(this.dataPage.taux_moov / 2) / 100)
						)
						: parseFloat(montant * (parseFloat(this.dataPage.taux_moov) / 100));
				// On lance la requête pour modifier le montant ainsi que le frais
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;

				return false;
			}

			this.dataFormulaireQuick.montant = this.montantSave;
			frais = 0;
			// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
		},

		// Calculer le montant total à payer prenant en
		// compte le frais à payer
		tauxMontantTresor() {
			let montant = this.montantSave,
				frais = 0;
			this.checkedFeesTresorPAY = !this.checkedFeesTresorPAY;

			// Formulaire normal
			if (this.typeForm == "normal") {
				if (this.checkedFeesTresorPAY) {
					this.dataFormulaire.montant =
						this.dataPage.frais == "3"
							? parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_tresor / 2) / 100)
							)
							: parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_tresor) / 100)
							);

					frais =
						this.dataPage.frais == "3"
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_tresor) / 100)
							)
							: parseFloat(
								montant * (parseFloat(this.dataPage.taux_tresor) / 100)
							);
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

					return false;
				}

				this.dataFormulaire.montant = this.montantSave;
				frais = 0;
				// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;
				return false;
			}

			// Formulaire Paie vite
			if (this.checkedFeesTresorPAY) {
				this.dataFormulaireQuick.montant =
					this.dataPage.frais == "3"
						? parseFloat(montant) +
						parseFloat(
							montant * (parseFloat(this.dataPage.taux_tresor / 2) / 100)
						)
						: parseFloat(montant) +
						parseFloat(montant * (parseFloat(this.dataPage.taux_tresor) / 100));

				frais =
					this.dataPage.frais == "3"
						? parseFloat(
							montant * (parseFloat(this.dataPage.taux_tresor / 2) / 100)
						)
						: parseFloat(montant * (parseFloat(this.dataPage.taux_tresor) / 100));
				// On lance la requête pour modifier le montant ainsi que le frais
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;

				return false;
			}

			this.dataFormulaireQuick.montant = this.montantSave;
			frais = 0;
			// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
		},

		// Calculer le montant total à payer prenant en
		// compte le frais à payer
		tauxMontantCb() {
			let montant = this.montantSave,
				frais = 0;
			this.checkedFeesCb = !this.checkedFeesCb;

			// Formulaire normal
			if (this.typeForm == "normal") {
				if (this.checkedFeesCb) {
					this.dataFormulaire.montant =
						parseFloat(montant) +
						// ( this.pourboire !== 'autre' ? parseFloat(this.pourboire) : ( this.dataFormulaire.amountPourboire ? parseFloat( this.dataFormulaire.amountPourboire ) : 0 ) ) +
						parseFloat(montant * (parseFloat(this.dataPage.taux_cb) / 100));

					frais = parseFloat(
						montant * (parseFloat(this.dataPage.taux_cb) / 100)
					);
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

					return false;
				}

				this.dataFormulaire.montant = this.montantSave;
				frais = 0;
				// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;
				return false;
			}

			// Formulaire Paie vite
			if (this.checkedFeesCb) {
				this.dataFormulaireQuick.montant =
					parseFloat(montant) +
					// ( this.pourboire !== 'autre' ? parseFloat(this.pourboire) : ( this.dataFormulaireQuick.amountPourboire ? parseFloat( this.dataFormulaire.amountPourboire ) : 0 ) ) +
					parseFloat(montant * (parseFloat(this.dataPage.taux_cb) / 100));

				frais = parseFloat(montant * (parseFloat(this.dataPage.taux_cb) / 100));
				// On lance la requête pour modifier le montant ainsi que le frais
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;

				return false;
			}

			this.dataFormulaireQuick.montant = this.montantSave;
			frais = 0;
			// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
		},

		// Calculer le montant total à payer prenant en
		// compte le frais à payer
		tauxMontantApaym() {
			let montant = this.montantSave,
				frais = 0;
			this.checkedFeesApaym = !this.checkedFeesApaym;

			// Formulaire normal
			if (this.typeForm == "normal") {
				if (this.checkedFeesApaym) {
					this.dataFormulaire.montant =
						this.dataPage.frais == "3"
							? parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
							)
							: parseFloat(montant) +
							parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym) / 100)
							);

					frais =
						this.dataPage.frais == "3"
							? parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
							)
							: parseFloat(
								montant * (parseFloat(this.dataPage.taux_apaym) / 100)
							);
					// On lance la requête pour modifier le montant ainsi que le frais
					// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;

					return false;
				}

				this.dataFormulaire.montant = this.montantSave;
				frais = 0;
				// this.modifierMontantFrais(this.dataFormulaire.montant, frais) ;
				return false;
			}

			// Formulaire Paie vite
			if (this.checkedFeesApaym) {
				this.dataFormulaireQuick.montant =
					this.dataPage.frais == "3"
						? parseFloat(montant) +
						parseFloat(
							montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
						)
						: parseFloat(montant) +
						parseFloat(
							montant * (parseFloat(this.dataPage.taux_apaym) / 100)
						);

				frais =
					this.dataPage.frais == "3"
						? parseFloat(
							montant * (parseFloat(this.dataPage.taux_apaym / 2) / 100)
						)
						: parseFloat(
							montant * (parseFloat(this.dataPage.taux_apaym) / 100)
						);
				// On lance la requête pour modifier le montant ainsi que le frais
				// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;

				return false;
			}

			this.dataFormulaireQuick.montant = this.montantSave;
			frais = 0;
			// this.modifierMontantFrais(this.dataFormulaireQuick.montant, frais) ;
		},

		// Modifier le montant et le frais de la transaction
		// Cas de paiement des frais (Obligatoire ou pas)
		modifierMontantFrais(montant, frais) {
			this.isLoading = true;
			let dataSend = new FormData();
			dataSend.append(
				"reference",
				window.localStorage.getItem("_reference_" + this.url_data.slug)
			);
			dataSend.append("frais", frais);
			dataSend.append("montant", montant);

			axios
				.post(apiUrl + "api/paiement-marchand/frais-montant", dataSend)
				.then((response) => {
					this.isLoading = false;
					if (response.data.code == 1) {
						return false;
					}

					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						response.data["message"]
					);
				})
				.catch((error) => {
					this.isLoading = false;
					console.log(error);
					sweetAlert(
						this.$swal,
						true,
						"top-end",
						"error",
						"Erreur",
						"Une erreur est survenue, veuillez réessayer plus tard"
					);
				});
		},

		letSomeonePay() {
			this.scrollToTop();
			this.showPaymentSection = !this.showPaymentSection;
			this.sharingSection = !this.sharingSection;
		},

		validateEmail(email) {
			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
				return true;
			} else {
				return false;
			}
		},

		validateString(string) {
			if (/^[A-Za-z-'çÇéÉèÈêÊëË ]+$/.test(string)) {
				return true;
			} else {
				return false;
			}
		},

		validateSpecialString(string) {
			console.log(string);
			return true;
			/*if (/^[a-zA-Z0-9-&.'#()*º ]+$/.test(string)) {
				return true ;
			} else {
				return false ;
			}*/
		},

	},

	mounted() {

		window.scrollTo(0, 0);

		this.url_data.slug = this.$route.params.slug_marchand;

		// console.log( this.isMobile() );
		this.mobileDevice = this.isMobile();
		this.getInfosPage(this.$route.params.slug_marchand);
		this.getIpAddress();

		// Update screen page with Pusher
		Pusher.logToConsole = false;
		var channel = this.$pusher.subscribe("wby-channel");
		var my = this;
		var sessionStorage1 = sessionStorage;
		var response = function (data) {
			if (
				localStorage.getItem("route") === "defaultPage" ||
				localStorage.getItem("route") === "refPage" ||
				localStorage.getItem("route") === "SouscriptionAssurance"
			) {
				if (
					my.dataFormulaireQuick.montant.toString() !== "" ||
					my.dataFormulaire.montant.toString() !== ""
				) {
					if (data.type == "QR") {
						my.modePayment = "Visa QR";
					}
					else if (data.type == "BICICI") {
						my.modePayment = my.$t("payment_modal.bicici.value");
					} else {
						my.modePayment = data.type;
					}

					if (data.type == "QR") {
						if (
							data.status == "success" &&
							data.reference == window.localStorage.getItem("_reference_" + my.url_data.slug)
						) {
							my.modePay = "Visa QR";
							my.hideRecu = false;
							// my.dataPage.deleg_pdci == "3" ? my.doYangoRechargement() : console.log("Call back") ;
							my.showPaymentSection = false;
							my.sharingSection = false;

							my.formPayment = true;

							// if (my.slug_boutique != null) {
							// 	setTimeout(() => {
							// 		window.location.href =
							// 			"https://shopapaym.weblogy.net/" + my.slug_boutique;
							// 	}, 2000);
							// 	return;
							// }

							// if (
							// 	my.dataPage.urlRedirection !== "" &&
							// 	my.dataPage.urlRedirection !== null
							// ) {
							// 	my.dataPage.redirectWithCode == "1"
							// 		? setTimeout(
							// 			(window.location.href =
							// 				my.dataPage.urlRedirection +
							// 				"?code=" +
							// 				sessionStorage1.getItem("k")),
							// 			12000
							// 		)
							// 		: setTimeout(
							// 			(window.location.href = my.dataPage.urlRedirection),
							// 			12000
							// 		);
							// }

							// my.dataPage.urlRedirection !== "" && my.dataPage.urlRedirection !== null ? setTimeout( window.location.href = my.dataPage.urlRedirection, 12000) : "" ;

							return false;
						}
					}
					else if (data.type == "MTN Money") {
						if (
							data.status == "success" &&
							data.reference == window.localStorage.getItem("_reference_" + my.url_data.slug)
						) {
							my.modePay = "MTN Money";
							my.hideRecu = false;
							// my.dataPage.deleg_pdci == "3" ? my.doYangoRechargement() : console.log("Call back") ;
							// my.$refs['openPaymentModal'].hide();
							my.showPaymentSection = false;
							my.sharingSection = false;

							my.formPayment = true;

							// if (my.slug_boutique != null) {
							// 	setTimeout(() => {
							// 		window.location.href =
							// 			"https://shopapaym.weblogy.net/" + my.slug_boutique;
							// 	}, 2000);
							// 	return;
							// }

							// if (
							// 	my.dataPage.urlRedirection !== "" &&
							// 	my.dataPage.urlRedirection !== null
							// ) {
							// 	my.dataPage.redirectWithCode == "1"
							// 		? setTimeout(
							// 			(window.location.href =
							// 				my.dataPage.urlRedirection +
							// 				"?code=" +
							// 				sessionStorage1.getItem("k")),
							// 			12000
							// 		)
							// 		: setTimeout(
							// 			(window.location.href = my.dataPage.urlRedirection),
							// 			12000
							// 		);
							// }

							// my.dataPage.urlRedirection !== "" && my.dataPage.urlRedirection !== null ? setTimeout( window.location.href = my.dataPage.urlRedirection, 12000) : "" ;
						} else if (
							(data.responseCode == "529" ||
								data.responseCode == "515" ||
								data.responseCode == "9" ||
								data.responseCode == "100") &&
							data.reference == window.localStorage.getItem("_reference_" + my.url_data.slug)
						) {
							my.isLoadingMomo = false;
							my.mtnNOkay = true;
							my.mtnOkay = false;
						}
					}
					else if (data.type == "Orange Money") {
						if (
							data.status == "success" &&
							data.reference == window.localStorage.getItem("_reference_" + my.url_data.slug)
						) {
							my.modePay = "Orange Money";
							my.hideRecu = false;
							// my.dataPage.deleg_pdci == "3" ? my.doYangoRechargement() : console.log("Call back") ;
							// my.$refs['openPaymentModal'].hide();
							my.modalOrange == true
								? my.$refs["openOrangeMoneyModal"].hide()
								: "";
							my.showPaymentSection = false;
							my.sharingSection = false;

							my.formPayment = true;

							// if (my.slug_boutique != null) {
							// 	setTimeout(() => {
							// 		window.location.href =
							// 			"https://shopapaym.weblogy.net/" + my.slug_boutique;
							// 	}, 2000);
							// 	return;
							// }

							// if (
							// 	my.dataPage.urlRedirection !== "" &&
							// 	my.dataPage.urlRedirection !== null
							// ) {
							// 	my.dataPage.redirectWithCode == "1"
							// 		? setTimeout(
							// 			(window.location.href =
							// 				my.dataPage.urlRedirection +
							// 				"?code=" +
							// 				sessionStorage1.getItem("k")),
							// 			12000
							// 		)
							// 		: setTimeout(
							// 			(window.location.href = my.dataPage.urlRedirection),
							// 			12000
							// 		);
							// }

							// my.dataPage.urlRedirection !== "" && my.dataPage.urlRedirection !== null ? setTimeout( window.location.href = my.dataPage.urlRedirection, 12000) : "" ;
						} else if (
							data.responseCode !== "SUCCESS" &&
							data.reference == window.localStorage.getItem("_reference_" + my.url_data.slug)
						) {
							my.isLoadingOrange = false;
							my.orangeNOkay = true;
							my.orangeOkay = false;
							my.modalOrange == true
								? my.$refs["openOrangeMoneyModal"].hide()
								: "";
						}
					}
					else if (data.type == "Tresor PAY") {
						if (
							data.status == "success" &&
							data.reference == window.localStorage.getItem("_reference_" + my.url_data.slug)
						) {
							my.modePay = "Tresor PAY";
							my.hideRecu = false;
							// my.dataPage.deleg_pdci == "3" ? my.doYangoRechargement() : console.log("Call back") ;
							// my.$refs['openPaymentModal'].hide();
							my.showPaymentSection = false;
							my.sharingSection = false;

							my.formPayment = true;

							// if (my.slug_boutique != null) {
							// 	setTimeout(() => {
							// 		window.location.href =
							// 			"https://shopapaym.weblogy.net/" + my.slug_boutique;
							// 	}, 2000);
							// 	return;
							// }

							// if (
							// 	my.dataPage.urlRedirection !== "" &&
							// 	my.dataPage.urlRedirection !== null
							// ) {
							// 	my.dataPage.redirectWithCode == "1"
							// 		? setTimeout(
							// 			(window.location.href =
							// 				my.dataPage.urlRedirection +
							// 				"?code=" +
							// 				sessionStorage1.getItem("k")),
							// 			12000
							// 		)
							// 		: setTimeout(
							// 			(window.location.href = my.dataPage.urlRedirection),
							// 			12000
							// 		);
							// }

							// my.dataPage.urlRedirection !== "" && my.dataPage.urlRedirection !== null ? setTimeout( window.location.href = my.dataPage.urlRedirection, 12000) : "" ;
						} else if (
							data.responseCode !== "SUCCESS" &&
							data.reference == window.localStorage.getItem("_reference_" + my.url_data.slug)
						) {
							my.isLoadingTresorPay = false;
							my.tresorNOkay = true;
							my.tresorOkay = false;

							my.payment_failure_details = data.message;
						}
					} else if (data.type == "WAVE") {
						if (
							data.status == "success" &&
							data.reference == window.localStorage.getItem("_reference_" + my.url_data.slug)
						) {
							my.modePay = "Wave";
							my.hideRecu = false;
							// my.dataPage.deleg_pdci == "3" ? my.doYangoRechargement() : console.log("Call back") ;
							my.$refs["openWaveModal"].hide();
							// my.modalWave == true ? my.$refs['openWaveModal'].hide() : "" ;
							my.showPaymentSection = false;
							my.sharingSection = false;
							my.waveNOkay = false;
							my.waveOkay = false;

							my.formPayment = true;

							// if (my.slug_boutique != null) {
							// 	setTimeout(() => {
							// 		window.location.href =
							// 			"https://shopapaym.weblogy.net/" + my.slug_boutique;
							// 	}, 2000);
							// 	return;
							// }

							// if (
							// 	my.dataPage.urlRedirection !== "" &&
							// 	my.dataPage.urlRedirection !== null
							// ) {
							// 	my.dataPage.redirectWithCode == "1"
							// 		? setTimeout(
							// 			(window.location.href =
							// 				my.dataPage.urlRedirection +
							// 				"?code=" +
							// 				sessionStorage1.getItem("k")),
							// 			12000
							// 		)
							// 		: setTimeout(
							// 			(window.location.href = my.dataPage.urlRedirection),
							// 			12000
							// 		);
							// }

							// my.dataPage.urlRedirection !== "" && my.dataPage.urlRedirection !== null ? setTimeout( window.location.href = my.dataPage.urlRedirection, 12000) : "" ;
						} else if (
							data.responseCode !== "SUCCESS" &&
							data.reference == window.localStorage.getItem("_reference_" + my.url_data.slug)
						) {
							my.isLoadingWave = false;
							my.waveNOkay = true;
							my.waveOkay = false;
							my.$refs["openWaveModal"].hide();
							// my.modalWave == true ? my.$refs['openWaveModal'].hide() : "" ;
						}
					} else if (data.type == "BICICI") {
						if (
							data.status == "success" &&
							data.reference == window.localStorage.getItem("_reference_" + my.url_data.slug)
						) {
							my.modePay = "Cartes Bancaires";
							my.hideRecu = false;
							// my.dataPage.deleg_pdci == "3" ? my.doYangoRechargement() : console.log("Call back") ;
							my.sharingSection = false;
							// my.$refs['openPaymentModal'].hide();
							// my.modalBicici == true ? my.$refs['openBiciciModal'].hide() : "";
							my.showPaymentSection = false;
							my.formPayment = true;

							// if (
							// 	my.dataPage.urlRedirection !== "" &&
							// 	my.dataPage.urlRedirection !== null
							// ) {
							// 	my.dataPage.redirectWithCode == "1"
							// 		? setTimeout(
							// 			(window.location.href =
							// 				my.dataPage.urlRedirection +
							// 				"?code=" +
							// 				sessionStorage1.getItem("k")),
							// 			12000
							// 		)
							// 		: setTimeout(
							// 			(window.location.href = my.dataPage.urlRedirection),
							// 			12000
							// 		);
							// }

							// my.dataPage.urlRedirection !== "" && my.dataPage.urlRedirection !== null ? setTimeout( window.location.href = my.dataPage.urlRedirection, 12000) : "" ;
						} else if (
							data.responseCode !== "BICICI-SUCCESS" &&
							data.reference == window.localStorage.getItem("_reference_" + my.url_data.slug)
						) {
							my.formBicici = false;
							my.isBicici = false;
							my.cardType = "";
							my.biciciNOkay = true;
							// my.modalBicici == true ? my.$refs['openBiciciModal'].hide() : "";
						}
					}
				}
			} else {
				console.log("Payment Page");
			}
		};

		// Écouteur sur les mises à jour des paiements pour checker le statut
		channel.bind("payment-merchant", response);

	},
	updated() {
		this.mobileDevice = this.isMobile();
		// console.log("Is a mobile device ? "+ this.mobileDevice) ;
	},
	watch: {
		timeLeft(newValue) {
			if (newValue === 0) {
				this.onTimesUp();
				this.showVerifMoov = true;
				this.checkingFloozStatus();
			}
		},
	},
}
</script>

<template>
	<div class="apaympro-container">

		<div class="flex flex-column align-items-center justify-content-center"
			style="height: 100vh; width: 100%; overflow: hidden;" v-if="isLoading">
			<!-- Loader -->
			<loading :active.sync="isLoading" :can-cancel="false" :on-cancel="onCancel" :is-full-page="fullPage"
				loader="bars" :opacity="1"></loading>
			<!-- Loader -->
		</div>


		<!-- Skeleton Loader Component -->
		<skeleton-loader :formLoader="formLoader"></skeleton-loader>
		<!-- Skeleton Loader Component -->

		<!-- Form Payment -->
		<div class="apaympro-wrapper animated fadeIn container" v-if="formPayment === true" style="width: 500px">
			<div class="apaympro-page">
				<!-- ****** -->
				<!-- ****** -->
				<!-- Header -->
				<!-- ****** -->
				<!-- ****** -->
				<div class="apaympro-header" style="margin-top: 15px">
					<div class="company-logo" v-if="dataPage.business_logo"
						style="width: 150px !important; justify-content: center">
						<img :src="dataPage.business_logo" alt="Business Logo" />
					</div>
					<div class="page-info" v-if="dataPage.deleg_pdci !== '1'">
						<h3 class="page-name font-16" v-if="dataPage.partner !== '2'">
							{{
								dataPage.type == "ONG" || dataPage.type == "PERSONNALITE" ? $t("text_header.donation.value") :
								$t("text_header.pay.value")
							}}
							<strong style="text-transform: uppercase">
								{{ dataPage.business_name }}
								<img src="../assets/img/badge-check.png" v-b-tooltip.hover
									:title="$t('text_certified_account.value')" v-if="dataPage.verified == '1'" width="15"
									style="margin-top: -3px" />
							</strong>
							<!-- <p class="company-name mb-2" style="font-weight: 500; font-size: 11px">{{ dataPage.ville }}</p> -->
							<p class="company-name mb-2" style="font-weight: 500; font-size: 11px">
								{{ dataPage.ville }} / {{ dataPage.type }}
							</p>
						</h3>

						<!-- Yango -->
						<h3 class="page-name font-16" v-if="dataPage.partner == '2'">
							<strong style="text-transform: uppercase">
								YANGO
								<img src="../assets/img/badge-check.png" v-b-tooltip.hover
									:title="$t('text_certified_account.value')" v-if="dataPage.verified == '1'" width="15"
									style="margin-top: -3px" />
							</strong>
							<p class="company-name mb-2" style="font-weight: 500; font-size: 11px">
								{{ dataPage.ville }}
							</p>
						</h3>
						<!-- Yango -->

						<p class="company-name text-dark" v-if="dataPage.type == 'SP'">
							{{ $t("text_header.by2.value") }}
							<span style="font-weight: 700">{{ dataPage.nom }}</span> <br />
							<span class="font-12" style="text-transform: none">{{ $t("text_header.enterprising.value")
							}}</span>
						</p>
						<p class="company-name" v-if="dataPage.type !== 'SP' && dataPage.type !== 'AUTRE'">
							<span style="font-weight: 500; font-size: 11px">
								{{ dataPage.rcc !== "0" || dataPage.rcc.includes("---") == "-1" ? dataPage.rcc !== "" ?
									dataPage.rcc : "" : "" }}
							</span>
						</p>
					</div>


					<div class="page-description mb-3" v-if="dataPage.merchant === '24111'">
						<span style="cursor: pointer">
							« Gagnez la nouvelle SUZUKI Fronx et d’autres lots, en souscrivant à une assurance 
							Individuelle Accident ou Assurance AUTO chez NSIA ASSURANCES, à partir de 7 000 FCFA ! »
						</span>
						<!--  id="tooltip-description-page"  <b-tooltip target="tooltip-description-page" triggers="hover">
							{{ dataPage.description_page_2 }}
						</b-tooltip> -->
					</div>

					<div class="page-description mb-3" v-else>
						<span id="tooltip-description-page" style="cursor: pointer">
							{{ dataPage.description_page }}
						</span>
						<b-tooltip target="tooltip-description-page" triggers="hover">
							{{ dataPage.description_page_2 }}
						</b-tooltip>
					</div>

					<!-- Social medias -->
					<div class="company-name" style="text-transform: none">
						<!-- <span class="mr-1 ml-1">
							<a class="btn btn-lg btn-link btn-facebook" v-b-modal.openQrCode style="padding: 0">
								<i class="fas fa-solid fa-qrcode text-primary"></i>
							</a>
						</span> -->
						<span v-if="dataPage.facebook" class="mr-1 ml-1">
							<a class="btn btn-lg btn-link btn-facebook" :href="dataPage.facebook" target="_blank"
								style="padding: 0">
								<i class="fab fa-facebook-f"></i>
							</a>
						</span>
						<span v-if="dataPage.instagram" class="mr-1 ml-1">
							<a class="btn btn-lg btn-link btn-instagram" :href="dataPage.instagram" target="_blank"
								style="padding: 0">
								<i class="fab fa-instagram"></i>
							</a>
						</span>
						<span v-if="dataPage.whatsapp" class="mr-1 ml-1">
							<a class="btn btn-lg btn-link btn-whatsapp" :href="dataPage.whatsapp" target="_blank"
								style="padding: 0">
								<i class="fab fa-whatsapp"></i>
							</a>
						</span>
						<span v-if="dataPage.site" class="mr-1 ml-1">
							<a class="btn btn-lg btn-link btn-site" :href="dataPage.site" target="_blank"
								style="padding: 0">
								<i class="fas fa-solid fa-globe"></i>
							</a>
						</span>
					</div>
					<!-- Social medias -->

					<!-- Collected Amount -->
					<div v-if="dataPage.collected_amount !== 0">
						<h3 class="font-16 collected-transactions" style="margin-top: 10px;">
							<div>
								{{ $t("text_header.collected.value") }} :
								<strong> {{ new Intl.NumberFormat("fr-FR", {
									style: "currency", currency: "XOF",
								}).format(dataPage.collected_amount) }} </strong>
							</div>
							<div> {{ $t("text_header.transactions.value") }} : <strong> {{ dataPage.collected_qty }}
								</strong> </div>
						</h3>
					</div>
					<!-- Collected Amount -->

					<div style="display:flex; justify-content: center; gap: 5px;" class="mb-3 mt-2" v-if="hideRecu">
						<div v-for="mode in modesPaiementDispo2" :key="mode.mode" v-show="mode.actif === 'oui'">
							<img :src="require(`../assets/img/menu/${mode.img}`)" alt="Logo Operateur" width="50" />
						</div>
					</div>

				</div>
				<!-- ****** -->
				<!-- ****** -->
				<!-- Header -->
				<!-- ****** -->
				<!-- ****** -->

				<div class="font-weight-bold font-20 text-center mt-3 mb-3">
                    ASSURANCE CIVILE SCOLAIRE
				</div>

				<div class="font-16 text-center px-4 mb-2">
                    <p>
                        Cette assurance couvre l’élève toute l’année scolaire et les vacances c’est à dire 12
                        mois sur 12.
                        <br>
                        La responsabilité civile, la défense et le recours ne sont pas appliquées
                        pendant les vacances scolaires.
                    </p>
				</div>

				<div class="container w-100 text-center flex flex-column align-items-center"
					v-if="show_assurance_form === false">

					<span class="mt-5 mb-3 font-18">
						Type d'assurance
					</span>

					<div class=" flex flex-row w-100 justify-content-around mb-5">

						<div class="formule-bloc"
							style="height: 100px !important; border-radius: 15px !important; padding: 10px !important;"
							@click="assurance_choisie = 'Enseignement General'"
							:style="assurance_choisie === 'Enseignement General' && 'background-color: #f6f6f6; border: 1px solid #005CC8'">
							<div style="display: flex; justify-content: flex-end; align-items: center; width: 100%; padding-top: 15px;">
								<input type="radio" v-model="assurance_choisie" name="General" value="Enseignement General" style="cursor: pointer; width: 18px; height: 18px" />
							</div>
							<i class="fa fa-car font-30 mb-2" style="color: rgb(0 21 78)"></i>
							<p class="font-weight-bold">Enseignement général</p>
						</div>

<!--						<div class="formule-bloc"-->
<!--							style="height: 100px !important; border-radius: 15px !important; padding: 10px !important;"-->
<!--							@click="assurance_choisie = 'Enseignement Technique'"-->
<!--							:style="assurance_choisie === 'Enseignement Technique' && 'background-color: #f6f6f6; border: 1px solid #005CC8'">-->
<!--							<div-->
<!--								style="display: flex; justify-content: flex-end; align-items: center; width: 100%; padding-top: 15px;">-->
<!--								<input type="radio" v-model="assurance_choisie" name="Technique" value="Enseignement Technique" style="cursor: pointer; width: 18px; height: 18px" />-->
<!--							</div>-->
<!--							<i class="fa fa-user font-30 mb-2" style="color: rgb(0 21 78)"></i>-->
<!--							<p class="font-weight-bold">Enseignement technique</p>-->
<!--						</div>-->

						<div class="formule-bloc"
							style="height: 100px !important; border-radius: 15px !important; padding: 10px !important;"
							@click="assurance_choisie = 'Enseignement Technique Professionnel'"
							:style="assurance_choisie === 'Enseignement Technique Professionnel' && 'background-color: #f6f6f6; border: 1px solid #005CC8'">
							<div style="display: flex; justify-content: flex-end; align-items: center; width: 100%; padding-top: 15px;">
								<input type="radio" v-model="assurance_choisie" name="Enseignement Technique Professionnel" value="Enseignement Technique Professionnel" style="cursor: pointer; width: 18px; height: 18px" />
							</div>
							<i class="fa fa-tag font-30 mb-2" style="color: rgb(0 21 78)"></i>
							<p class="font-weight-bold">Formation Professionnelle</p>
						</div>

					</div>

					<div class="w-100 py-3">
						<button type="button" v-if="assurance_choisie === ''" class="btn btn-disabled-custom btn-block" style="cursor: not-allowed;" disabled>
							Souscrire maintenant
						</button>

						<div v-if="assurance_choisie !== '' " class="btn btn-dark btn-block" @click="show_assurance_form = true">
							Souscrire maintenant
						</div>

<!--						<a :href="'https://pay.apaym.com/'+ url_data.slug" v-if="assurance_choisie !== '' && assurance_choisie === 'Autres' " class="btn btn-dark btn-block">-->
<!--							Souscrire maintenant-->
<!--						</a>-->

					</div>

				</div>


				<!-- ******************** -->
				<!-- NSIA ASSURANCES Form -->
				<!-- ** ASSURANCE EG ** -->
				<!-- ******************** -->
				<div class="body" v-if="show_assurance_form === true && assurance_choisie === 'Enseignement General'">

					<button class="btn btn-link btn-close-modal cancel-payment mb-3" @click="choixAssurance" style=" display: flex; align-items: center; height: 40px; width: auto; font-size: initial; background: transparent !important; color: #000 !important;">
						<i class="fas fa-arrow-circle-left mr-1"></i>
						{{ $i18n.locale === "fr" ? "Retour" : "Back" }}
					</button>

					<form name="payment-form" :class="hideRecu === true ? 'payment-form' : 'payment-form hidden'">

						<div class="flex flex-column justify-content-center align-items-center text-center mb-4" style="font-size: 20px; font-weight: bold; color: rgb(30 1 125)">
							{{ assurance_choisie }}
							<span style="display: block; width: 80px; height: 3px; background: rgb(30 1 125); border-radius: 20px;"></span>
						</div>

						<!-- Nom eleve assure -->
						<div class="element-pair">
							<div class="element-pair-container">
								<div class="element-wrap element-wrap-on-left">
									<div class="element-label">
										<span>{{ $t("text_form.nom_eleve_assure.value") }}</span>
									</div>
									<input v-model="dataFormulaire.nomEleve" type="text" class="element-is-input" name="name" required />
								</div>
							</div>
						</div>
						<!-- Nom eleve assure -->

						<!-- Prenom eleve assure -->
						<div class="element-pair">
							<div class="element-pair-container">
								<div class="element-wrap element-wrap-on-left">
									<div class="element-label">
										<span>{{ $t("text_form.prenom_eleve_assure.value") }}</span>
									</div>
									<input v-model="dataFormulaire.prenomEleve" type="text" class="element-is-input"
										name="name" required />
								</div>
							</div>
						</div>
						<!-- Prenom eleve assure -->

						<!-- Contact parent eleve assure -->
						<div class="element-pair">
							<div class="element-pair-container">
								<div class="element-wrap element-wrap-on-left">
									<div class="element-label">
										<span>{{ $t("text_form.conatact_parent_eleve_assure.value") }}</span>
									</div>
									<input v-model="dataFormulaire.contactParentEleve" type="text" class="element-is-input"
										name="name" required />
								</div>
							</div>
						</div>
                        <!-- Contact parent eleve assure -->

                        <!-- Numero matricule assure -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.numero_matricule_eleve_assure.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.matriculeEleve" type="text" class="element-is-input"
                                           name="name" required />
                                </div>
                            </div>
                        </div>
                        <!-- Numero matricule  assure -->

						<!-- Nom ecole assure -->
						<div class="element-pair">
							<div class="element-pair-container">
								<div class="element-wrap element-wrap-on-left">
									<div class="element-label">
										<span>{{ $t("text_form.nom_ecole_eleve_assure.value") }}</span>
									</div>
									<input v-model="dataFormulaire.ecoleEleve" type="text" class="element-is-input"
										name="name" required />
								</div>
							</div>
						</div>
						<!-- Nom ecole assure -->

						<!-- Montant a payer -->
						<div class="element-pair">
							<div class="element-label">
								<span>
									{{ $t("text_form.montant_cotisation_eleve.value") }}
								</span>
							</div>
							<div class="element-pair-container" style="align-items: center">
								<div class="element-wrap element-wrap-on-right">
									<div style="display: flex; flex-direction: row; align-items: center; "
										v-if="dataPage.eticket.length <= 0">
										<div class="element-is-input"
											v-if="dataPage.amount !== '' && parseFloat(dataPage.amount) >= 100"
											style="font-weight: 900; font-size: 22px; pointer-events: none; background-color: #eee; border: 1px solid #ccc;">
											{{ dataPage.amount | formatAmount }}
										</div>

										<input v-if="dataPage.amount == ''" :disabled="dataPage.amount !== '' && parseFloat(dataPage.amount) >= 100 "
                                               v-model="dataFormulaire.montant" type="tel" v-on:keypress="numbersOnly"
											   v-mask="currencyMask" class="element-is-input" name="montant" maxlength="4"  />
									</div>

									<div class="element-is-input" v-else style="font-weight: 900; font-size: 22px; pointer-events: none;
                                            background-color: rgb(238, 238, 238); border: 1px solid rgb(204, 204, 204);">
										{{ dataFormulaire.montant }}
									</div>
								</div>

								<div style="font-weight: 800; font-size: 20px; white-space: nowrap">
									F CFA
								</div>
							</div>
						</div>
						<!-- Montant a payer -->

						<!-- Numero de telephone -->
						<div class="element-pair" style="margin-top: 15px">
							<div class="element-label" >
								<span>{{ $t("text_form.numero_paie_cotisation.value") }}</span>
							</div>
							<div class="element-pair-container">
								<div class="element-wrap element-wrap-on-left">
									<multiselect v-model="indice.i" :options="options" :multiple="false"
										placeholder="Indicatif" tag-placeholder="Indicatif" track-by="code" label="country"
										:searchable="true" :select-label="''" :deselect-label="''" :selected-label="'✓'"
										:allow-empty="false">
										<template slot="singleLabel" slot-scope="{ option }">
                                            {{ option.country_code }}
										</template>
										<span slot="noResult"></span>
									</multiselect>
								</div>
								<div class="element-wrap element-wrap-on-right">
									<input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'" v-model="dataFormulaire.telephone" name="telephone" style="font-weight: 900; font-size: 22px" />
								</div>
							</div>
						</div>
						<!-- Numero de telephone -->
                        


						<div v-if="dataFormulaire.montant !== '' && dataFormulaire.montant.replace(/\s/g, '') < 1000"
							class="formule-info" style="color: red">
							Le montant de la cotisation est inférieur au montant minimum.
							<br> <strong>Montant 1 000 FCFA</strong>
						</div>

						<!-- Button submit form -->
						<div class="element-wrap element-wrap-for-submit">
							<div>
								<!-- ********************************** -->
								<!-- ********************************** -->
								<!-- ********************************** -->
								<button type="button" @click="payer" :class="
									!this.dataFormulaire.nomEleve ||
									!this.dataFormulaire.prenomEleve ||
									!this.dataFormulaire.contactParentEleve ||
									!this.dataFormulaire.matriculeEleve ||
									!this.dataFormulaire.ecoleEleve ||
									this.dataFormulaire.contactParentEleve.length < 10 ||
									!this.dataFormulaire.montant.replace(/\s/g, '') ||
									this.dataFormulaire.montant.replace(/\s/g, '') < 1000 ||
									this.dataFormulaire.montant.replace(/\s/g, '') > 1000
									? 'btn btn-dark btn-disabled-custom btn-block'
									: 'btn btn-dark btn-block'"
                                    :disabled="!this.dataFormulaire.nomEleve || !this.dataFormulaire.prenomEleve || !this.dataFormulaire.contactParentEleve || !this.dataFormulaire.matriculeEleve || !this.dataFormulaire.ecoleEleve ||
									this.dataFormulaire.telephone.length < 11 || !this.dataFormulaire.montant.replace(/\s/g, '') ||
									this.dataFormulaire.montant.replace(/\s/g, '') < 100 ||
									this.dataFormulaire.montant.replace(/\s/g, '') > 1000"
								>
									{{ $t("text_form.button.value") }}
									{{
										dataFormulaire.montant.replace(/\s/g, "") !== ""
										? new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(parseFloat(dataFormulaire.montant.replace(/\s/g, "")))
										: ""
									}}
								</button>
								<!-- ********************************** -->
								<!-- ********************************** -->
								<!-- ********************************** -->
							</div>
						</div>
						<!-- Button submit form -->

<!--						<div v-if="dataFormulaire.montant.replace(/\s/g, '') >= 75000" class="formule-info">-->
<!--							Nombre de point: {{ Math.floor(dataFormulaire.montant.replace(/\s/g, '') / 7000) }}-->
<!--						</div>-->
					</form>

					<!-- ******** -->
					<!-- Bloc success payment -->
					<!-- ******** -->
					<success-page :hideRecu="hideRecu" :logoSmallDevice="logoSmallDevice" :montant="dataFormulaire.montant"
						:nom="dataFormulaire.nom" :prenom="dataFormulaire.prenom" :devise="dataFormulaire.devise"
						:motif="dataFormulaire.motif" :refPayment="refPayment" :nombre_transactions="nombre_transactions">
					</success-page>
					<!-- ******** -->
					<!-- Bloc success payment -->
					<!-- ******** -->
				</div>
				<!-- ******************** -->
				<!-- NSIA ASSURANCES Form -->
				<!-- ** ASSURANCE AUTO ** -->
				<!-- ******************** -->

				<!-- ******************** -->
				<!-- NSIA ASSURANCES Form -->
				<!-- *** ASSURANCE ETFP *** -->
				<!-- ******************** -->
				<div class="body" v-if="show_assurance_form === true && assurance_choisie === 'Enseignement Technique Professionnel' ">

					<button class="btn btn-link btn-close-modal cancel-payment mb-3" @click="choixAssurance"
						style=" display: flex; align-items: center; height: 40px; width: auto; font-size: initial; background: transparent !important; color: #000 !important;">
						<i class="fas fa-arrow-circle-left mr-1"></i>
						{{ $i18n.locale === "fr" ? "Retour" : "Back" }}
					</button>

					<form name="payment-form" :class="hideRecu === true ? 'payment-form' : 'payment-form hidden'">

						<div class="flex flex-column justify-content-center align-items-center text-center mb-4"
							style="font-size: 20px; font-weight: bold; color: rgb(30 1 125)">
							{{ assurance_choisie }}
							<span style="display: block; width: 50px; height: 3px; background: rgb(30 1 125); border-radius: 10px;"></span>
						</div>

                        <!-- Nom eleve assure -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.nom_eleve_assure.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.nomEleve" type="text" class="element-is-input" name="name" required />
                                </div>
                            </div>
                        </div>
                        <!-- Nom eleve assure -->

                        <!-- Prenom eleve assure -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.prenom_eleve_assure.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.prenomEleve" type="text" class="element-is-input"
                                           name="name" required />
                                </div>
                            </div>
                        </div>
                        <!-- Prenom eleve assure -->

                        <!-- Contact parent eleve assure -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.conatact_parent_eleve_assure.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.contactParentEleve" type="text" class="element-is-input"
                                           name="name" required />
                                </div>
                            </div>
                        </div>
                        <!-- Contact parent eleve assure -->

                        <!-- Numero matricule assure -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.numero_matricule_eleve_assure.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.matriculeEleve" type="text" class="element-is-input"
                                           name="name" required />
                                </div>
                            </div>
                        </div>
                        <!-- Numero matricule  assure -->

                        <!-- Nom ecole assure -->
                        <div class="element-pair">
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <div class="element-label">
                                        <span>{{ $t("text_form.nom_ecole_eleve_assure.value") }}</span>
                                    </div>
                                    <input v-model="dataFormulaire.ecoleEleve" type="text" class="element-is-input"
                                           name="name" required />
                                </div>
                            </div>
                        </div>
                        <!-- Nom ecole assure -->

                        <!-- Montant a payer -->
                        <div class="element-pair">
                            <div class="element-label">
								<span>
									{{ $t("text_form.montant_cotisation_eleve.value") }}
								</span>
                            </div>
                            <div class="element-pair-container" style="align-items: center">
                                <div class="element-wrap element-wrap-on-right">
                                    <div style="display: flex; flex-direction: row; align-items: center; "
                                         v-if="dataPage.eticket.length <= 0">
                                        <div class="element-is-input"
                                             v-if="dataPage.amount !== '' && parseFloat(dataPage.amount) >= 100"
                                             style="font-weight: 900; font-size: 22px; pointer-events: none; background-color: #eee; border: 1px solid #ccc;">
                                            {{ dataPage.amount | formatAmount }}
                                        </div>

                                        <input v-if="dataPage.amount == ''" :disabled="dataPage.amount !== '' && parseFloat(dataPage.amount) >= 100 "
                                               v-model="dataFormulaire.montant" type="tel" v-on:keypress="numbersOnly"
                                               v-mask="currencyMask" class="element-is-input" name="montant" maxlength="4"  />
                                    </div>

                                    <div class="element-is-input" v-else style="font-weight: 900; font-size: 22px; pointer-events: none;
                                            background-color: rgb(238, 238, 238); border: 1px solid rgb(204, 204, 204);">
                                        {{ dataFormulaire.montant }}
                                    </div>
                                </div>

                                <div style="font-weight: 800; font-size: 20px; white-space: nowrap">
                                    F CFA
                                </div>
                            </div>
                        </div>
                        <!-- Montant a payer -->

                        <!-- Numero de telephone -->
                        <div class="element-pair" style="margin-top: 15px">
                            <div class="element-label" >
                                <span>{{ $t("text_form.numero_paie_cotisation.value") }}</span>
                            </div>
                            <div class="element-pair-container">
                                <div class="element-wrap element-wrap-on-left">
                                    <multiselect v-model="indice.i" :options="options" :multiple="false"
                                                 placeholder="Indicatif" tag-placeholder="Indicatif" track-by="code" label="country"
                                                 :searchable="true" :select-label="''" :deselect-label="''" :selected-label="'✓'"
                                                 :allow-empty="false">
                                        <template slot="singleLabel" slot-scope="{ option }">
                                            {{ option.country_code }}
                                        </template>
                                        <span slot="noResult"></span>
                                    </multiselect>
                                </div>
                                <div class="element-wrap element-wrap-on-right">
                                    <input class="element-is-input" type="tel" v-mask="'## ## ## ## ##'" v-model="dataFormulaire.telephone" name="telephone" style="font-weight: 900; font-size: 22px" />
                                </div>
                            </div>
                        </div>
                        <!-- Numero de telephone -->


                        <div v-if="dataFormulaire.montant !== '' && dataFormulaire.montant.replace(/\s/g, '') < 2000"
                             class="formule-info" style="color: red">
                            Le montant de la cotisation est inférieur au montant minimum.
                            <br> <strong>Montant 2 000 FCFA</strong>
                        </div>

						<!-- Button submit form -->
						<div class="element-wrap element-wrap-for-submit">
							<div>
								<!-- ********************************** -->
								<!-- ********************************** -->
								<!-- ********************************** -->

								<!-- Bouton normal -->
								<button type="button" @click="payer" v-if="dataFormulaire.garantie._id !== 4" :class="!this.dataFormulaire.nom || !this.dataFormulaire.prenom ||
										!this.dataFormulaire.telephone || !this.dataFormulaire.email || !this.dataFormulaire.montant.replace(/\s/g, '') ||
										this.dataFormulaire.montant.replace(/\s/g, '') < 100 || this.dataFormulaire.montant.replace(/\s/g, '') > 1000000 ||
										!this.dataFormulaire.typeClient || !this.dataFormulaire.isInsured || !this.dataFormulaire.nom_beneficiaire || !this.dataFormulaire.date_naissance ||
										!this.dataFormulaire.numero_mobile_apporteur || !this.dataFormulaire.garantie ? 'btn btn-dark btn-disabled-custom btn-block' : 'btn btn-dark btn-block'
									"
									:disabled="!this.dataFormulaire.nom || !this.dataFormulaire.prenom || !this.dataFormulaire.telephone || !this.dataFormulaire.email ||
										!this.dataFormulaire.montant.replace(/\s/g, '') ||
										this.dataFormulaire.montant.replace(/\s/g, '') < 100 ||
										this.dataFormulaire.montant.replace(/\s/g, '') > 1000000 ||
										!this.dataFormulaire.typeClient || !this.dataFormulaire.isInsured || !this.dataFormulaire.nom_beneficiaire
										|| !this.dataFormulaire.date_naissance || !this.dataFormulaire.numero_mobile_apporteur
									"
								>
									{{ $t("text_form.button.value") }}
									{{
										dataFormulaire.montant.replace(/\s/g, "") !== "" ? new Intl.NumberFormat("fr-FR", {
											style: "currency", currency: "XOF",
										}).format(parseFloat(dataFormulaire.montant.replace(/\s/g, ""))) : ""
									}}
								</button>
								<!-- Bouton normal -->

								<!-- Quand on choisit l'option  -->
								<button type="button" @click="payer" v-else :class="!this.dataFormulaire.nom || !this.dataFormulaire.prenom ||
										!this.dataFormulaire.telephone || !this.dataFormulaire.email || !this.dataFormulaire.montant.replace(/\s/g, '') ||
										this.dataFormulaire.montant.replace(/\s/g, '') < 100 || this.dataFormulaire.montant.replace(/\s/g, '') > 1000000 ||
										!this.dataFormulaire.typeClient || !this.dataFormulaire.isInsured || !this.dataFormulaire.numeroQuittance ||
										!this.dataFormulaire.numero_mobile_apporteur || !this.dataFormulaire.garantie ? 'btn btn-dark btn-disabled-custom btn-block' : 'btn btn-dark btn-block'
									" :disabled="!this.dataFormulaire.nom || !this.dataFormulaire.prenom || !this.dataFormulaire.telephone || !this.dataFormulaire.email ||
                                    !this.dataFormulaire.montant.replace(/\s/g, '') || this.dataFormulaire.montant.replace(/\s/g, '') < 100 ||
                                    this.dataFormulaire.montant.replace(/\s/g, '') > 1000000 ||
                                    !this.dataFormulaire.typeClient || !this.dataFormulaire.isInsured || !this.dataFormulaire.numeroQuittance || !this.dataFormulaire.numero_mobile_apporteur
                                ">
									{{ $t("text_form.button.value") }}
									{{
										dataFormulaire.montant.replace(/\s/g, "") !== "" ? new Intl.NumberFormat("fr-FR", {
											style: "currency", currency: "XOF",
										}).format(parseFloat(dataFormulaire.montant.replace(/\s/g, ""))) : ""
									}}
								</button>
								<!-- Quand on choisit l'option Autre pour l'AI -->

								<!-- ********************************** -->
								<!-- ********************************** -->
								<!-- ********************************** -->
							</div>
						</div>
						<!-- Button submit form -->
					</form>

					<!-- ******** -->
					<!-- Bloc success payment -->
					<!-- ******** -->
					<success-page :hideRecu="hideRecu" :logoSmallDevice="logoSmallDevice" :montant="dataFormulaire.montant"
						:nom="dataFormulaire.nom" :prenom="dataFormulaire.prenom" :devise="dataFormulaire.devise"
						:motif="dataFormulaire.motif" :refPayment="refPayment" :nombre_transactions="nombre_transactions">
					</success-page>
					<!-- ******** -->
					<!-- Bloc success payment -->
					<!-- ******** -->
				</div>
				<!-- ******************** -->
				<!-- NSIA ASSURANCES Form -->
				<!-- *** ASSURANCE IA *** -->
				<!-- ******************** -->
			</div>

		</div>
		<!-- Form Payment -->

		<!-- ****************************************************** -->
		<!-- ****************************************************** -->
		<!-- Section du paiement | Affichage des moyens de paiement -->
		<!-- ****************************************************** -->
		<!-- ****************************************************** -->
		<div class="apaympro-wrapper animated fadeIn container mb-5" v-if="showPaymentSection" style="width: 500px">
			<div class="apaympro-page mb-4">
				<!-- Header -->
				<div class="apaympro-header">
					<div class="mb-1 mt-4" style="display: flex; flex-direction: column; width: 100%">
						<div class="text-modal">
							<!-- <img v-if="!dataPage.business_logo" src="../assets/apaym-pay.png" width="140" alt="Logo APAYM Pay" /> -->
							<div class="company-logo mb-3" style="width: 150px" v-if="dataPage.business_logo">
								<img :src="dataPage.business_logo" alt="Business Logo" width="140" />
							</div>
						</div>

						<!-- Montant -->
						<div v-if="showMenu" style="display: contents">
							<div style="text-align: center; display: flex; flex-direction: column;">
								<span style="font-size: 25px; font-weight: 600; letter-spacing: 1.2px;">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(parseFloat(montantSave))
									}}
								</span>
							</div>

						</div>
						<!-- Montant -->

						<!-- <div>
							{{ assurance_choisie }}
						</div> -->

						<!-- Type Marchand : Personnalité, SA, SARL, etc. -->
						<div class="text-modal" style="padding-top: 5px">
							{{
								dataPage.type == "ONG" || dataPage.type == "PERSONNALITE"
								? $t("text_header.donation.value")
								: $t("text_header.pay.value")
							}} votre
							<strong style="text-transform: uppercase">
								<!-- {{ dataPage.business_name }}
								<img src="../assets/img/badge-check.png" v-b-tooltip.hover
									:title="$t('text_certified_account.value')" v-if="dataPage.verified == '1'" width="15"
									style="margin-top: -3px" /> -->
									{{ assurance_choisie }}
							</strong>
							<p>
								{{ $t("text_header.by.value") }}
							</p>
						</div>
						<!-- Type Marchand : Personnalité, SA, SARL, etc. -->
					</div>
				</div>
				<!-- Header -->

				<!-- Body -->
				<div class="body">
					<button class="btn btn-link btn-close-modal cancel-payment" @click="hideSectionPayment" style="display: flex; align-items: center;
			              height: 40px; width: auto; font-size: initial;
		            ">
						<i class="fas fa-times mr-1"></i>
						{{ $i18n.locale == "fr" ? "Annuler" : "Cancel" }}
					</button>

					<form class="payment-form" :style="dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3' || dataPage.pourboire == '1'
						? 'padding: 25px 15px 0px; padding-bottom: 0;' : ''
						">
						<div>
							<!-- **************************** -->
							<!-- **************************** -->
							<!-- Menu -->
							<!-- **************************** -->
							<!-- **************************** -->
							<div class="container" style="padding: 10px; padding-top: 0 !important; padding-bottom: 0 !important;">
								<div class="row mt-3" v-if="showMenu">
									<div class="row">
										<div class="col-4 mb-4" v-for="mode in modesPaiementDispo" :key="mode.mode"
											v-show="mode.actif == 'oui'">
											<a @click="selectMenu(mode.libelle)" href="javascript:void(0);"
												v-if="mode.actif == 'oui'">
												<clazy-load :src="require(`../assets/img/menu/${mode.img}`)">
													<img :src="require(`../assets/img/menu/${mode.img}`)"
														alt="Logo Operateur" />
													<div class="preloader" slot="placeholder">
														<i class="fa fa-spin fa-spinner font-18 text-primary"></i>
													</div>
												</clazy-load>
											</a>
										</div>
									</div>
								</div>
							</div>
							<!-- **************************** -->
							<!-- **************************** -->
							<!-- Menu -->
							<!-- **************************** -->
							<!-- **************************** -->

							<!-- **************************** -->
							<!-- **************************** -->
							<!-- Change Menu -->
							<!-- **************************** -->
							<!-- **************************** -->
							<div style="display: flex; flex-direction: row; z-index: 1; align-items: center; width: 100%;
				                  padding: 15px; height: 60px; justify-content: center; background: #f4f4f4; border-radius: 10px;
                                " v-if="showContent">
								<div style="display: flex; flex-direction: row; z-index: 1; align-items: center; width: 100%;
					                    justify-content: space-between; ">
									<span class="font-16" style="font-weight: 800; text-transform: uppercase">
										{{
											menuSelected == "VISA" || menuSelected == "MASTERCARD"
											? typeCarte
											: menuSelected
										}}
									</span>
									<a href="javascript:void(0);" style="color: #0e2abd; font-weight: 600; font-size: 18px"
										@click="showHeader = showContent = false; showMenu = true; scrollToTop();">
										<i class="fas fa-exchange-alt" style="transform: rotate(-76deg)"></i>
										{{ $t("payment_modal.change_menu.value") }}
									</a>
								</div>
							</div>
							<!-- **************************** -->
							<!-- **************************** -->
							<!-- Change Menu -->
							<!-- **************************** -->
							<!-- **************************** -->

							<!-- **************************** -->
							<!-- **************************** -->
							<!-- Header -->
							<!-- **************************** -->
							<!-- **************************** -->
							<div style="display: flex; flex-direction: row; z-index: 1; align-items: center;
				                  width: 100%; padding-top: 10px; padding-bottom: 10px; justify-content: space-between;
				                  margin-bottom: 12px;" v-if="showHeader">
								<!-- rgb(255, 159, 0) -->
								<img :src="require(`../assets/img/menu/${logoSmallDevice}`)" width="75"
									alt="Logo moyen de paiement" />

								<div
									style="display: flex; flex-direction: column; top: 5px; z-index: 1; align-items: flex-end; padding: 5px;">
									<div style="text-align: end; display: flex; flex-direction: column;">
										<span v-if="dataFormulaire.nomEntreprise">
											{{
												dataFormulaire.nomEntreprise
											}}
										</span>
										<span v-if="dataFormulaire.prenom && dataFormulaire.nom">
											{{ dataFormulaire.prenom }} {{ dataFormulaire.nom }}
										</span>
										<span v-if="dataFormulaire.email">
											{{
												dataFormulaire.email
											}}
										</span>
										<span v-if="dataFormulaire.telephone">
											{{ indice.i.code }} {{ dataFormulaire.telephone }}
											<span v-if="nombre_transactions !== ''">
												<img :src="require(`../assets/img/badges/${imgStatus}`)" width="20"
													style="position: relative; top: -3px" />
												{{ statusClient }}
											</span>
										</span>
										<span style="font-size: 25px; font-weight: 600; letter-spacing: 1.2px;">
											{{
												new Intl.NumberFormat("fr-FR", {
													style: "currency",
													currency: "XOF",
												}).format(parseFloat(montantSave))
											}}
										</span>
										<span v-if="assurance_choisie">
											{{
												assurance_choisie.trim()
											}}
										</span>
									</div>

								</div>
							</div>
							<!-- **************************** -->
							<!-- **************************** -->
							<!-- Header -->
							<!-- **************************** -->
							<!-- **************************** -->

							<!-- **************************** -->
							<!-- **************************** -->
							<!-- ********** Content ********* -->
							<!-- **************************** -->
							<!-- **************************** -->
							<div v-if="showContent">

								<!-- Visa QR -->
								<div class="flex flex-column" v-if="menuSelected === 'Visa QR'">

									<div class="flex flex-column align-items-center justify-content-center"
										v-if="isLoadingQr">
										<!-- Loader -->
										<loading :active.sync="isLoadingQr" :can-cancel="true" :on-cancel="onCancelQr"
											:is-full-page="fullPageQr" loader="bars" :opacity="1"></loading>
										<!-- Loader -->
									</div>

									<!-- Content -->
									<div class="checkout__stage">
										<section>
											<div class="visa-qr-container">
												<div>
													<div class="visa-qr-container__header">
														<p class="instruction mt-3">
															{{ $t("payment_modal.visa_qr.text.value") }}
														</p>
													</div>
													<a download="visa-qr-code.png" class="btn btn-light mt-2 mb-2"
														:href="qrCodeUri" style="
							                              height: 35px;
							                              line-height: 1.4;
							                              background: #f4f4f4;
							                              border: #f4f4f4;
						                            ">
														{{ $t("payment_modal.download_visa_qr.value") }}
													</a>

													<!-- Recapitulatif Montant à payer, pourboire, frais -->
													<div class="mt-1">
														<!-- Fees -->
														<div class="container d-flex justify-content-between mt-2 mb-3"
															v-if="dataPage.frais == '1' ||
																dataPage.frais == '2' ||
																dataPage.frais == '3'
																">
															<div>
																<h6 v-if="dataPage.frais == '1'">
																	{{ $t("text_fees_payment.value") }}
																</h6>
																<h6 v-if="dataPage.frais == '2' ||
																	dataPage.frais == '3'
																	">
																	{{ $t("text_fees_forced_payment.value") }}
																</h6>
															</div>
															<label class="ui-switch switch-solid"
																v-if="dataPage.frais == '1'">
																<input type="checkbox" :checked="checkedFeesApaym"
																	@click="feeAmountQr" />
																<span style="left: 20px"></span>
															</label>
															<div class="ui-switch switch-solid" v-if="dataPage.frais == '2' || dataPage.frais == '3'
																">
																<input type="checkbox" :checked="checkedFeesApaym"
																	@click="feeAmountQr" />
																<span style="left: 20px"></span>
															</div>
														</div>
														<div class="container d-flex justify-content-between" v-if="(dataPage.frais == '1' ||
															dataPage.frais == '2' ||
															dataPage.frais == '3') &&
															checkedFeesApaym
															">
															<div>
																<span class="font-12">{{
																	$t("text_fees.value")
																}}</span>
															</div>
															<div>
																<span class="font-12">
																	{{
																		dataPage.frais == "3"
																		? new Intl.NumberFormat("fr-FR", {
																			style: "currency",
																			currency: "XOF",
																		}).format(
																			montantSave
																				? parseFloat(montantSave) *
																				(parseFloat(
																					dataPage.taux_apaym / 2
																				) /
																					100)
																				: 0
																		)
																		: new Intl.NumberFormat("fr-FR", {
																			style: "currency",
																			currency: "XOF",
																		}).format(
																			montantSave
																				? parseFloat(montantSave) *
																				(parseFloat(
																					dataPage.taux_apaym
																				) /
																					100)
																				: 0
																		)
																	}}
																</span>
															</div>
														</div>
														<!-- Fees -->

														<!-- Pourboire -->
														<!-- Type formulaire normal -->
														<div>
															<div class="container d-flex justify-content-between" v-if="dataPage.pourboire == '1' &&
																	pourboire &&
																	(pourboire == 'autre'
																		? parseFloat(
																			dataFormulaire.amountPourboire
																		) > 0
																		: parseFloat(pourboire) > 0)
																	">
																<div>
																	<span class="font-12">
																		{{ $t("text_pourboire.value") }}
																	</span>
																</div>
																<div>
																	<span class="font-12">
																		{{
																			new Intl.NumberFormat("fr-FR", {
																				style: "currency",
																				currency: "XOF",
																			}).format(
																				pourboire == "autre"
																					? dataFormulaire.amountPourboire
																						? parseFloat(
																							dataFormulaire.amountPourboire
																						)
																						: 0
																					: parseFloat(pourboire)
																			)
																		}}
																	</span>
																</div>
															</div>
														</div>
														<!-- Pourboire -->

														<!-- Montant à payer -->
														<div class="container d-flex justify-content-between" v-if="montantAPayer &&
																(dataPage.frais == '1' ||
																	dataPage.frais == '2' ||
																	dataPage.frais == '3' ||
																	dataPage.pourboire == '1')
																">
															<div>
																<span class="font-12">{{
																	$t("text_amount_to_pay.value")
																}}</span>
															</div>

															<div>
																<span class="font-12">
																	{{
																		new Intl.NumberFormat("fr-FR", {
																			style: "currency",
																			currency: "XOF",
																		}).format(parseFloat(montantAPayer))
																	}}
																</span>
															</div>
														</div>

														<!-- Montant total -->
														<div class="container d-flex justify-content-between mb-3" :style="dataPage.frais == '1' ||
															dataPage.frais == '2' ||
															dataPage.frais == '3' ||
															dataPage.pourboire == '1'
															? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
															: ''
															"
															v-if="(dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3' || dataPage.pourboire == '1')">
															<div>
																<span style="font-weight: 800; font-size: 22px">TOTAL</span>
															</div>
															<div>
																<span style="font-weight: 800; font-size: 22px"
																	v-if="montantSave">
																	{{
																		new Intl.NumberFormat("fr-FR", {
																			style: "currency",
																			currency: "XOF",
																		}).format(
																			parseFloat(montantSave) +
																			parseFloat(dataPage.frais === "3"
																				? parseFloat(montantSave) ? parseFloat(montantSave) *
																					(parseFloat(dataPage.taux_apaym / 2) / 100)
																					: 0
																				: parseFloat(montantSave) ? parseFloat(montantSave) *
																					(parseFloat(dataPage.taux_apaym) / 100)
																					: 0
																			)
																		)
																	}}
																</span>
																<span style="font-weight: 800; font-size: 22px"
																	v-if="!montantSave">
																	{{
																		new Intl.NumberFormat("fr-FR", {
																			style: "currency",
																			currency: "XOF",
																		}).format(0)
																	}}
																</span>
															</div>
														</div>
														<!-- Montant total -->
													</div>
													<!-- Recapitulatif Montant à payer, pourboire, frais -->

													<div class="qr__wrapper">
														<div class="qr__code">
															<img v-if="qrCodeUri" name="qrCodeImage" alt="APAYM Pay QR Code"
																:src="qrCodeUri" id="qrCodeImage" style="width: 68%" />
														</div>
													</div>
													<div class="qr__wrapper mt-2 mb-4">
														<div class="qr__code">
															<i class="fas fa-spinner fa-spin" style="font-size: 20px"
																v-if="qrCodeUri"></i>
														</div>
														<div class="mt-2">
															{{ $t("text_visa_qr_waiting.value") }}
														</div>
													</div>
												</div>
											</div>
										</section>
									</div>
									<!-- Content -->
								</div>
								<!-- Visa QR -->

								<!-- Visa / Mastercard -->
								<div v-if="menuSelected === 'VISA' || menuSelected === 'MASTERCARD'">
									<div class="">
										<section>
											<div class="visa-qr-container">
												<div class="flex flex-column">
													<!-- Loader -->
													<div class="flex flex-column align-items-center justify-content-center mt-3"
														v-if="isLoadingBicici">
														<loading :active.sync="isLoadingBicici" :can-cancel="false"
															:on-cancel="onCancelBicici" :is-full-page="fullPageBicici"
															loader="bars" :opacity="1"></loading>
													</div>
													<!-- Loader -->

													<div class="visa-qr-container__header">
														<p class="instruction mt-3" v-if="!isBicici &&
															!dataPage.subMerchant &&
															dataPage.merchant !== '21449'
															">
															<!-- {{ $t('payment_modal.visa.text_orabank.value') }} -->
															{{ $t("payment_modal.visa.text.value") }}
														</p>

														<div class="displayerror__details mt-3" v-if="biciciNOkay">
															<div>
																<p class="paiement-failed">
																	{{
																		$t(
																			"payment_modal.bicici_payment_failure_title.value"
																		)
																	}}
																</p>
																<div class="error-information">
																	{{
																		$t(
																			"payment_modal.bicici_payment_failure_details.value"
																		)
																	}}
																</div>
															</div>
														</div>
													</div>

													<!-- Orabank -->
													<div class="qr__wrapper container" v-if="dataPage.subMerchant ||
															dataPage.merchant == '21449' ||
															dataPage.merchant == '24545'
															">
														<!-- ************************** -->
														<!-- Formulaire Visa/Mastercard -->
														<!-- ************************** -->

														<!-- Numéro de la carte -->
														<div class="element-pair text-left">
															<div class="element-label">
																<span>
																	{{
																		$t("payment_modal.visa.card_number.value")
																	}}
																</span>
															</div>
															<div class="element-pair-container">
																<div class="element-wrap element-wrap-on-left">
																	<div class="input-group-icon input-group-icon-right">
																		<span class="input-icon input-icon-right">
																			<i :class="menuSelected == 'VISA'
																						? 'fab fa-cc-visa font-20'
																						: 'fab fa-cc-mastercard font-20'
																					"></i>
																		</span>
																		<!-- <input v-model="dataVisaCard.pan"
                                                                            class="element-is-input" type="tel"
                                                                            name="cardNumber" @keypress="numbersOnly"
                                                                            v-mask="'####  ####  ####  ####'"
                                                                            placeholder="xxxx  xxxx  xxxx  xxxx"
                                                                            style="font-weight: 800; font-size: 18px"
                                                                            @input="checkPan($event)" /> -->
																		<input v-model="dataVisaCard.pan"
																			class="element-is-input" type="tel"
																			name="cardNumber" @keypress="numbersOnly"
																			v-mask="'####  ####  ####  ####'"
																			placeholder="xxxx  xxxx  xxxx  xxxx"
																			style="font-weight: 800; font-size: 18px" />
																	</div>
																</div>
															</div>
														</div>
														<!-- Numéro de la carte -->

														<!-- Nom de la banque -->
														<div class="element-pair text-left" v-if="formRechargement.issuer_name &&
															dataVisaCard.pan &&
															dataVisaCard.pan.replace(/\s/g, '').length == 16
															">
															<div class="element-pair-container">
																<div class="element-wrap">
																	<div class="element-label font-14"
																		style="line-height: 1.6rem">
																		<strong>{{
																			formRechargement.issuer_name
																		}}</strong>
																		<br />
																		Carte
																		{{ formRechargement.card_type_description }}
																	</div>
																</div>
															</div>
														</div>
														<!-- Nom de la banque -->

														<!-- Date d'expiration et CVV -->
														<div style="
                                display: flex;
                                justify-content: space-between;
                                margin-top: 15px;
                              ">
															<!-- Date d'expiration -->
															<div class="element-pair text-left" style="width: 75%">
																<div class="element-label">
																	<span>
																		{{
																			$t(
																				"payment_modal.visa.expiration_date.value"
																			)
																		}}
																	</span>
																</div>
																<div class="element-pair-container">
																	<!-- Mois -->
																	<div
																		class="element-wrap element-wrap-for-select element-wrap-on-left">
																		<select v-model="dataVisaCard.expirationMonth"
																			class="element-is-select"
																			style="font-weight: 500" name="month">
																			<option value="" selected disabled>
																				{{
																					$t(
																						"payment_modal.visa.expiration_month.value"
																					)
																				}}
																			</option>
																			<option v-for="month in expirationMonth"
																				:key="month.id" :value="month.month">
																				{{ month.month }} -
																				{{
																					$i18n.locale == "fr"
																					? month.libelle
																					: month.libelleEn
																				}}
																			</option>
																		</select>
																	</div>
																	<!-- Mois -->

																	<!-- Année -->
																	<div
																		class="element-wrap element-wrap-for-select element-wrap-on-right">
																		<select v-model="dataVisaCard.expirationYear"
																			class="element-is-select"
																			style="font-weight: 500" name="year">
																			<option value="" selected disabled>
																				{{
																					$t(
																						"payment_modal.visa.expiration_year.value"
																					)
																				}}
																			</option>
																			<option :value="$index + minCardYear"
																				v-for="(n, $index) in 19" :key="n">
																				{{ $index + minCardYear }}
																			</option>
																		</select>
																	</div>
																	<!-- Année -->
																</div>
															</div>
															<!-- Date d'expiration -->

															<!-- CVV -->
															<div class="element-pair text-left" style="width: 20%">
																<div class="element-label">
																	<span> CVV </span>
																</div>
																<div class="element-pair-container">
																	<div class="element-wrap element-wrap-on-right">
																		<input v-model="dataVisaCard.cvv"
																			class="element-is-input" type="tel" name="cvv"
																			v-mask="'###'" @keypress="numbersOnly"
																			style="font-weight: 500" />
																	</div>
																</div>
															</div>
															<!-- cvv -->
														</div>
														<!-- Date d'expiration et CVV -->

														<!-- Nom du porteur de la carte -->
														<div class="element-pair text-left mt-3">
															<div class="element-label">
																<span>
																	{{
																		$t(
																			"payment_modal.visa.card_holder_name.value"
																		)
																	}}
																</span>
															</div>
															<div class="element-pair-container">
																<div class="element-wrap element-wrap-on-left">
																	<input v-model="dataVisaCard.cardHolderName"
																		class="element-is-input" type="text"
																		name="cardHolderName" style="font-weight: 500" />
																</div>
															</div>
														</div>
														<!-- Nom du porteur de la carte -->

														<!-- ************************** -->
														<!-- Formulaire Visa/Mastercard -->
														<!-- ************************** -->

														<div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
															dataPage.frais == '2' ||
															dataPage.frais == '3' ||
															dataPage.pourboire == '1'
															? 'background: #f5f5f5; margin: 40px -30px 0px;'
															: ''
															">
															<img src="../assets/img/trait-recap.png"
																alt="Illustration zigzag recap"
																style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
																	dataPage.frais == '2' ||
																	dataPage.frais == '3' ||
																	dataPage.pourboire == '1'
																	" />

															<!-- Recapitulatif Montant à payer, pourboire, frais -->
															<div class="mt-1">
																<!-- Fees -->
																<div class="container d-flex justify-content-between mt-2 mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3'
		">
																	<div>
																		<h6 v-if="dataPage.frais == '1'">
																			{{ $t("text_fees_payment.value") }}
																		</h6>
																		<h6 v-if="dataPage.frais == '2' ||
																			dataPage.frais == '3'
																			">
																			{{ $t("text_fees_forced_payment.value") }}
																		</h6>
																	</div>
																	<label class="ui-switch switch-solid"
																		v-if="dataPage.frais == '1'">
																		<input type="checkbox" :checked="checkedFeesCb"
																			@click="tauxMontantCb" />
																		<span style="left: 20px"></span>
																	</label>
																	<div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
																		dataPage.frais == '3'
																		">
																		<input type="checkbox" :checked="checkedFeesCb"
																			@click="tauxMontantCb" />
																		<span style="left: 20px"></span>
																	</div>
																</div>
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="(dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3') &&
		checkedFeesCb
		">
																	<div>
																		<span class="font-12">{{
																			$t("text_fees.value")
																		}}</span>
																	</div>
																	<div>
																		<span class="font-12">
																			{{
																				dataPage.frais == "3"
																				? new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_cb / 2
																						) /
																							100)
																						: 0
																				)
																				: new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_cb
																						) /
																							100)
																						: 0
																				)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Fees -->

																<!-- Pourboire -->
																<!-- Type formulaire normal -->
																<div>
																	<div class="container d-flex justify-content-between"
																		:style="dataPage.frais == '1' ||
																			dataPage.frais == '2' ||
																			dataPage.frais == '3' ||
																			dataPage.pourboire == '1'
																			? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																			: ''
																			" v-if="dataPage.pourboire == '1' &&
			pourboire &&
			(pourboire == 'autre'
				? parseFloat(
					dataFormulaire.amountPourboire
				) > 0
				: parseFloat(pourboire) > 0)
			">
																		<div>
																			<span class="font-12">
																				{{ $t("text_pourboire.value") }}
																			</span>
																		</div>
																		<div>
																			<span class="font-12">
																				{{
																					new Intl.NumberFormat("fr-FR", {
																						style: "currency",
																						currency: "XOF",
																					}).format(
																						pourboire == "autre"
																							? dataFormulaire.amountPourboire
																								? parseFloat(
																									dataFormulaire.amountPourboire
																								)
																								: 0
																							: parseFloat(pourboire)
																					)
																				}}
																			</span>
																		</div>
																	</div>
																</div>
																<!-- Pourboire -->

																<!-- Montant à payer -->
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="montantAPayer &&
			(dataPage.frais == '1' ||
				dataPage.frais == '2' ||
				dataPage.frais == '3' ||
				dataPage.pourboire == '1')
			">
																	<div>
																		<span class="font-12">{{
																			$t("text_amount_to_pay.value")
																		}}</span>
																	</div>

																	<div>
																		<span class="font-12">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(parseFloat(montantAPayer))
																			}}
																		</span>
																	</div>
																</div>

																<!-- Montant total -->
																<div class="container d-flex justify-content-between mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		"
																	v-if="(dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3' || dataPage.pourboire == '1')">
																	<div>
																		<span
																			style="font-weight: 800; font-size: 22px">TOTAL</span>
																	</div>
																	<div>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					parseFloat(montantSave) +
																					parseFloat(dataPage.frais === "3"
																						? parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_cb / 2) / 100)
																							: 0
																						: parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_cb) / 100)
																							: 0
																					)
																				)
																			}}
																		</span>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="!montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(0)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Montant total -->
															</div>
															<!-- Recapitulatif Montant à payer, pourboire, frais -->

															<div :style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
																		: ''
																	">
																<button type="button" :class="!cardType
																	? 'btn btn-dark btn-disabled-custom btn-block'
																	: 'btn btn-dark btn-block'
																	" @click="sendCardPayment" :disabled="!cardType">
																	{{ $t("payment_modal.visa.button.value") }}
																	{{ typeCarte }}
																</button>
															</div>
														</div>
													</div>

													<!-- UBA -->
													<div class="qr__wrapper container" v-else>
														<div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
															dataPage.frais == '2' ||
															dataPage.frais == '3' ||
															dataPage.pourboire == '1'
															? 'background: #f5f5f5; margin: 40px -30px 0px;'
															: ''
															">
															<img src="../assets/img/trait-recap.png"
																alt="Illustration zigzag recap"
																style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
																	dataPage.frais == '2' ||
																	dataPage.frais == '3' ||
																	dataPage.pourboire == '1'
																	" />

															<!-- Recapitulatif Montant à payer, pourboire, frais -->
															<div class="mt-1">
																<!-- Fees -->
																<div class="container d-flex justify-content-between mt-2 mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3'
		">
																	<div>
																		<h6 v-if="dataPage.frais == '1'">
																			{{ $t("text_fees_payment.value") }}
																		</h6>
																		<h6 v-if="dataPage.frais == '2' ||
																			dataPage.frais == '3'
																			">
																			{{ $t("text_fees_forced_payment.value") }}
																		</h6>
																	</div>
																	<label class="ui-switch switch-solid"
																		v-if="dataPage.frais == '1'">
																		<input type="checkbox" :checked="checkedFeesCb"
																			@click="tauxMontantCb" />
																		<span style="left: 20px"></span>
																	</label>
																	<div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
																		dataPage.frais == '3'
																		">
																		<input type="checkbox" :checked="checkedFeesCb"
																			@click="tauxMontantCb" />
																		<span style="left: 20px"></span>
																	</div>
																</div>
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="(dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3') &&
		checkedFeesCb
		">
																	<div>
																		<span class="font-12">{{
																			$t("text_fees.value")
																		}}</span>
																	</div>
																	<div>
																		<span class="font-12">
																			{{
																				dataPage.frais == "3"
																				? new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_cb / 2
																						) /
																							100)
																						: 0
																				)
																				: new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_cb
																						) /
																							100)
																						: 0
																				)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Fees -->

																<!-- Pourboire -->
																<!-- Type formulaire normal -->
																<div>
																	<div class="container d-flex justify-content-between"
																		:style="dataPage.frais == '1' ||
																			dataPage.frais == '2' ||
																			dataPage.frais == '3' ||
																			dataPage.pourboire == '1'
																			? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																			: ''
																			" v-if="dataPage.pourboire == '1' &&
			pourboire &&
			(pourboire == 'autre'
				? parseFloat(
					dataFormulaire.amountPourboire
				) > 0
				: parseFloat(pourboire) > 0)
			">
																		<div>
																			<span class="font-12">
																				{{ $t("text_pourboire.value") }}
																			</span>
																		</div>
																		<div>
																			<span class="font-12">
																				{{
																					new Intl.NumberFormat("fr-FR", {
																						style: "currency",
																						currency: "XOF",
																					}).format(
																						pourboire == "autre"
																							? dataFormulaire.amountPourboire
																								? parseFloat(
																									dataFormulaire.amountPourboire
																								)
																								: 0
																							: parseFloat(pourboire)
																					)
																				}}
																			</span>
																		</div>
																	</div>
																</div>
																<!-- Pourboire -->

																<!-- Montant à payer -->
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="montantAPayer &&
			(dataPage.frais == '1' ||
				dataPage.frais == '2' ||
				dataPage.frais == '3' ||
				dataPage.pourboire == '1')
			">
																	<div>
																		<span class="font-12">{{
																			$t("text_amount_to_pay.value")
																		}}</span>
																	</div>

																	<div>
																		<span class="font-12">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(parseFloat(montantAPayer))
																			}}
																		</span>
																	</div>
																</div>

																<!-- Montant total -->
																<div class="container d-flex justify-content-between mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		"
																	v-if="(dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3' || dataPage.pourboire == '1')">
																	<div>
																		<span
																			style="font-weight: 800; font-size: 22px">TOTAL</span>
																	</div>
																	<div>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					parseFloat(montantSave) +
																					parseFloat(dataPage.frais === "3"
																						? parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_cb / 2) / 100)
																							: 0
																						: parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_cb) / 100)
																							: 0
																					)
																				)
																			}}
																		</span>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="!montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(0)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Montant total -->
															</div>
															<!-- Recapitulatif Montant à payer, pourboire, frais -->

															<div :style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
																		: ''
																	">
																<button type="button" :class="!cardType
																	? 'btn btn-dark btn-disabled-custom btn-block'
																	: 'btn btn-dark btn-block'
																	" @click="sendCardPaymentUba"
																	:disabled="!cardType">
																	{{ $t("payment_modal.visa.button.value") }}
																	{{ typeCarte }}
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div>
								</div>
								<!-- Visa / Mastercard -->

								<!-- Orange Money -->
								<div v-if="menuSelected === 'Orange Money'">
									<div class="flex flex-column checkout__stage">
										<div class="flex flex-column align-items-center justify-content-center mt-3"
											v-if="isLoadingOrange">
											<!-- Loader -->
											<loading :active.sync="isLoadingOrange" :can-cancel="false"
												:on-cancel="onCancelOrange" :is-full-page="fullPageOrange" loader="bars"
												:opacity="1"></loading>
											<!-- Loader -->
										</div>

										<!-- Content -->
										<section>
											<div class="visa-qr-container" style="">
												<div>
													<div class="qr__wrapper container">
														<div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
															dataPage.frais == '2' ||
															dataPage.frais == '3' ||
															dataPage.pourboire == '1'
															? 'background: #f5f5f5; margin: 40px -30px 0px;'
															: ''
															">
															<img src="../assets/img/trait-recap.png"
																alt="Illustration zigzag recap"
																style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
																	dataPage.frais == '2' ||
																	dataPage.frais == '3' ||
																	dataPage.pourboire == '1'
																	" />

															<!-- Recapitulatif Montant à payer, pourboire, frais -->
															<div class="mt-1">
																<!-- Fees -->
																<div class="container d-flex justify-content-between mt-2 mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3'
		">
																	<div>
																		<h6 v-if="dataPage.frais == '1'">
																			{{ $t("text_fees_payment.value") }}
																		</h6>
																		<h6 v-if="dataPage.frais == '2' ||
																			dataPage.frais == '3'
																			">
																			{{ $t("text_fees_forced_payment.value") }}
																		</h6>
																	</div>
																	<label class="ui-switch switch-solid"
																		v-if="dataPage.frais == '1'">
																		<input type="checkbox" :checked="checkedFeesOm"
																			@click="tauxMontantOm" />
																		<span style="left: 20px"></span>
																	</label>
																	<div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
																		dataPage.frais == '3'
																		">
																		<input type="checkbox" :checked="checkedFeesOm"
																			@click="tauxMontantOm" />
																		<span style="left: 20px"></span>
																	</div>
																</div>
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="(dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3') &&
		checkedFeesOm
		">
																	<div>
																		<span class="font-12">{{
																			$t("text_fees.value")
																		}}</span>
																	</div>
																	<div>
																		<span class="font-12">
																			{{
																				dataPage.frais == "3"
																				? new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_om / 2
																						) /
																							100)
																						: 0
																				)
																				: new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_om
																						) /
																							100)
																						: 0
																				)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Fees -->

																<!-- Pourboire -->
																<!-- Type formulaire normal -->
																<div>
																	<div class="container d-flex justify-content-between"
																		:style="dataPage.frais == '1' ||
																			dataPage.frais == '2' ||
																			dataPage.frais == '3' ||
																			dataPage.pourboire == '1'
																			? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																			: ''
																			" v-if="dataPage.pourboire == '1' &&
			pourboire &&
			(pourboire == 'autre'
				? parseFloat(
					dataFormulaire.amountPourboire
				) > 0
				: parseFloat(pourboire) > 0)
			">
																		<div>
																			<span class="font-12">
																				{{ $t("text_pourboire.value") }}
																			</span>
																		</div>
																		<div>
																			<span class="font-12">
																				{{
																					new Intl.NumberFormat("fr-FR", {
																						style: "currency",
																						currency: "XOF",
																					}).format(
																						pourboire == "autre"
																							? dataFormulaire.amountPourboire
																								? parseFloat(
																									dataFormulaire.amountPourboire
																								)
																								: 0
																							: parseFloat(pourboire)
																					)
																				}}
																			</span>
																		</div>
																	</div>
																</div>
																<!-- Pourboire -->

																<!-- Montant à payer -->
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="montantAPayer &&
			(dataPage.frais == '1' ||
				dataPage.frais == '2' ||
				dataPage.frais == '3' ||
				dataPage.pourboire == '1')
			">
																	<div>
																		<span class="font-12">{{
																			$t("text_amount_to_pay.value")
																		}}</span>
																	</div>

																	<div>
																		<span class="font-12">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(parseFloat(montantAPayer))
																			}}
																		</span>
																	</div>
																</div>

																<!-- Montant total -->
																<div class="container d-flex justify-content-between mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		"
																	v-if="(dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3' || dataPage.pourboire == '1')">
																	<div>
																		<span
																			style="font-weight: 800; font-size: 22px">TOTAL</span>
																	</div>
																	<div>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					parseFloat(montantSave) +
																					parseFloat(dataPage.frais === "3"
																						? parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_om / 2) / 100)
																							: 0
																						: parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_om) / 100)
																							: 0
																					)
																				)
																			}}
																		</span>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="!montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(0)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Montant total -->
															</div>
															<!-- Recapitulatif Montant à payer, pourboire, frais -->

															<div :style="dataPage.frais == '1' ||
																dataPage.frais == '2' ||
																dataPage.frais == '3' ||
																dataPage.pourboire == '1'
																? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
																: ''
																" v-if="dataPage.yango === 1">
																<button v-if="mobileDevice" type="button" id="pay-btn"
																	@click="sendOM('app')"
																	class="btn btn-dark btn-block mb-3">
																	{{ $t("payment_modal.om.button2.value") }}
																</button>

																<span class="text-dark mt-5 mb-5" style="font-weight: 900"
																	v-if="mobileDevice">
																	{{ $t("payment_modal.om.or_om.value") }}
																</span>

																<button type="button" id="pay-btn" @click="sendOM('ussd')"
																	:class="mobileDevice
																		? 'btn btn-outline-dark btn-block mt-3'
																		: 'btn btn-dark btn-block mt-3'
																		" style>
																	{{ $t("payment_modal.om.button.value") }}
																</button>
															</div>

															<div :style="dataPage.frais == '1' ||
																dataPage.frais == '2' ||
																dataPage.frais == '3' ||
																dataPage.pourboire == '1'
																? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
																: ''
																" v-if="dataPage.yango !== 1">
																<button v-if="mobileDevice" type="button" id="pay-btn"
																	@click="sendOM('app')"
																	class="btn btn-dark btn-block mb-3">
																	{{ $t("payment_modal.om.button2.value") }}
																</button>

																<span class="text-dark mt-5 mb-5" style="font-weight: 900"
																	v-if="mobileDevice">
																	{{ $t("payment_modal.om.or_om.value") }}
																</span>

																<button type="button" id="pay-btn" @click="sendOM('ussd')"
																	:class="mobileDevice
																		? 'btn btn-outline-dark btn-block mt-3'
																		: 'btn btn-dark btn-block mt-3'
																		" style>
																	{{ $t("payment_modal.om.button.value") }}
																</button>
															</div>
														</div>
													</div>

													<div class="visa-qr-container__header mt-2">
														<p class="instruction mt-3" v-if="!mobileDevice">
															{{ $t("payment_modal.om.text.value") }}
														</p>
														<p class="instruction mt-3" v-if="mobileDevice">
															{{ $t("payment_modal.om.text2.value") }}
														</p>

														<div class="displayerror__details mt-3" v-if="orangeNOkay">
															<div>
																<p class="paiement-failed">
																	{{
																		$t(
																			"payment_modal.om.payment_failure_title.value"
																		)
																	}}
																</p>
																<div class="error-information">
																	{{
																		$t(
																			"payment_modal.om.payment_failure_details.value"
																		)
																	}}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
										<!-- Content -->
									</div>
								</div>
								<!-- Orange Money -->

								<!-- Wave -->
								<div v-if="menuSelected === 'Wave'">
									<div class="flex flex-column checkout__stage">
										<div class="flex flex-row align-items-center justify-content-center mt-3"
											v-if="isLoadingWave">
											<loading :active.sync="isLoadingWave" :can-cancel="false"
												:on-cancel="onCancelWave" :is-full-page="fullPageWave" loader="bars"
												:opacity="1">
											</loading>
										</div>
										<section>
											<div class="visa-qr-container" style="">
												<div>
													<div class="qr__wrapper container">
														<div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
															dataPage.frais == '2' ||
															dataPage.frais == '3' ||
															dataPage.pourboire == '1'
															? 'background: #f5f5f5; margin: 40px -30px 0px;'
															: ''
															">
															<img src="../assets/img/trait-recap.png"
																alt="Illustration zigzag recap"
																style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
																	dataPage.frais == '2' ||
																	dataPage.frais == '3' ||
																	dataPage.pourboire == '1'
																	" />

															<!-- Recapitulatif Montant à payer, pourboire, frais -->
															<div class="mt-1">
																<!-- Fees -->
																<div class="container d-flex justify-content-between mt-2 mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3'
		">
																	<div>
																		<h6 v-if="dataPage.frais == '1'">
																			{{ $t("text_fees_payment.value") }}
																		</h6>
																		<h6 v-if="dataPage.frais == '2' ||
																			dataPage.frais == '3'
																			">
																			{{ $t("text_fees_forced_payment.value") }}
																		</h6>
																	</div>
																	<label class="ui-switch switch-solid"
																		v-if="dataPage.frais == '1'">
																		<input type="checkbox" :checked="checkedFeesWave"
																			@click="tauxMontantWave" />
																		<span style="left: 20px"></span>
																	</label>
																	<div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
																		dataPage.frais == '3'
																		">
																		<input type="checkbox" :checked="checkedFeesWave"
																			@click="tauxMontantWave" />
																		<span style="left: 20px"></span>
																	</div>
																</div>
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		"
																	v-if="(dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3') && checkedFeesWave">
																	<div>
																		<span class="font-12">{{
																			$t("text_fees.value")
																		}}</span>
																	</div>
																	<div>
																		<span class="font-12">
																			{{
																				dataPage.frais == "3"
																				? new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_wave / 2
																						) /
																							100)
																						: 0
																				)
																				: new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_wave
																						) /
																							100)
																						: 0
																				)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Fees -->

																<!-- Pourboire -->
																<!-- Type formulaire normal -->
																<div>
																	<div class="container d-flex justify-content-between"
																		:style="dataPage.frais == '1' ||
																			dataPage.frais == '2' ||
																			dataPage.frais == '3' ||
																			dataPage.pourboire == '1'
																			? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																			: ''
																			" v-if="dataPage.pourboire == '1' &&
			pourboire &&
			(pourboire == 'autre'
				? parseFloat(
					dataFormulaire.amountPourboire
				) > 0
				: parseFloat(pourboire) > 0)
			">
																		<div>
																			<span class="font-12">
																				{{ $t("text_pourboire.value") }}
																			</span>
																		</div>
																		<div>
																			<span class="font-12">
																				{{
																					new Intl.NumberFormat("fr-FR", {
																						style: "currency",
																						currency: "XOF",
																					}).format(
																						pourboire == "autre"
																							? dataFormulaire.amountPourboire
																								? parseFloat(
																									dataFormulaire.amountPourboire
																								)
																								: 0
																							: parseFloat(pourboire)
																					)
																				}}
																			</span>
																		</div>
																	</div>
																</div>
																<!-- Pourboire -->

																<!-- Montant à payer -->
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="montantAPayer &&
			(dataPage.frais == '1' ||
				dataPage.frais == '2' ||
				dataPage.frais == '3' ||
				dataPage.pourboire == '1')
			">
																	<div>
																		<span class="font-12">{{
																			$t("text_amount_to_pay.value")
																		}}</span>
																	</div>

																	<div>
																		<span class="font-12">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(parseFloat(montantAPayer))
																			}}
																		</span>
																	</div>
																</div>

																<!-- Montant total -->
																<div class="container d-flex justify-content-between mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		"
																	v-if="(dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3' || dataPage.pourboire == '1')">
																	<div>
																		<span
																			style="font-weight: 800; font-size: 22px">TOTAL</span>
																	</div>
																	<div>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					parseFloat(montantSave) +
																					parseFloat(dataPage.frais === "3"
																						? parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_wave / 2) / 100)
																							: 0
																						: parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_wave) / 100)
																							: 0
																					)
																				)
																			}}
																		</span>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="!montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(0)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Montant total -->
															</div>
															<!-- Recapitulatif Montant à payer, pourboire, frais -->

															<!-- Wave button -->
															<div :style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
																		: ''
																	">
																<!-- Payer avec Wave / QR Code -->
																<button type="button" id="pay-btn" v-if="!mobileDevice"
																	@click="sendWave()" class="btn btn-dark btn-block mt-3">
																	{{ $t("payment_modal.wave.button.value") }}
																</button>
																<!-- Payer avec Wave / QR Code -->

																<!-- Payer avec l'application Wave -->
																<button type="button" id="pay-btn" v-if="mobileDevice"
																	@click="sendWave()" class="btn btn-dark btn-block mt-3">
																	{{ $t("payment_modal.wave.button_2.value") }}
																</button>
																<!-- Payer avec l'application Wave -->
															</div>
															<!-- Wave button -->
														</div>
													</div>

													<div class="visa-qr-container__header">
														<p class="instruction mt-3" v-if="!mobileDevice">
															{{ $t("payment_modal.wave.text.value") }}
														</p>

														<p class="instruction mt-3" v-if="mobileDevice">
															{{ $t("payment_modal.wave.text_2.value") }}
														</p>

														<!-- Display waiting message -->
														<div v-if="waveOkay" class="displayerror__details mt-3"
															style="padding: 10px 5px 2px 5px">
															<div class="qr__code">
																<i class="fas fa-spinner fa-spin"
																	style="font-size: 20px"></i>
															</div>
															<div>
																<p class="paiement-failed"
																	style="font-weight: 600; color: #261b64">
																	{{ $t("payment_modal.wave.waiting.value") }}
																</p>
															</div>
														</div>
														<!-- Display waiting message -->

														<!-- Display error message -->
														<div class="displayerror__details mt-3" v-if="waveNOkay">
															<div>
																<p class="paiement-failed">
																	{{
																		$t(
																			"payment_modal.wave.payment_failure_title.value"
																		)
																	}}
																</p>
																<div class="error-information">
																	{{
																		$t(
																			"payment_modal.wave.payment_failure_details.value"
																		)
																	}}
																</div>
															</div>
														</div>
														<!-- Display error message -->
													</div>
												</div>
											</div>
										</section>
									</div>
								</div>
								<!-- Wave -->

								<!-- MTN Money -->
								<div v-if="menuSelected === 'MTN Money'">
									<div class="checkout__stage">
										<section>
											<div class="flex flex-column visa-qr-container">
												<div class="flex flex-column align-items-center justify-content-center mt-3"
													v-if="isLoadingMomo">
													<loading :active.sync="isLoadingMomo" :can-cancel="false"
														:on-cancel="onCancelMomo" :is-full-page="fullPageMomo" loader="bars"
														:opacity="1"></loading>
												</div>
												<div>
													<div class="qr__wrapper container">
														<div class="element-pair-container mt-2 mb-3">
															<div class="element-wrap element-wrap-on-left">
																<multiselect v-model="indiceMobileMoney" :options="options"
																	:multiple="false" placeholder="Indicatif"
																	tag-placeholder="Indicatif" track-by="code"
																	label="country" :searchable="true" disabled>
																	<template slot="singleLabel" slot-scope="{ option }">{{
																		option.country_code
																	}}
																	</template>
																	<span slot="noResult"></span>
																</multiselect>
															</div>
															<div class="element-wrap element-wrap-on-right">
																<input type="tel" v-on:keypress="numbersOnly" :placeholder="$t('payment_modal.mtn.placeholder.value')
																		" class="element-is-input"
																	v-model="dataMoMo.numero" v-mask="'## ## ## ## ##'" />
															</div>
														</div>

														<div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
															dataPage.frais == '2' ||
															dataPage.frais == '3' ||
															dataPage.pourboire == '1'
															? 'background: #f5f5f5; margin: 40px -30px 0px;'
															: ''
															">
															<img src="../assets/img/trait-recap.png"
																alt="Illustration zigzag recap"
																style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
																	dataPage.frais == '2' ||
																	dataPage.frais == '3' ||
																	dataPage.pourboire == '1'
																	" />

															<!-- Recapitulatif Montant à payer, pourboire, frais -->
															<div class="mt-1">
																<!-- Fees -->
																<div class="container d-flex justify-content-between mt-2 mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3'
		">
																	<div>
																		<h6 v-if="dataPage.frais == '1'">
																			{{ $t("text_fees_payment.value") }}
																		</h6>
																		<h6 v-if="dataPage.frais == '2' ||
																			dataPage.frais == '3'
																			">
																			{{ $t("text_fees_forced_payment.value") }}
																		</h6>
																	</div>
																	<label class="ui-switch switch-solid"
																		v-if="dataPage.frais == '1'">
																		<input type="checkbox" :checked="checkedFeesMtn"
																			@click="tauxMontantMtn" />
																		<span style="left: 20px"></span>
																	</label>
																	<div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
																		dataPage.frais == '3'
																		">
																		<input type="checkbox" :checked="checkedFeesMtn"
																			@click="tauxMontantMtn" />
																		<span style="left: 20px"></span>
																	</div>
																</div>
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="(dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3') &&
		checkedFeesMtn
		">
																	<div>
																		<span class="font-12">{{
																			$t("text_fees.value")
																		}}</span>
																	</div>
																	<div>
																		<span class="font-12">
																			{{
																				dataPage.frais == "3"
																				? new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_mtn / 2
																						) /
																							100)
																						: 0
																				)
																				: new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_mtn
																						) /
																							100)
																						: 0
																				)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Fees -->

																<!-- Pourboire -->
																<!-- Type formulaire normal -->
																<div>
																	<div class="container d-flex justify-content-between"
																		:style="dataPage.frais == '1' ||
																			dataPage.frais == '2' ||
																			dataPage.frais == '3' ||
																			dataPage.pourboire == '1'
																			? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																			: ''
																			" v-if="dataPage.pourboire == '1' &&
			pourboire &&
			(pourboire == 'autre'
				? parseFloat(
					dataFormulaire.amountPourboire
				) > 0
				: parseFloat(pourboire) > 0)
			">
																		<div>
																			<span class="font-12">
																				{{ $t("text_pourboire.value") }}
																			</span>
																		</div>
																		<div>
																			<span class="font-12">
																				{{
																					new Intl.NumberFormat("fr-FR", {
																						style: "currency",
																						currency: "XOF",
																					}).format(
																						pourboire == "autre"
																							? dataFormulaire.amountPourboire
																								? parseFloat(
																									dataFormulaire.amountPourboire
																								)
																								: 0
																							: parseFloat(pourboire)
																					)
																				}}
																			</span>
																		</div>
																	</div>
																</div>
																<!-- Type formulaire paie vite -->
																<!-- Pourboire -->

																<!-- Montant à payer -->
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="montantAPayer &&
			(dataPage.frais == '1' ||
				dataPage.frais == '2' ||
				dataPage.frais == '3' ||
				dataPage.pourboire == '1')
			">
																	<div>
																		<span class="font-12">{{
																			$t("text_amount_to_pay.value")
																		}}</span>
																	</div>

																	<div>
																		<span class="font-12">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(parseFloat(montantAPayer))
																			}}
																		</span>
																	</div>
																</div>

																<!-- Montant total -->
																<div class="container d-flex justify-content-between mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		"
																	v-if="(dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3' || dataPage.pourboire == '1')">
																	<div>
																		<span
																			style="font-weight: 800; font-size: 22px">TOTAL</span>
																	</div>
																	<div>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					parseFloat(montantSave) +
																					parseFloat(dataPage.frais === "3"
																						? parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_mtn / 2) / 100)
																							: 0
																						: parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_mtn) / 100)
																							: 0
																					)
																				)
																			}}
																		</span>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="!montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(0)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Montant total -->
															</div>
															<!-- Recapitulatif Montant à payer, pourboire, frais -->

															<div :style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
																		: ''
																	">
																<button type="button" id="pay-btn" @click="sendMoMo" :class="!dataMoMo.numero
																	? 'btn btn-dark btn-disabled-custom btn-block'
																	: 'btn btn-dark btn-block'
																	" :disabled="!dataMoMo.numero">
																	{{ $t("payment_modal.mtn.button.value") }}
																</button>
															</div>
														</div>
													</div>

													<div class="visa-qr-container__header">
														<p class="instruction mt-3">
															{{ $t("payment_modal.mtn.text.value") }}
															<strong class="custom-text">*133#</strong>
															{{ $t("payment_modal.mtn.text2.value") }}
														</p>

														<!-- MTN Message en cas d'échec -->
														<div class="displayerror__details mt-3" v-if="mtnNOkay">
															<div>
																<p class="paiement-failed">
																	{{
																		$t(
																			"payment_modal.mtn.payment_failure_title.value"
																		)
																	}}
																</p>
																<div class="error-information">
																	{{
																		$t(
																			"payment_modal.mtn.payment_failure_details.value"
																		)
																	}}
																</div>
															</div>
														</div>
														<!-- MTN Message en cas d'échec -->

														<!-- MTN Message en cas d'attente de confirmation du paiement -->
														<div v-if="mtnOkay" class="displayerror__details mt-3"
															style="padding: 10px 5px 2px 5px">
															<div class="qr__code">
																<i class="fas fa-spinner fa-spin"
																	style="font-size: 20px"></i>
															</div>

															<div>
																<p class="paiement-failed"
																	style="font-weight: 600; color: #261b64">
																	{{
																		$t(
																			"payment_modal.mtn.payment_pending.value"
																		)
																	}}
																</p>
															</div>
														</div>
														<!-- MTN Message en cas d'attente de confirmation du paiement -->
													</div>
												</div>
											</div>
										</section>
									</div>
								</div>
								<!-- MTN Money -->

								<!-- Moov Money -->
								<div v-if="menuSelected === 'Moov Money'">
									<div class="checkout__stage">
										<section>
											<div class="visa-qr-container">
												<div class="flex flex-column">
													<div class="flex flex-column align-items-center justify-content-center mt-3"
														v-if="isLoadingFlooz">
														<loading :active.sync="isLoadingFlooz" :can-cancel="false"
															:on-cancel="onCancelFlooz" :is-full-page="fullPageFlooz"
															loader="bars" :opacity="1"></loading>
													</div>

													<div class="qr__wrapper container">
														<div class="element-pair mt-2 mb-3">
															<div class="element-pair-container">
																<div class="element-wrap element-wrap-on-left">
																	<multiselect v-model="indiceMobileMoney"
																		:options="options" :multiple="false"
																		placeholder="Indicatif" tag-placeholder="Indicatif"
																		track-by="code" label="country" :searchable="true"
																		disabled>
																		<template slot="singleLabel"
																			slot-scope="{ option }">{{
																				option.country_code
																			}}
																		</template>
																		<span slot="noResult"></span>
																	</multiselect>
																</div>
																<div class="element-wrap element-wrap-on-right">
																	<input type="tel" v-on:keypress="numbersOnly"
																		:placeholder="$t('payment_modal.moov.placeholder.value')
																				" class="element-is-input"
																		v-model="dataMoov.numero"
																		v-mask="'## ## ## ## ##'" />
																</div>
															</div>
														</div>

														<div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
															dataPage.frais == '2' ||
															dataPage.frais == '3' ||
															dataPage.pourboire == '1'
															? 'background: #f5f5f5; margin: 40px -30px 0px;'
															: ''
															">
															<img src="../assets/img/trait-recap.png"
																alt="Illustration zigzag recap"
																style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
																	dataPage.frais == '2' ||
																	dataPage.frais == '3' ||
																	dataPage.pourboire == '1'
																	" />

															<!-- Recapitulatif Montant à payer, pourboire, frais -->
															<div class="mt-1">
																<!-- Fees -->
																<div class="container d-flex justify-content-between mt-2 mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		"
																	v-if="dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3'">
																	<div>
																		<h6 v-if="dataPage.frais == '1'">
																			{{ $t("text_fees_payment.value") }}
																		</h6>
																		<h6 v-if="dataPage.frais == '2' ||
																			dataPage.frais == '3'
																			">
																			{{ $t("text_fees_forced_payment.value") }}
																		</h6>
																	</div>
																	<label class="ui-switch switch-solid"
																		v-if="dataPage.frais == '1'">
																		<input type="checkbox" :checked="checkedFeesMoov"
																			@click="tauxMontantMoov" />
																		<span style="left: 20px"></span>
																	</label>
																	<div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
																		dataPage.frais == '3'
																		">
																		<input type="checkbox" :checked="checkedFeesMoov"
																			@click="tauxMontantMoov" />
																		<span style="left: 20px"></span>
																	</div>
																</div>
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		"
																	v-if="(dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3') && checkedFeesMoov">
																	<div>
																		<span class="font-12">{{
																			$t("text_fees.value")
																		}}</span>
																	</div>
																	<div>
																		<span class="font-12">
																			{{
																				dataPage.frais == "3"
																				? new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_moov / 2
																						) /
																							100)
																						: 0
																				)
																				: new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_moov
																						) /
																							100)
																						: 0
																				)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Fees -->

																<!-- Pourboire -->
																<!-- Type formulaire normal -->
																<div>
																	<div class="container d-flex justify-content-between"
																		:style="dataPage.frais == '1' ||
																			dataPage.frais == '2' ||
																			dataPage.frais == '3' ||
																			dataPage.pourboire == '1'
																			? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																			: ''
																			" v-if="dataPage.pourboire == '1' &&
			pourboire &&
			(pourboire == 'autre'
				? parseFloat(
					dataFormulaire.amountPourboire
				) > 0
				: parseFloat(pourboire) > 0)
			">
																		<div>
																			<span class="font-12">
																				{{ $t("text_pourboire.value") }}
																			</span>
																		</div>
																		<div>
																			<span class="font-12">
																				{{
																					new Intl.NumberFormat("fr-FR", {
																						style: "currency",
																						currency: "XOF",
																					}).format(
																						pourboire == "autre"
																							? dataFormulaire.amountPourboire
																								? parseFloat(
																									dataFormulaire.amountPourboire
																								)
																								: 0
																							: parseFloat(pourboire)
																					)
																				}}
																			</span>
																		</div>
																	</div>
																</div>
																<!-- Type formulaire normal -->
																<!-- Pourboire -->

																<!-- Montant à payer -->
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="montantAPayer &&
			(dataPage.frais == '1' ||
				dataPage.frais == '2' ||
				dataPage.frais == '3' ||
				dataPage.pourboire == '1')
			">
																	<div>
																		<span class="font-12">{{
																			$t("text_amount_to_pay.value")
																		}}</span>
																	</div>

																	<div>
																		<span class="font-12">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(parseFloat(montantAPayer))
																			}}
																		</span>
																	</div>
																</div>

																<!-- Montant total -->
																<div class="container d-flex justify-content-between mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		"
																	v-if="(dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3' || dataPage.pourboire == '1')">
																	<div>
																		<span
																			style="font-weight: 800; font-size: 22px">TOTAL</span>
																	</div>
																	<div>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					parseFloat(montantSave) +
																					parseFloat(dataPage.frais === "3"
																						? parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_moov / 2) / 100)
																							: 0
																						: parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_moov) / 100)
																							: 0
																					)
																				)
																			}}
																		</span>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="!montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(0)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Montant total -->
															</div>
															<!-- Recapitulatif Montant à payer, pourboire, frais -->

															<div :style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
																		: ''
																	">
																<button type="button" @click="sendMoov" :class="!dataMoov.numero
																	? 'btn btn-dark btn-disabled-custom btn-block'
																	: 'btn btn-dark btn-block'
																	" :disabled="!dataMoov.numero">
																	{{ $t("payment_modal.moov.button.value") }}
																</button>
															</div>
														</div>
													</div>

													<div class="visa-qr-container__header">
														<p class="instruction mt-3">
															{{ $t("payment_modal.moov.text.value") }}
														</p>

														<div class="displayerror__details mt-3" v-if="moovNOkay">
															<div>
																<p class="paiement-failed">
																	{{
																		$t(
																			"payment_modal.moov.payment_failure_title.value"
																		)
																	}}
																</p>
																<div class="error-information">
																	{{
																		$t(
																			"payment_modal.moov.payment_failure_details.value"
																		)
																	}}
																</div>
															</div>
														</div>

														<div v-if="moovOkay" class="displayerror__details mt-3 mb-2"
															style="padding: 10px 5px 2px 5px">
															<!-- checkingFloozStatus() -->

															<div class="qr__code mt-2 pt-2" v-if="showVerifMoov">
																<i class="fas fa-spinner fa-spin"
																	style="font-size: 20px"></i>
															</div>
															<div class="mt-2 pt-2"
																v-if="!showVerifMoov && !showInProcessMoov">
																<p class="paiement-failed" style="font-weight: 600">
																	{{
																		$t(
																			"payment_modal.moov.payment_waiting_1.value"
																		)
																	}}
																</p>
															</div>
															<span class="timer" v-if="!showVerifMoov && !showInProcessMoov">
																{{ formattedTimeLeft }} min
															</span>
															<div class="mt-2" v-if="!showVerifMoov && !showInProcessMoov">
																<p class="paiement-failed" style="font-weight: 600">
																	{{
																		$t(
																			"payment_modal.moov.payment_waiting_2.value"
																		)
																	}}
																</p>
															</div>

															<p class="paiement-failed" style="font-weight: 600"
																v-if="!showVerifMoov && !showInProcessMoov">
																{{
																	$t(
																		"payment_modal.moov.ou_check_yourself.value"
																	)
																}}
															</p>

															<button type="button" @click="checkingFloozStatus"
																class="btn btn-dark mb-3"
																v-if="!showVerifMoov && !showInProcessMoov">
																{{
																	$t("payment_modal.moov.check_status.value")
																}}
															</button>

															<div class="mt-2" v-if="showVerifMoov">
																<p class="paiement-failed"
																	style="font-weight: 600; color: #261b64">
																	{{
																		$t(
																			"payment_modal.moov.payment_pending.value"
																		)
																	}}
																</p>
															</div>

															<div class="qr__code mt-2 pt-2" v-if="showInProcessMoov">
																<i class="fas fa-spinner fa-spin"
																	style="font-size: 20px"></i>
															</div>

															<div class="mt-2" v-if="showInProcessMoov">
																<p class="paiement-failed"
																	style="font-weight: 600; color: #261b64">
																	{{
																		$t(
																			"payment_modal.moov.payment_waiting_3.value"
																		)
																	}}
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div>
								</div>
								<!-- Moov Money -->

								<!-- Tresor PAY -->
								<div v-if="menuSelected === 'Tresor PAY'">
									<div class="checkout__stage">
										<section>
											<div class="visa-qr-container">
												<div class="flex flex-column">
													<div class="flex flex-column align-items-center justify-content-center mt-3"
														v-if="isLoadingTresorPay">
														<loading :active.sync="isLoadingTresorPay" :can-cancel="false"
															:on-cancel="onCancelTresorPay" :is-full-page="fullPageTresorPay"
															loader="bars" :opacity="1"></loading>
													</div>

													<div class="qr__wrapper container">
														<div class="element-pair mt-2 mb-3">
															<div class="element-pair-container">
																<div class="element-wrap element-wrap-on-left">
																	<multiselect v-model="indiceMobileMoney"
																		:options="options" :multiple="false"
																		placeholder="Indicatif" tag-placeholder="Indicatif"
																		track-by="code" label="country" :searchable="true"
																		disabled>
																		<template slot="singleLabel"
																			slot-scope="{ option }">{{
																				option.country_code
																			}}
																		</template>
																		<span slot="noResult"></span>
																	</multiselect>
																</div>
																<div class="element-wrap element-wrap-on-right">
																	<input type="tel" v-on:keypress="numbersOnly"
																		:placeholder="$t('payment_modal.tresor.placeholder.value')
																				" class="element-is-input"
																		v-model="dataTresorPAY.numero"
																		v-mask="'## ## ## ## ##'" />
																</div>
															</div>
														</div>

														<div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
															dataPage.frais == '2' ||
															dataPage.frais == '3' ||
															dataPage.pourboire == '1'
															? 'background: #f5f5f5; margin: 40px -30px 0px;'
															: ''
															">
															<img src="../assets/img/trait-recap.png"
																alt="Illustration zigzag recap"
																style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
																	dataPage.frais == '2' ||
																	dataPage.frais == '3' ||
																	dataPage.pourboire == '1'
																	" />

															<!-- Recapitulatif Montant à payer, pourboire, frais -->
															<div class="mt-1">
																<!-- Fees -->
																<div class="container d-flex justify-content-between mt-2 mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3'
		">
																	<div>
																		<h6 v-if="dataPage.frais == '1'">
																			{{ $t("text_fees_payment.value") }}
																		</h6>
																		<h6 v-if="dataPage.frais == '2' ||
																			dataPage.frais == '3'
																			">
																			{{ $t("text_fees_forced_payment.value") }}
																		</h6>
																	</div>
																	<label class="ui-switch switch-solid"
																		v-if="dataPage.frais == '1'">
																		<input type="checkbox"
																			:checked="checkedFeesTresorPAY"
																			@click="tauxMontantTresor" />
																		<span style="left: 20px"></span>
																	</label>
																	<div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
																		dataPage.frais == '3'
																		">
																		<input type="checkbox"
																			:checked="checkedFeesTresorPAY"
																			@click="tauxMontantTresor" />
																		<span style="left: 20px"></span>
																	</div>
																</div>
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="(dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3') &&
		checkedFeesTresorPAY
		">
																	<div>
																		<span class="font-12">{{
																			$t("text_fees.value")
																		}}</span>
																	</div>
																	<div>
																		<span class="font-12">
																			{{
																				dataPage.frais == "3"
																				? new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_tresor / 2
																						) /
																							100)
																						: 0
																				)
																				: new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_tresor
																						) /
																							100)
																						: 0
																				)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Fees -->

																<!-- Pourboire -->
																<!-- Type formulaire normal -->
																<div>
																	<div class="container d-flex justify-content-between"
																		:style="dataPage.frais == '1' ||
																			dataPage.frais == '2' ||
																			dataPage.frais == '3' ||
																			dataPage.pourboire == '1'
																			? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																			: ''
																			" v-if="dataPage.pourboire == '1' &&
			pourboire &&
			(pourboire == 'autre'
				? parseFloat(
					dataFormulaire.amountPourboire
				) > 0
				: parseFloat(pourboire) > 0)
			">
																		<div>
																			<span class="font-12">
																				{{ $t("text_pourboire.value") }}
																			</span>
																		</div>
																		<div>
																			<span class="font-12">
																				{{
																					new Intl.NumberFormat("fr-FR", {
																						style: "currency",
																						currency: "XOF",
																					}).format(
																						pourboire == "autre"
																							? dataFormulaire.amountPourboire
																								? parseFloat(
																									dataFormulaire.amountPourboire
																								)
																								: 0
																							: parseFloat(pourboire)
																					)
																				}}
																			</span>
																		</div>
																	</div>
																</div>
																<!-- Pourboire -->

																<!-- Montant à payer -->
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="montantAPayer &&
			(dataPage.frais == '1' ||
				dataPage.frais == '2' ||
				dataPage.frais == '3' ||
				dataPage.pourboire == '1')
			">
																	<div>
																		<span class="font-12">{{
																			$t("text_amount_to_pay.value")
																		}}</span>
																	</div>

																	<div>
																		<span class="font-12">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(parseFloat(montantAPayer))
																			}}
																		</span>
																	</div>
																</div>

																<!-- Montant total -->
																<div class="container d-flex justify-content-between mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		"
																	v-if="(dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3' || dataPage.pourboire == '1')">
																	<div>
																		<span
																			style="font-weight: 800; font-size: 22px">TOTAL</span>
																	</div>
																	<div>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					parseFloat(montantSave) +
																					parseFloat(dataPage.frais === "3"
																						? parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_tresor / 2) / 100)
																							: 0
																						: parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_tresor) / 100)
																							: 0
																					)
																				)
																			}}
																		</span>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="!montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(0)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Montant total -->
															</div>
															<!-- Recapitulatif Montant à payer, pourboire, frais -->

															<div :style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
																		: ''
																	">
																<button type="button" @click="sendTresorPAY" :class="!dataTresorPAY.numero
																	? 'btn btn-dark btn-disabled-custom btn-block'
																	: 'btn btn-dark btn-block'
																	" :disabled="!dataTresorPAY.numero">
																	{{ $t("payment_modal.tresor.button.value") }}
																</button>
															</div>
														</div>
													</div>

													<div class="visa-qr-container__header">
														<p class="instruction mt-3">
															{{ $t("payment_modal.tresor.text.value") }}
														</p>

														<div class="displayerror__details mt-3" v-if="tresorNOkay">
															<div>
																<p class="paiement-failed">
																	{{
																		$t(
																			"payment_modal.tresor.payment_failure_title.value"
																		)
																	}}
																</p>
																<div class="error-information">
																	{{
																		payment_failure_details
																	}}
																</div>
															</div>
														</div>

														<div v-if="tresorOkay === true"
															class="displayerror__details mt-3 mb-2"
															style="padding: 10px 5px 2px 5px">
															<!-- checkingFloozStatus() -->

															<div class="qr__code mt-2 pt-2" v-if="showVerifTresor">
																<i class="fas fa-spinner fa-spin"
																	style="font-size: 20px"></i>
															</div>
															<div class="mt-2 pt-2"
																v-if="!showVerifTresor && !showInProcessTresor">
																<p class="paiement-failed" style="font-weight: 600">
																	{{
																		$t(
																			"payment_modal.tresor.payment_waiting_1.value"
																		)
																	}}
																</p>
															</div>

															<div class="mt-2" v-if="showVerifTresor">
																<p class="paiement-failed"
																	style="font-weight: 600; color: #261b64">
																	{{
																		$t(
																			"payment_modal.tresor.payment_pending.value"
																		)
																	}}
																</p>
															</div>

															<div class="qr__code mt-2 pt-2" v-if="showInProcessTresor">
																<i class="fas fa-spinner fa-spin"
																	style="font-size: 20px"></i>
															</div>

															<div class="mt-2" v-if="showInProcessTresor">
																<p class="paiement-failed"
																	style="font-weight: 600; color: #261b64">
																	{{
																		$t(
																			"payment_modal.tresor.payment_waiting_3.value"
																		)
																	}}
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div>
								</div>
								<!-- Tresor PAY -->

								<!-- APaym USSD -->
								<div v-if="menuSelected === 'APaym USSD'">
									<div class="checkout__stage">
										<section>
											<div class="flex flex-column visa-qr-container">
												<div class="flex flex-column align-items-center justify-content-center mt-3"
													v-if="isLoadingAPaym">
													<loading :active.sync="isLoadingAPaym" :can-cancel="false"
														:on-cancel="onCancelAPaym" :is-full-page="fullPageAPaym"
														loader="bars" :opacity="1"></loading>
												</div>
												<div>
													<div class="qr__wrapper">
														<div class="element-pair mt-2 mb-3">
															<div class="element-pair-container mt-4">
																<div class="element-wrap element-wrap-on-left">
																	<multiselect v-model="dataApaymPay.indice"
																		:options="options" :multiple="false"
																		placeholder="Indicatif" tag-placeholder="Indicatif"
																		track-by="code" label="country" :searchable="true">
																		<template slot="singleLabel"
																			slot-scope="{ option }">{{
																				option.country_code
																			}}
																		</template>
																		<span slot="noResult"></span>
																	</multiselect>
																</div>
																<div class="element-wrap element-wrap-on-right">
																	<input class="element-is-input" type="tel"
																		v-on:keypress="numbersOnly"
																		v-mask="'## ## ## ## ##'"
																		v-model="dataApaymPay.numApaym" :placeholder="$t(
																					'payment_modal.ussd_apaym.placeholder.value'
																				)
																				" />
																</div>
															</div>
														</div>

														<div class="element-wrap">
															<input type="tel" v-on:keypress="numbersOnly"
																class="element-is-input" :disabled="!dataApaymPay.numApaym"
																v-model="dataApaymPay.codeApaym" :placeholder="$t(
																	'payment_modal.ussd_apaym.placeholder2.value'
																)
																	" />
														</div>

														<div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
															dataPage.frais == '2' ||
															dataPage.frais == '3' ||
															dataPage.pourboire == '1'
															? 'background: #f5f5f5; margin: 40px -15px 0px;'
															: ''
															">
															<img src="../assets/img/trait-recap.png"
																alt="Illustration zigzag recap"
																style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
																	dataPage.frais == '2' ||
																	dataPage.frais == '3' ||
																	dataPage.pourboire == '1'
																	" />

															<!-- Recapitulatif Montant à payer, pourboire, frais -->
															<div class="mt-1">
																<!-- Fees -->
																<div class="container d-flex justify-content-between mt-2 mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3'
		">
																	<div>
																		<h6 v-if="dataPage.frais == '1'">
																			{{ $t("text_fees_payment.value") }}
																		</h6>
																		<h6 v-if="dataPage.frais == '2' ||
																			dataPage.frais == '3'
																			">
																			{{ $t("text_fees_forced_payment.value") }}
																		</h6>
																	</div>
																	<label class="ui-switch switch-solid"
																		v-if="dataPage.frais == '1'">
																		<input type="checkbox" :checked="checkedFeesApaym"
																			@click="tauxMontantApaym" />
																		<span style="left: 20px"></span>
																	</label>
																	<div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
																		dataPage.frais == '3'
																		">
																		<input type="checkbox" :checked="checkedFeesApaym"
																			@click="tauxMontantApaym" />
																		<span style="left: 20px"></span>
																	</div>
																</div>
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="(dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3') &&
		checkedFeesApaym
		">
																	<div>
																		<span class="font-12">{{
																			$t("text_fees.value")
																		}}</span>
																	</div>
																	<div>
																		<span class="font-12">
																			{{
																				dataPage.frais == "3"
																				? new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_apaym / 2
																						) /
																							100)
																						: 0
																				)
																				: new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_apaym
																						) /
																							100)
																						: 0
																				)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Fees -->

																<!-- Pourboire -->
																<!-- Type formulaire normal -->
																<div>
																	<div class="container d-flex justify-content-between"
																		:style="dataPage.frais == '1' ||
																			dataPage.frais == '2' ||
																			dataPage.frais == '3' ||
																			dataPage.pourboire == '1'
																			? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																			: ''
																			" v-if="dataPage.pourboire == '1' &&
			pourboire &&
			(pourboire == 'autre'
				? parseFloat(
					dataFormulaire.amountPourboire
				) > 0
				: parseFloat(pourboire) > 0)
			">
																		<div>
																			<span class="font-12">
																				{{ $t("text_pourboire.value") }}
																			</span>
																		</div>
																		<div>
																			<span class="font-12">
																				{{
																					new Intl.NumberFormat("fr-FR", {
																						style: "currency",
																						currency: "XOF",
																					}).format(
																						pourboire == "autre"
																							? dataFormulaire.amountPourboire
																								? parseFloat(
																									dataFormulaire.amountPourboire
																								)
																								: 0
																							: parseFloat(pourboire)
																					)
																				}}
																			</span>
																		</div>
																	</div>
																</div>
																<!-- Pourboire -->

																<!-- Montant à payer -->
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="montantAPayer &&
			(dataPage.frais == '1' ||
				dataPage.frais == '2' ||
				dataPage.frais == '3' ||
				dataPage.pourboire == '1')
			">
																	<div>
																		<span class="font-12">{{
																			$t("text_amount_to_pay.value")
																		}}</span>
																	</div>

																	<div>
																		<span class="font-12">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(parseFloat(montantAPayer))
																			}}
																		</span>
																	</div>
																</div>

																<!-- Montant total -->
																<div class="container d-flex justify-content-between mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		"
																	v-if="(dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3' || dataPage.pourboire == '1')">
																	<div>
																		<span
																			style="font-weight: 800; font-size: 22px">TOTAL</span>
																	</div>
																	<div>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					parseFloat(montantSave) +
																					parseFloat(dataPage.frais === "3"
																						? parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_apaym / 2) / 100)
																							: 0
																						: parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_apaym) / 100)
																							: 0
																					)
																				)
																			}}
																		</span>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="!montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(0)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Montant total -->
															</div>
															<!-- Recapitulatif Montant à payer, pourboire, frais -->

															<div :style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
																		: ''
																	">
																<button type="button" id="pay-btn" @click="sendAPaym"
																	:class="!dataApaymPay.numApaym ||
																		!dataApaymPay.codeApaym
																		? 'btn btn-dark btn-disabled-custom btn-block'
																		: 'btn btn-dark btn-block'
																		" :disabled="!dataApaymPay.numApaym ||
		!dataApaymPay.codeApaym
		">
																	{{
																		$t("payment_modal.ussd_apaym.button.value")
																	}}
																</button>
															</div>
														</div>
													</div>

													<div class="visa-qr-container__header">
														<!-- <strong class="mb-2" style="font-size: 22px;">
                                                            *766#
                                                        </strong> -->
														<p class="instruction mt-3">
															{{ $t("payment_modal.ussd_apaym.text.value") }}
															<strong class="custom-text">*766#</strong>
															{{ $t("payment_modal.ussd_apaym.text2.value") }}
														</p>
													</div>
												</div>
											</div>
										</section>
									</div>
								</div>
								<!-- APaym USSD -->

								<!-- APaym -->
								<div v-if="menuSelected === 'APaym'">
									<!-- Content APaym Payment - Listing des cartes visa du profil APaym -->
									<div class="checkout__stage">
										<section style="width: 100%">
											<div class="visa-qr-container">
												<div class="flex flex-column">
													<div class="flex flex-column align-items-center justify-content-center mt-3"
														v-if="isLoadingAPaymAuth">
														<loading :active.sync="isLoadingAPaymAuth" :can-cancel="false"
															:on-cancel="onCancelAPaymAuth" :is-full-page="fullPageAPaymAuth"
															loader="bars" :opacity="1"></loading>
													</div>

													<div class="qr__wrapper">
														<div class="element-pair mt-3" v-if="loginApaym">
															<div class="element-pair-container mt-4">
																<div class="element-wrap element-wrap-on-left"
																	style="width: 5px; !important">
																	<multiselect v-model="dataApaymPayAuth.indice"
																		:options="options" :multiple="false"
																		placeholder="Indicatif" tag-placeholder="Indicatif"
																		track-by="code" label="country" :searchable="true">
																		<template slot="singleLabel"
																			slot-scope="{ option }">{{
																				option.country_code
																			}}
																		</template>
																		<span slot="noResult"></span>
																	</multiselect>
																</div>
																<div class="element-wrap element-wrap-on-right">
																	<div style="
                                      display: flex;
                                      flex-direction: row;
                                      align-items: center;
                                    ">
																		<input class="element-is-input" type="tel"
																			v-on:keypress="numbersOnly"
																			v-mask="'## ## ## ## ##'"
																			v-model="dataApaymPayAuth.numApaym"
																			:placeholder="$t(
																						'payment_modal.apaym.placeholder.value'
																					)
																					" />
																		<!-- <i class="fas fa-arrow-right searchIcon" aria-hidden="true" @click="sendAPaymAuth"></i> -->
																		<button type="button" @click="sendAPaymAuth" :class="!dataApaymPayAuth.numApaym
																			? 'btn btn-dark btn-disabled-custom searchIcon'
																			: 'btn btn-dark searchIcon'
																			" :disabled="!dataApaymPayAuth.numApaym">
																			<i class="fas fa-arrow-right"
																				aria-hidden="true"></i>
																		</button>
																	</div>
																</div>
															</div>

															<!-- <div class="container mt-4 d-flex-inline">
                                                                <p
                                                                    class="text-danger mb-2"
                                                                    v-if="this.error !== null"
                                                                >
                                                                    <i class="fas fa-exclamation-circle"></i>
                                                                    {{ this.error }}
                                                                </p>
                                                                <vue-hcaptcha
                                                                    class="h-captcha"
                                                                    @verify="onVerify"
                                                                    @expired="onExpired"
                                                                    @error="onError"
                                                                    sitekey="cc4778d8-c49d-40ad-950c-ab566138bda2"
                                                                ></vue-hcaptcha>
                                                            </div> -->
														</div>

														<div class="element-wrap" v-if="hideCodePay">
															<input type="tel" v-on:keypress="numbersOnly"
																class="element-is-input"
																:disabled="!dataApaymPayAuth.numApaym"
																v-model="dataApaymPayAuth.codeApaym" :placeholder="$t('payment_modal.apaym.placeholder2.value')
																	" />
														</div>

														<div class="element-wrap element-wrap-for-submit" :style="dataPage.frais == '1' ||
															dataPage.frais == '2' ||
															dataPage.frais == '3' ||
															dataPage.pourboire == '1'
															? 'background: #f5f5f5; margin: 40px -15px 0px;'
															: ''
															" v-if="hideCodePay">
															<img src="../assets/img/trait-recap.png"
																alt="Illustration zigzag recap"
																style="position: relative; top: -10px" v-if="dataPage.frais == '1' ||
																	dataPage.frais == '2' ||
																	dataPage.frais == '3' ||
																	dataPage.pourboire == '1'
																	" />

															<!-- Recapitulatif Montant à payer, pourboire, frais -->
															<div class="mt-1">
																<!-- Fees -->
																<div class="container d-flex justify-content-between mt-2 mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3'
		">
																	<div>
																		<h6 v-if="dataPage.frais == '1'">
																			{{ $t("text_fees_payment.value") }}
																		</h6>
																		<h6 v-if="dataPage.frais == '2' ||
																			dataPage.frais == '3'
																			">
																			{{ $t("text_fees_forced_payment.value") }}
																		</h6>
																	</div>
																	<label class="ui-switch switch-solid"
																		v-if="dataPage.frais == '1'">
																		<input type="checkbox" :checked="checkedFeesApaym"
																			@click="tauxMontantApaym" />
																		<span style="left: 20px"></span>
																	</label>
																	<div class="ui-switch switch-solid" v-if="dataPage.frais == '2' ||
																		dataPage.frais == '3'
																		">
																		<input type="checkbox" :checked="checkedFeesApaym"
																			@click="tauxMontantApaym" />
																		<span style="left: 20px"></span>
																	</div>
																</div>
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="(dataPage.frais == '1' ||
		dataPage.frais == '2' ||
		dataPage.frais == '3') &&
		checkedFeesApaym
		">
																	<div>
																		<span class="font-12">{{
																			$t("text_fees.value")
																		}}</span>
																	</div>
																	<div>
																		<span class="font-12">
																			{{
																				dataPage.frais == "3"
																				? new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_apaym / 2
																						) /
																							100)
																						: 0
																				)
																				: new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					montantSave
																						? parseFloat(montantSave) *
																						(parseFloat(
																							dataPage.taux_apaym
																						) /
																							100)
																						: 0
																				)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Fees -->

																<!-- Pourboire -->
																<!-- Type formulaire normal -->
																<div>
																	<div class="container d-flex justify-content-between"
																		:style="dataPage.frais == '1' ||
																			dataPage.frais == '2' ||
																			dataPage.frais == '3' ||
																			dataPage.pourboire == '1'
																			? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																			: ''
																			" v-if="dataPage.pourboire == '1' &&
			pourboire &&
			(pourboire == 'autre'
				? parseFloat(
					dataFormulaire.amountPourboire
				) > 0
				: parseFloat(pourboire) > 0)
			">
																		<div>
																			<span class="font-12">
																				{{ $t("text_pourboire.value") }}
																			</span>
																		</div>
																		<div>
																			<span class="font-12">
																				{{
																					new Intl.NumberFormat("fr-FR", {
																						style: "currency",
																						currency: "XOF",
																					}).format(
																						pourboire == "autre"
																							? dataFormulaire.amountPourboire
																								? parseFloat(
																									dataFormulaire.amountPourboire
																								)
																								: 0
																							: parseFloat(pourboire)
																					)
																				}}
																			</span>
																		</div>
																	</div>
																</div>

																<!-- Montant à payer -->
																<div class="container d-flex justify-content-between"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		" v-if="montantAPayer &&
			(dataPage.frais == '1' ||
				dataPage.frais == '2' ||
				dataPage.frais == '3' ||
				dataPage.pourboire == '1')
			">
																	<div>
																		<span class="font-12">{{
																			$t("text_amount_to_pay.value")
																		}}</span>
																	</div>

																	<div>
																		<span class="font-12">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(parseFloat(montantAPayer))
																			}}
																		</span>
																	</div>
																</div>

																<!-- Montant total -->
																<div class="container d-flex justify-content-between mb-3"
																	:style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
																		: ''
																		"
																	v-if="(dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3' || dataPage.pourboire == '1')">
																	<div>
																		<span
																			style="font-weight: 800; font-size: 22px">TOTAL</span>
																	</div>
																	<div>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(
																					parseFloat(montantSave) +
																					parseFloat(dataPage.frais === "3"
																						? parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_apaym / 2) / 100)
																							: 0
																						: parseFloat(montantSave) ?
																							parseFloat(montantSave) *
																							(parseFloat(dataPage.taux_apaym) / 100)
																							: 0
																					)
																				)
																			}}
																		</span>
																		<span style="font-weight: 800; font-size: 22px"
																			v-if="!montantSave">
																			{{
																				new Intl.NumberFormat("fr-FR", {
																					style: "currency",
																					currency: "XOF",
																				}).format(0)
																			}}
																		</span>
																	</div>
																</div>
																<!-- Montant total -->
															</div>
															<!-- Recapitulatif Montant à payer, pourboire, frais -->

															<div :style="dataPage.frais == '1' ||
																		dataPage.frais == '2' ||
																		dataPage.frais == '3' ||
																		dataPage.pourboire == '1'
																		? 'padding: 5px 25px 15px 25px; top: -20px; position: relative;'
																		: ''
																	">
																<button type="button" @click="sendAPaymToken"
																	:disabled="!dataApaymPayAuth.numApaym || !dataApaymPayAuth.codeApaym"
																	:class="!dataApaymPayAuth.numApaym || !dataApaymPayAuth.codeApaym
																		? 'btn btn-dark btn-disabled-custom btn-block'
																		: 'btn btn-dark btn-block'
																		">
																	{{ $t("payment_modal.apaym.button.value") }}
																</button>
															</div>
														</div>

														<!-- Liste des cartes -->
														<div v-if="showCard == true">
															<ul class="list-unstyled media-list-divider text-left"
																:style="cardsLength > 5 ? 'height: 392px; overflow-y: scroll;' : 'height: auto;'">
																<li class="media py-3" v-for="(carte, i) in cards" :key="i"
																	style="cursor: pointer" @click="payWithApaym(carte)">
																	<img :src="require(`../assets/img/cards/${carte.imgCard}`)
																		" alt="Card image" class="mr-3" width="60" />
																	<div class="media-body flexbox"
																		style="flex-direction: row; align-items: center;">
																		<div>
																			<h6 class="mt-0 mb-1 font-weight-bold">
																				<a href="javascript:void(0);">{{
																					carte.nickname
																				}}</a>
																			</h6>
																			<span class="font-12">
																				Visa **** {{ carte.last_4_digit }}
																			</span>
																		</div>

																		<a href="javascript:void(0)"
																			class="btn btn-light btn-sm btn-rounded"
																			style="line-height: 1.3" v-if="carte.favori_card == true &&
																				$i18n.locale == 'fr'
																				">
																			Par défaut
																		</a>

																		<a href="javascript:void(0)"
																			class="btn btn-light btn-sm btn-rounded"
																			style="line-height: 1.3" v-if="carte.favori_card == true &&
																				$i18n.locale == 'en'
																				">
																			By default
																		</a>
																	</div>
																</li>
															</ul>
															<!-- <div>Cliquez sur l'une de vos cartes pour effectuer le paiement</div> -->
														</div>
														<!-- Liste des cartes -->
													</div>

													<div class="visa-qr-container__header" style="text-align: right">
														<div v-if="showCard == true" style="text-align: center">
															{{ $t("payment_modal.apaym.choose_card.value") }}
														</div>

														<div class="btn btn-link btn-sm my-4" v-if="showCard == true"
															@click="disconnectApaym" style="align-items: center; background: #ffffff; justify-content: center; font-weight: 900; border-color: #241d60; line-height: 1.6;
                                                        ">
															{{ $t("payment_modal.apaym.logout.value") }}
														</div>

														<p class="instruction mt-3" v-if="loginApaym"
															style="text-align: center">
															{{ $t("payment_modal.apaym.text.value") }}
														</p>
													</div>
												</div>
											</div>
										</section>
									</div>
									<!-- Content APaym Payment - Listing des cartes visa du profil APaym -->
								</div>
								<!-- APaym -->

								<!-- ECOBANK Pay -->
								<div class="flex flex-column" v-if="menuSelected === 'ECOBANK Pay'">

									<div class="flex flex-column align-items-center justify-content-center mt-3"
										v-if="isLoadingEcobank">
										<!-- Loader -->
										<loading :active.sync="isLoadingEcobank" :can-cancel="true" :on-cancel="onCancelQr"
											:is-full-page="fullPageEcobank" loader="bars" :opacity="1"></loading>
										<!-- Loader -->
									</div>

									<!-- Content -->
									<div class="checkout__stage">
										<section>
											<div class="visa-qr-container">
												<div>
													<div class="visa-qr-container__header">
														<p class="instruction mt-3">
															{{ $t("payment_modal.ecobank_qr.text.value") }}
														</p>
													</div>

													<a download="ecobank-qr-code.png" class="btn btn-light mt-2 mb-2"
														:href="ecobankQrCodeUri"
														style="height: 35px; line-height: 1.4; background: #f4f4f4; border: #f4f4f4;">
														{{ $t("payment_modal.download_visa_qr.value") }}
													</a>

													<!-- Recapitulatif Montant à payer, pourboire, frais -->
													<div class="mt-1">
														<!-- Fees -->
														<div class="container d-flex justify-content-between mt-2 mb-3"
															v-if="dataPage.frais == '1' ||
																dataPage.frais == '2' ||
																dataPage.frais == '3'
																">
															<div>
																<h6 v-if="dataPage.frais == '1'">
																	{{ $t("text_fees_payment.value") }}
																</h6>
																<h6 v-if="dataPage.frais == '2' ||
																	dataPage.frais == '3'
																	">
																	{{ $t("text_fees_forced_payment.value") }}
																</h6>
															</div>
															<label class="ui-switch switch-solid"
																v-if="dataPage.frais == '1'">
																<input type="checkbox" :checked="checkedFeesApaym"
																	@click="feeAmountEcobankQr" />
																<span style="left: 20px"></span>
															</label>
															<div class="ui-switch switch-solid" v-if="dataPage.frais == '2' || dataPage.frais == '3'
																">
																<input type="checkbox" :checked="checkedFeesApaym"
																	@click="feeAmountEcobankQr" />
																<span style="left: 20px"></span>
															</div>
														</div>
														<div class="container d-flex justify-content-between" v-if="(dataPage.frais == '1' ||
															dataPage.frais == '2' ||
															dataPage.frais == '3') &&
															checkedFeesApaym
															">
															<div>
																<span class="font-12">{{
																	$t("text_fees.value")
																}}</span>
															</div>
															<div>
																<span class="font-12">
																	{{
																		dataPage.frais == "3"
																		? new Intl.NumberFormat("fr-FR", {
																			style: "currency",
																			currency: "XOF",
																		}).format(
																			montantSave
																				? parseFloat(montantSave) *
																				(parseFloat(
																					dataPage.taux_apaym / 2
																				) /
																					100)
																				: 0
																		)
																		: new Intl.NumberFormat("fr-FR", {
																			style: "currency",
																			currency: "XOF",
																		}).format(
																			montantSave
																				? parseFloat(montantSave) *
																				(parseFloat(
																					dataPage.taux_apaym
																				) /
																					100)
																				: 0
																		)
																	}}
																</span>
															</div>
														</div>
														<!-- Fees -->

														<!-- Pourboire -->
														<!-- Type formulaire normal -->
														<div>
															<div class="container d-flex justify-content-between" v-if="dataPage.pourboire == '1' &&
																	pourboire &&
																	(pourboire == 'autre'
																		? parseFloat(
																			dataFormulaire.amountPourboire
																		) > 0
																		: parseFloat(pourboire) > 0)
																	">
																<div>
																	<span class="font-12">
																		{{ $t("text_pourboire.value") }}
																	</span>
																</div>
																<div>
																	<span class="font-12">
																		{{
																			new Intl.NumberFormat("fr-FR", {
																				style: "currency",
																				currency: "XOF",
																			}).format(
																				pourboire == "autre"
																					? dataFormulaire.amountPourboire
																						? parseFloat(
																							dataFormulaire.amountPourboire
																						)
																						: 0
																					: parseFloat(pourboire)
																			)
																		}}
																	</span>
																</div>
															</div>
														</div>
														<!-- Pourboire -->

														<!-- Montant à payer -->
														<div class="container d-flex justify-content-between" v-if="montantAPayer &&
																(dataPage.frais == '1' ||
																	dataPage.frais == '2' ||
																	dataPage.frais == '3' ||
																	dataPage.pourboire == '1')
																">
															<div>
																<span class="font-12">{{
																	$t("text_amount_to_pay.value")
																}}</span>
															</div>

															<div>
																<span class="font-12">
																	{{
																		new Intl.NumberFormat("fr-FR", {
																			style: "currency",
																			currency: "XOF",
																		}).format(parseFloat(montantAPayer))
																	}}
																</span>
															</div>
														</div>

														<!-- Montant total -->
														<div class="container d-flex justify-content-between mb-3" :style="dataPage.frais == '1' ||
															dataPage.frais == '2' ||
															dataPage.frais == '3' ||
															dataPage.pourboire == '1'
															? 'padding: 0px 30px 0px 30px; top: -20px; position: relative;'
															: ''
															"
															v-if="(dataPage.frais == '1' || dataPage.frais == '2' || dataPage.frais == '3' || dataPage.pourboire == '1')">
															<div>
																<span style="font-weight: 800; font-size: 22px">TOTAL</span>
															</div>
															<div>
																<span style="font-weight: 800; font-size: 22px"
																	v-if="montantSave">
																	{{
																		new Intl.NumberFormat("fr-FR", {
																			style: "currency",
																			currency: "XOF",
																		}).format(
																			parseFloat(montantSave) +
																			parseFloat(dataPage.frais === "3"
																				? parseFloat(montantSave) ? parseFloat(montantSave) *
																					(parseFloat(dataPage.taux_apaym / 2) / 100)
																					: 0
																				: parseFloat(montantSave) ? parseFloat(montantSave) *
																					(parseFloat(dataPage.taux_apaym) / 100)
																					: 0
																			)
																		)
																	}}
																</span>
																<span style="font-weight: 800; font-size: 22px"
																	v-if="!montantSave">
																	{{
																		new Intl.NumberFormat("fr-FR", {
																			style: "currency",
																			currency: "XOF",
																		}).format(0)
																	}}
																</span>
															</div>
														</div>
														<!-- Montant total -->
													</div>
													<!-- Recapitulatif Montant à payer, pourboire, frais -->

													<div class="qr__wrapper">
														<div class="qr__code">
															<img v-if="ecobankQrCodeUri" name="qrCodeImage"
																alt="ECOBANK Pay QR Code" :src="ecobankQrCodeUri"
																id="qrCodeImage" style="width: 68%" />
														</div>
													</div>
													<div class="qr__wrapper mt-2 mb-4">
														<div class="qr__code">
															<i class="fas fa-spinner fa-spin" style="font-size: 20px"
																v-if="ecobankQrCodeUri"></i>
														</div>
														<div class="mt-2">
															{{ $t("text_visa_qr_waiting.value") }}
														</div>
													</div>
												</div>
											</div>
										</section>
									</div>
									<!-- Content -->
								</div>
								<!-- ECOBANK Pay -->

							</div>
							<!-- **************************** -->
							<!-- **************************** -->
							<!-- ********** Content ********* -->
							<!-- **************************** -->
							<!-- **************************** -->

							<!-- **************************** -->
							<!-- **************************** -->
							<!-- Second bloc -->
							<!-- **************************** -->
							<!-- **************************** -->
							<div :style="(dataPage.frais == '1' ||
								dataPage.frais == '2' ||
								dataPage.frais == '3' ||
								dataPage.pourboire == '1') &&
								menuSelected == 'Visa QR'
								? 'display: flex; flex-direction: column; z-index: 1; width: 100%; padding: 13px; justify-content: center; background: rgb(244, 244, 244); border-radius: 10px;'
								: 'display: flex; flex-direction: column; z-index: 1; width: 100%; padding: 13px; justify-content: center;'
								" v-if="loyalty_card == '1' && showHeader">
								<label class="mt-2" style="font-size: 12px; font-weight: 600">
									{{ $t("payment_modal.loyalty_card.value") }}
								</label>
								<div style="
                    display: flex;
                    flex-direction: row;
                    top: 5px;
                    z-index: 1;
                    align-items: center;
                    justify-content: center;
                    padding: 5px;
                  ">
									<span :style="pointLoyal.paid !== 1
										? 'border-radius: 50% !important; height: 25px; width: 25px; border: 2px solid #261b64;'
										: 'border-radius: 50% !important; height: 25px; width: 25px; background: #261b64;'
										" class="mr-2" v-for="pointLoyal in pointLoyalty" :key="pointLoyal.id">
									</span>
									<img src="../assets/img/gift.png" alt="Gift" width="40" />
								</div>
								<label class="mt-2" style="font-size: 12px; font-weight: 600" v-if="cadeau_a_offrir">
									{{ $t("payment_modal.bonus_offered.value") }}
									{{ cadeau_a_offrir.slice(0, 30) }}
								</label>
							</div>
							<!-- **************************** -->
							<!-- **************************** -->
							<!-- Second bloc -->
							<!-- **************************** -->
							<!-- **************************** -->
						</div>
					</form>
				</div>
				<!-- Body -->
			</div>

			<!-- *********************** -->
			<!-- *********************** -->
			<!-- Let someone pay for you -->
			<!-- *********************** -->
			<!-- *********************** -->
			<div class="element-wrap element-wrap-for-submit flex flex-justify--space-between pfm-payForm__divider"
				v-if="showPayBySomeone">
				<div class="divider-dashed"></div>
				<p>
					{{ $t("payment_modal.sharing_or.value") }}
				</p>
				<div class="divider-dashed"></div>
			</div>
			<div class="element-wrap element-wrap-for-submit" style="margin-bottom: 4.3rem" v-if="showPayBySomeone">
				<a href="javascript:void(0);" @click="letSomeonePay" class="btn btn-block font-15 btn-pay-by-someone">
					{{ $t("payment_modal.sharing.value") }}
				</a>
			</div>
			<!-- *********************** -->
			<!-- Let someone pay for you -->
			<!-- *********************** -->
		</div>
		<!-- ****************************************************** -->
		<!-- ****************************************************** -->
		<!-- Section du paiement | Affichage des moyens de paiement -->
		<!-- ****************************************************** -->
		<!-- ****************************************************** -->

		<!-- ****************************************************** -->
		<!-- ****************************************************** -->
		<!-- Section du partage | Affichage des options de partage  -->
		<!-- ****************************************************** -->
		<!-- ****************************************************** -->
		<div class="apaympro-wrapper animated fadeIn container mb-5" v-if="sharingSection" style="width: 500px">
			<div class="apaympro-page">
				<!-- Header -->
				<div class="apaympro-header" v-if="dataPage.dureeLien === '0'">
					<div class="mb-1" style="display: flex; flex-direction: column; width: 100%">
						<div class="text-modal mt-5 mb-4">
							<img src="../assets/apaym-pay.png" alt="Logo APAYM Pay" width="150" />
						</div>

						<!-- Montant -->
						<div class="mt-3" style="display: contents">
							<div style=" text-align: center; display: flex; flex-direction: column; ">
								<span style="
                    font-size: 25px;
                    font-weight: 600;
                    letter-spacing: 1.2px;
                  ">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(parseFloat(montantSave))
									}}
								</span>
							</div>
						</div>
						<!-- Montant -->

						<div class="text-modal" style="padding-top: 5px">
							<strong class="mb-4 font-16">
								{{ $t("payment_modal.sharing_text.value") }}
							</strong>

							<p class="font-14">
								{{ $t("payment_modal.sharing_sous_text.value") }}
							</p>
						</div>
					</div>

					<!-- Sharing bloc -->
					<div class="sharingBloc">
						<div class="sharingBloc">
							<div>
								<ShareNetwork network="facebook" :url="'https://pay.apaym.com/ref/' + refPayment"
									:title="dataPage.business_name + ' - Paye moi comme tu veux'"
									description="Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APAYM et APAYM"
									quote="Le moyen de paiement marchand le plus simplifié et pratique"
									hashtags="APaymPro,PaieMoi,Marchand,APAYMPay" class="btn btn-lg btn-link btn-facebook">
									<i class="fab fa-facebook-messenger" style="font-size: 1.6rem"></i>
								</ShareNetwork>

								<ShareNetwork network="Email" url="" :title="'Reçu de paiement APAYM Pay - ' +
									dataPage.merchant + ' (Ref: ' + refPayment + '#' + ')'" :description="'Trouvez via le lien ci-dessous le reçu de paiement APAYM Pay - Ref: ' +
		refPayment + '#' + '\n\n' + 'https://pay.apaym.com/recu/' + refPayment + '\n\n' + 'Paiement effectué via chez le marchand ' +
		dataPage.merchant" class="btn btn-link btn-lg btn-gmail"
									style="padding: 12px !important">
									<i style="font-size: 22px" class="fa fa-envelope"></i>
								</ShareNetwork>

								<ShareNetwork network="WhatsApp" :url="'https://pay.apaym.com/ref/' + refPayment"
									:title="dataPage.business_name + ' - Paye moi comme tu veux'"
									description="Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APAYM et APAYM"
									quote="Le moyen de paiement marchand le plus simplifié et pratique"
									hashtags="APaymPro,PaieMoi,Marchand,APAYMPay" class="btn btn-lg btn-link btn-whatsapp">
									<i class="fab fa-whatsapp" style="font-size: 1.6rem"></i>
								</ShareNetwork>

								<ShareNetwork network="telegram" :url="'https://pay.apaym.com/ref/' + refPayment"
									:title="dataPage.business_name + ' - Paye moi comme tu veux'"
									description="Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APAYM et APAYM"
									quote="Le moyen de paiement marchand le plus simplifié et pratique"
									hashtags="APaymPro,PaieMoi,Marchand,APAYMPay" class="btn btn-lg btn-link btn-telegram">
									<i class="fab fa-telegram" style="font-size: 1.6rem"></i>
								</ShareNetwork>
							</div>
						</div>
					</div>
					<!-- Sharing bloc -->

					<div class="mt-4 pfm__copy" style="border-color: rgb(53 59 65)">
						<p class="pfm__copy-link-preview" style="color: rgb(53 59 65)">
							https://pay.apaym.com/ref/{{ refPayment }}
						</p>
						<button class="pfm__copy-button button button--cta" @click="copy"
							style="background: rgb(53 59 65); line-height: 20px; display: flex; color: #ffffff; height: inherit;">
							<span> {{ $t("payment_modal.copy.value") }} </span>
						</button>
					</div>
					<!-- Sharing bloc -->
				</div>
				<!-- Header -->

				<!-- dataPage.merchant -->

				<!-- Header -->
				<div class="apaympro-header" v-if="dataPage.dureeLien == '1'">
					<div class="mb-1" style="display: flex; flex-direction: column; width: 100%">
						<div class="text-modal mt-5 mb-4">
							<img src="../assets/apaym-pay.png" alt="Logo APAYM Pay" width="150" />
						</div>

						<!-- Montant -->
						<div class="mt-3" style="display: contents">
							<div style="text-align: center; display: flex; flex-direction: column; ">
								<span style="font-size: 25px; font-weight: 600; letter-spacing: 1.2px; ">
									{{
										new Intl.NumberFormat("fr-FR", {
											style: "currency",
											currency: "XOF",
										}).format(parseFloat(montantSave))
									}}
								</span>
							</div>
						</div>
						<!-- Montant -->

						<div class="text-modal" style="padding-top: 5px">
							<strong class="mb-4 font-16">
								{{ $t("payment_modal.sharing_text.value") }}
							</strong>

							<p class="font-14">
								{{ $t("payment_modal.sharing_sous_text.value") }}
							</p>
						</div>
					</div>

					<!-- Sharing bloc -->
					<div class="sharingBloc">
						<!--v-if="!showLienReduit"-->
						<div class="sharingBloc" v-if="!showLienReduit">
							<div>
								<ShareNetwork network="facebook" :url="'https://pay.apaym.com/ref/' + refPayment"
									:title="dataPage.business_name + ' - Paye moi comme tu veux'"
									description="Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APAYM et APAYM"
									quote="Le moyen de paiement marchand le plus simplifié et pratique"
									hashtags="APaymPro,PaieMoi,Marchand,APAYMPay" class="btn btn-lg btn-link btn-facebook">
									<i class="fab fa-facebook-messenger" style="font-size: 1.6rem"></i>
								</ShareNetwork>

								<ShareNetwork network="Email" url="" :title="'Reçu de paiement APAYM Pay - ' +
									dataPage.business_name +
									' (Ref: ' +
									refPayment +
									'#' +
									')'
									" :description="'Trouvez via le lien ci-dessous le reçu de paiement APAYM Pay - Ref: ' +
		refPayment +
		'#' +
		'\n\n' +
		'https://pay.apaym.com/recu/' +
		refPayment +
		'\n\n' +
		'Paiement effectué via chez le marchand ' +
		dataPage.business_name
		" class="btn btn-link btn-lg btn-gmail" style="padding: 12px !important">
									<i style="font-size: 22px" class="fa fa-envelope"></i>
								</ShareNetwork>

								<ShareNetwork network="WhatsApp" :url="'https://pay.apaym.com/ref/' + refPayment"
									:title="dataPage.business_name + ' - Paye moi comme tu veux'" :disabled="!validiteLien"
									description="Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym"
									quote="Le moyen de paiement marchand le plus simplifié et pratique"
									hashtags="APaymPro,PaieMoi,Marchand" class="btn btn-lg btn-link btn-whatsapp">
									<i class="fab fa-whatsapp" style="font-size: 1.6rem"></i>
								</ShareNetwork>

								<ShareNetwork network="telegram" :url="'https://pay.apaym.com/ref/' + refPayment"
									:title="dataPage.business_name + ' - Paye moi comme tu veux'" :disabled="!validiteLien"
									description="Veuillez me payer via Visa QR, Cartes Bancaires, Mobile Money, USSD APaym et APaym"
									quote="Le moyen de paiement marchand le plus simplifié et pratique"
									hashtags="APaymPro,PaieMoi,Marchand" class="btn btn-lg btn-link btn-telegram">
									<i class="fab fa-telegram" style="font-size: 1.6rem"></i>
								</ShareNetwork>
							</div>
						</div>

						<div class="sharingBloc" v-if="showLienReduit">
							<div>
								<button :disabled="!validiteLien" class="btn btn-lg btn-link btn-facebook"
									:style="!validiteLien ? 'color: rgb(0 0 0 / 8%);' : ''" @click="copyLink">
									<i class="fab fa-facebook-messenger" style="font-size: 1.6rem"></i>
								</button>

								<button :disabled="!validiteLien" class="btn btn-lg btn-link btn-facebook"
									:style="!validiteLien ? 'color: rgb(0 0 0 / 8%);' : ''" @click="copyLink">
									<i class="fa fa-envelope" style="font-size: 1.6rem"></i>
								</button>

								<button :disabled="!validiteLien" class="btn btn-lg btn-link btn-whatsapp"
									:style="!validiteLien ? 'color: rgb(0 0 0 / 8%);' : ''" @click="copyLink">
									<i class="fab fa-whatsapp" style="font-size: 1.6rem"></i>
								</button>

								<button :disabled="!validiteLien" class="btn btn-lg btn-link btn-telegram"
									:style="!validiteLien ? 'color: rgb(0 0 0 / 8%);' : ''" @click="copyLink">
									<i class="fab fa-telegram" style="font-size: 1.6rem"></i>
								</button>
							</div>
						</div>
					</div>
					<!-- Sharing bloc -->

					<div class="mt-4 pfm__copy" style="border-color: rgb(53 59 65)" v-if="showLienReduit">
						<p class="pfm__copy-link-preview" style="color: rgb(53 59 65)">
							https://pay.apaym.com/ref/{{
								getDescriptionTronque2(refPayment, 5)
							}}
						</p>
						<!--<button class="pfm__copy-button button button&#45;&#45;cta" @click="copy"
                            style="background: rgb(53 59 65); line-height: 20px; display: flex; color: #ffffff; height: -webkit-fill-available;"
                        >
                            <span> {{ $t('payment_modal.copy.value') }} </span>
                        </button>-->
					</div>

					<div class="mt-4 pfm__copy" style="border-color: rgb(53 59 65)" v-if="!showLienReduit">
						<p class="pfm__copy-link-preview" style="color: rgb(53 59 65)">
							https://pay.apaym.com/ref/{{ refPayment }}
						</p>
					</div>

					<div class="element-pair mt-4">
						<div class="element-pair-container">
							<div class="element-wrap element-wrap-on-left element-wrap-for-select" style="width: 100%">
								<!--<div class="element-label">
                                    <span>{{$t('text_form.name_air_france.value')}}</span>
                                </div>-->
								<select v-model="validiteLien" type="text" class="element-is-select" name="validite"
									v-on:change="validitePaiement">
									<option value="" selected>Durée de validité</option>
									<option value="1 Heure">1 Heure</option>
									<option value="24 Heures">24 Heures</option>
									<option value="1 Semaine">1 Semaine</option>
									<option value="1 Mois">1 Mois</option>
									<option value="Indéterminé">Indéterminé</option>
								</select>
							</div>
							<div class="element-wrap element-wrap-on-right">
								<button :disabled="!validiteLien" :class="!validiteLien
									? 'btn btn-dark btn-disabled-custom'
									: 'btn btn-dark'
									" style="line-height: 1.7; height: 40px" @click="copy">
									<span> {{ $t("payment_modal.copy.value") }} </span>
								</button>
							</div>
						</div>
					</div>

					<!-- Sharing bloc -->
				</div>
				<!-- Header -->
			</div>

			<!-- Let someone pay for you -->
			<div class="element-wrap element-wrap-for-submit flex flex-justify--space-between pfm-payForm__divider"
				style="margin: 30px 0px -10px; padding: 15px 7px 0px 7px">
				<div class="divider-dashed"></div>
				<p>
					{{ $t("payment_modal.sharing_or.value") }}
				</p>
				<div class="divider-dashed"></div>
			</div>

			<div class="element-wrap element-wrap-for-submit" style="margin-bottom: 4.3rem">
				<a href="javascript:void(0);" @click="letSomeonePay" class="btn btn-block font-15 btn-pay-by-someone">
					{{ $t("payment_modal.sharing_2.value") }}
				</a>
			</div>
			<!-- Let someone pay for you -->
		</div>
		<!-- ****************************************************** -->
		<!-- ****************************************************** -->
		<!-- Section du partage | Affichage des options de partage  -->
		<!-- ****************************************************** -->
		<!-- ****************************************************** -->



		<!-- *********** -->
		<!-- *********** -->
		<!-- MODAL BLOC -->
		<!-- *********** -->
		<!-- *********** -->

		<!-- Orange money modal -->
		<b-modal class="modal-content" id="openOrangeMoney" ref="openOrangeMoneyModal" no-close-on-backdrop hide-footer
			hide-header scrollable style="padding: 0" size="lg">
			<div class="btn btn-link btn-floating btn-sm btn-close-modal display-for-large-only"
				@click="closeModal('openOrangeMoneyModal')">
				<i class="fas fa-times"></i>
			</div>
			<div class="btn btn-link btn-floating btn-sm btn-close-modal-small display-for-small-only"
				@click="closeModal('openOrangeMoneyModal')">
				<i class="fas fa-times" style="font-size: 23px; color: #ffffff"></i>
			</div>
			<div class="modalOrangeMoney">
				<iframe :src="urlOrange" frameborder="0" scrolling="yes" style="height: inherit; width: 100%"></iframe>
			</div>
		</b-modal>
		<!-- Orange money modal -->


		<!-- Wave modal -->
		<b-modal class="modal-content" id="openWave" ref="openWaveModal" no-close-on-backdrop hide-footer hide-header
			scrollable style="padding: 0" size="lg">
			<div class="btn btn-link btn-floating btn-sm btn-close-modal display-for-large-only"
				@click="closeModal('openWaveModal')">
				<i class="fas fa-times"></i>
			</div>
			<div class="btn btn-link btn-floating btn-sm btn-close-modal-small display-for-small-only"
				@click="closeModal('openWaveModal')">
				<i class="fas fa-times" style="font-size: 23px; color: #ffffff"></i>
			</div>
			<div class="modalOrangeMoney">
				<iframe :src="urlWave" frameborder="0" scrolling="yes" style="height: inherit; width: 100%"></iframe>
			</div>
		</b-modal>
		<!-- Wave modal -->

		<!-- *********** -->
		<!-- *********** -->
		<!-- MODAL BLOC -->
		<!-- *********** -->
		<!-- *********** -->

	</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

	.w-90 {
		width: 90% !important;
	}
	.btn-close-modal {
		position: absolute;
		font-size: 22px;
		color: #1b1e21;
		top: -4px;
		cursor: pointer;
		font-weight: 700;
		left: 102%;
		height: 25px;
		width: 25px;
	}

	.btn-close-modal-small {
		position: absolute;
		color: #1b1e21;
		top: 0;
		cursor: pointer;
		font-weight: 700;
		right: 0;
		/* height: 25px; */
		width: 25px;
		z-index: 50;
	}

	.text-modal-small {
		position: relative;
		color: #1b1e21;
		top: 5px;
		font-size: 16px;
		display: flex;
		justify-content: center;
		white-space: break-spaces;
		align-items: baseline;
	}

	.btn-disabled-custom {
		background: rgb(0 0 0 / 8%);
		border: rgb(0 0 0 / 8%);
		color: #1b1e21;
	}

	.searchIcon {
		/*margin-left: -35px;*/
		cursor: pointer;
		/* color: #261b64; */
		/*color: #343a40;*/
		/*cursor: pointer;*/
		/*font-size: 25px;*/
		/*z-index: 50;*/

		margin-left: -49px;
		cursor: pointer;
		/* color: #261b64; */
		/* color: #343a40; */
		cursor: pointer;
		/* font-size: 25px; */
		z-index: 50;
		height: 35px;
		line-height: normal;
		border-radius: 4px;
	}

	.payment__type {
		cursor: pointer;
		transition: all 0.15s ease;
		margin-right: 0px;
		white-space: nowrap;
	}

	.pdf-content {
		width: 100%;
		background: #fff;
	}

	.pdf-content .report-info {
		display: flex;
		padding: 20px;
		padding-bottom: 0px;
	}

	.pdf-content .report-info .user-details {
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
	}

	/* h3::first-letter{
		color: #ffdddd !important;
		font-weight: 900;
	} */

	.cols,
	.columns {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-left: -0.4rem;
		margin-right: -0.4rem;
	}

	.cols.col-gapless,
	.columns.col-gapless {
		margin-left: 0;
		margin-right: 0;
	}

	.cols.col-gapless>.column,
	.columns.col-gapless>.column {
		padding-left: 0;
		padding-right: 0;
	}

	.cols.col-oneline,
	.columns.col-oneline {
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		overflow-x: auto;
	}

	.cols.col-gapless>.column,
	.columns.col-gapless>.column {
		padding-left: 0;
		padding-right: 0;
	}

	.cols.col-oneline,
	.columns.col-oneline {
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		overflow-x: auto;
	}

	/* .column,
	[class~="col-"] {
		-webkit-flex: 1 1;
		flex: 1 1;
		max-width: 100%;
		padding-left: 0.4rem;
		padding-right: 0.4rem;
	} */
	.column.col-1,
	.column.col-2,
	.column.col-3,
	.column.col-4,
	.column.col-5,
	.column.col-6,
	.column.col-7,
	.column.col-8,
	.column.col-9,
	.column.col-10,
	.column.col-11,
	.column.col-12,
	.column.col-auto,
	[class~="col-"].col-1,
	[class~="col-"].col-2,
	[class~="col-"].col-3,
	[class~="col-"].col-4,
	[class~="col-"].col-5,
	[class~="col-"].col-6,
	[class~="col-"].col-7,
	[class~="col-"].col-8,
	[class~="col-"].col-9,
	[class~="col-"].col-10,
	[class~="col-"].col-11,
	[class~="col-"].col-12,
	[class~="col-"].col-auto {
		-webkit-flex: none;
		flex: none;
	}

	.column {
		padding-left: 0.8rem;
		padding-right: 0.8rem;
	}

	@media (max-width: 480px) {
		.main-layout .children .product-column {
			padding: 0 10px;
		}
	}

	@media (max-width: 600px) {
		.main-layout .children .product-column {
			padding: 0 10px;
		}
	}

	.product {
		margin-top: 30px;
	}

	.product {
		border-radius: 6px;
		height: 280px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		cursor: pointer;
	}

	@media (max-width: 480px) {
		.product {
			margin-top: 10px;
		}
	}

	@media (max-width: 600px) {
		.product {
			margin-top: 10px;
		}
	}

	/* .product:hover .product__details__name {
		text-decoration: underline;
	} */
	.product__media-container {
		border-radius: 6px 6px 0 0;
		background: #eceaeb;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		height: 250px;
		overflow: hidden;
	}

	.product__media-container--single-file {
		display: -webkit-flex;
		display: flex;
		padding: 20px;
	}

	.product__media {
		max-width: 100%;
		max-height: 100%;
		border-radius: 6px;
		object-fit: contain;
		outline: none;
	}

	.product__details {
		background: #fff;
		border-radius: 0 0 6px 6px;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		padding: 20px 20px;
		-webkit-flex-grow: 1;
		flex-grow: 1;
	}

	.product__details__price {
		color: #1a1a1a;
		font-weight: 800;
		font-size: 14px;
		line-height: 20px;
		text-decoration: none;
		text-align: center;
	}

	@media (max-width: 600px) {
		.product__details__price {
			text-align: center;
			font-size: 13px;
			line-height: 16px;
		}
	}

	@media (max-width: 480px) {
		.product__details__price {
			text-align: center;
			font-size: 13px;
			line-height: 16px;
		}
	}

	.product__details__name {
		color: #57584e;
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		z-index: 2;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-height: fit-content;
		text-align: center;
	}

	.product__details__name a {
		color: #57584e;
	}

	@media (max-width: 600px) {
		.product__details__name {
			text-align: center;
			font-size: 15px;
			line-height: 18px;
		}
	}

	@media (max-width: 480px) {
		.product__details__name {
			text-align: center;
			font-size: 15px;
			line-height: 18px;
		}
	}

	.modal-container .product-view__media--img,
	.product-view__media--img {
		pointer-events: none;
	}

	.modal-container .product-view__media--img,
	.modal-container .product-view__media--video,
	.product-view__media--img,
	.product-view__media--video {
		max-width: 100%;
		max-height: 100%;
		border-radius: 6px;
		object-fit: contain;
		outline: none;
	}

	.blocAbsolute {
		display: flex;
		position: absolute;
		flex-direction: row;
		z-index: 1;
		align-items: center;
		width: 100%;
		padding: 3px;
		justify-content: space-between;
		border-bottom: 1px solid rgb(255, 159, 0);
	}

	.blocNonAbsolute {
		display: flex;
		flex-direction: row;
		z-index: 1;
		align-items: center;
		width: 100%;
		padding: 3px;
		justify-content: space-between;
		border-bottom: 1px solid rgb(255, 159, 0);
	}

	.timer {
		position: relative;
		/* top: 6px; */
		font-size: 28px;
		font-weight: 700;
		color: #db110d;
	}

	.btn-copy-custom {
		background: rgb(53 59 65);
		line-height: 20px;
		display: flex;
		color: #ffffff;
		height: inherit;
	}

	.formules-container {
		width: 100%;

		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: 12px;
	}

	@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
		.formules-container {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-auto-rows: 96px;
			grid-gap: 20px;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
		}

		.tooltip .tooltip-inner {
			display: none !important;
		}

		/* .formules-container::-webkit-scrollbar {
						display: none;
					} */
	}

	.formule-bloc {
		position: relative;
		background-color: #f6f6f6;
		border: 1px solid #b2bec3;

		border-radius: 5px;
		width: 135px;
		height: 85px;
		max-height: 100px;
		padding: 5px;
		cursor: pointer;

		font-size: 14px;
		transition: all 0.3s ease-in-out;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.formule-bloc-disabled {
		position: relative;
		background-color: #eeeeeeb5 !important;
		color: #6a6c6e !important;
		border: 1px solid #b2bec3;

		border-radius: 5px;
		width: 135px;
		height: 96px;
		max-height: 100px;
		padding: 5px;
		cursor: pointer;

		font-size: 12px;
		transition: all 0.3s ease-in-out;
	}

	.formule-bloc:hover {
		transform: scale(1.05);
		background-color: #fdfdfd;
	}

	@media (max-width: 600px) {
		.formule-bloc {
			height: 95px;
			width: 119px;
		}

		.formule-bloc:hover {
			transform: scale(1);
		}
	}

	@media (max-width: 480px) {
		.formule-bloc {
			height: 7rem;
			width: 10rem;
		}

		.formule-bloc:hover {
			transform: scale(1);
		}
	}

	.formule-info {
		border-radius: 5px;
		text-align: center;
		padding: 5px;
		margin-top: 20px;
	}

	.image-container {
		width: 100%;
		/*height: 250px;*/
		overflow: hidden;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

</style>